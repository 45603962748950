// types
import { CountryCode, FlagCode } from '../../../@types';
import { countryCodes, flagCodes } from '../../../@types/unions';

// mui
import { Box } from '@mui/material';

// props
export interface FlagProps {
	code: CountryCode | FlagCode;
}

export const Flag = ({ code }: FlagProps) => {
	if (!countryCodes.includes(code as CountryCode) && !flagCodes.includes(code as FlagCode)) {
		return <></>;
	}

	return (
		<Box
			component="span"
			sx={{
				'& img': {
					display: 'block',
					width: 'auto',
					height: 18,
				},
			}}>
			<img alt={code} src={`/img/flags/${code.toLowerCase()}.svg`} />
		</Box>
	);
};
