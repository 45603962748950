// react
import { useEffect, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Transaction } from '../../../@types';

// mui
import { Grid, SxProps } from '@mui/material';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';
import { TableBasicTransactionsEntity } from '../../tables/basic/TableBasicTransactionsEntity/TableBasicTransactionsEntity';

// ui
import { BoxKeyValue } from '../../ui/BoxKeyValue/BoxKeyValue';

// fields
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';

// utils
import { formatCurrency } from '../../../utils/currency';
import { translateType } from '../../../utils/translations';

// props
export interface DialogTransactionsEntityProps extends DialogActionsProps {
	entity: Entity;
	isOpen: boolean;
	onClose: () => void;
	transactions: Transaction[];
}

export const DialogTransactionsEntity = ({
	entity,
	isOpen,
	onClose,
	transactions,
	...props
}: DialogTransactionsEntityProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [currency, setCurrency] = useState('');

	// vars
	const currencies = useMemo(() => {
		let currencies: string[] = [];

		for (let i = 0; i < transactions.length; i++) {
			if (transactions[i].currency) {
				currencies.push(String(transactions[i].currency));
			}
		}

		if (currencies.length) {
			currencies = [...new Set(currencies)];
		}

		return currencies;
	}, [transactions]);

	const received = [];
	const sent = [];

	// get total sent and received for each currency
	for (let i = 0; i < currencies.length; i++) {
		const transactionsByCurrency = transactions.filter(
			(transaction) => transaction.currency === currencies[i]
		);
		const transactionsSent = transactionsByCurrency.filter(
			(transaction) => transaction.fromEntity === entity.id
		);
		const transactionsReceived = transactionsByCurrency.filter(
			(transaction) => transaction.toEntity === entity.id
		);

		const totalReceived = transactionsReceived.reduce((total, transaction) => {
			return (total += transaction.amount);
		}, 0);

		const totalSent = transactionsSent.reduce((total, transaction) => {
			return (total += transaction.amount);
		}, 0);

		received.push({
			currency: currencies[i],
			total: totalReceived,
		});

		sent.push({
			currency: currencies[i],
			total: totalSent,
		});
	}

	const sxValue: SxProps = {
		color: 'brand.blue.500',
		fontWeight: 600,
		variant: 'h2',
	};

	const totalReceivedByCurrency = received.find((item) => item.currency === currency);
	const totalSentByCurrency = sent.find((item) => item.currency === currency);

	// set first currency
	useEffect(() => {
		if (currencies.length) {
			setCurrency(currencies[0]);
		}
	}, [currencies]);

	return (
		<DialogActions
			{...props}
			hasClose={true}
			isOpen={isOpen}
			maxWidth={800}
			onClose={onClose}
			subtitle={{
				children: translateType(entity?.type, t),
				variant: 'body2',
			}}
			title={{
				children: entity?.name,
				variant: 'h3',
			}}>
			<Grid container spacing={2}>
				{currencies.length > 1 && (
					<Grid item xs={12}>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid item xs={12}>
								<SelectCurrency
									currencies={currencies}
									defaultValue={currencies[0]}
									hasLabel={false}
									onChange={(e) => {
										const { value } = e.target as HTMLInputElement;
										setCurrency(value);
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				)}
				{totalSentByCurrency && (
					<Grid item xs={12} md={true}>
						<BoxKeyValue
							IconCircleProps={{ type: 'payment' }}
							label={{ children: t('sent') }}
							value={{
								children: formatCurrency({
									currency: totalSentByCurrency.currency,
									value: totalSentByCurrency.total,
								}),
								...sxValue,
							}}
						/>
					</Grid>
				)}
				{totalReceivedByCurrency && (
					<Grid item xs={12} md={true}>
						<BoxKeyValue
							IconCircleProps={{ type: 'payment' }}
							label={{ children: t('received') }}
							value={{
								children: formatCurrency({
									currency: totalReceivedByCurrency.currency,
									value: totalReceivedByCurrency.total,
								}),
								...sxValue,
							}}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<TableBasicTransactionsEntity
						borderRadius={2}
						color="grey"
						entity={entity}
						hasBorder={true}
						transactions={transactions}
					/>
				</Grid>
			</Grid>
		</DialogActions>
	);
};
