// types
import { Account } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Container, Grid } from '@mui/material';

// pages
import { Entities } from './Entities/Entities';
import { Financing } from './Financing/Financing';

// ui
import { VisibilityTextToggle } from '../../../ui/VisibilityTextToggle/VisibilityTextToggle';

// utils
import { formatCurrency } from '../../../../utils/currency';
import { CardKeyValue } from '../../../cards/CardKeyValue/CardKeyValue';

// props
export interface DetailsProps {
	account: Account;
}

export const Details = ({ account }: DetailsProps) => {
	// hooks
	const { t } = useTranslation();

	const balance = account.balances.reduce((total, balance) => {
		return total + balance.balance;
	}, 0);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<CardKeyValue
						label={{ children: t('institution') }}
						value={{
							children: account.institution || t('na'),
							color: 'common.black',
							fontWeight: 500,
							variant: 'body1',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CardKeyValue
						label={{ children: t('number') }}
						value={{
							children: account.num ? <VisibilityTextToggle text={account.num} /> : t('na'),
							color: 'common.black',
							fontWeight: 500,
							variant: 'body1',
						}}
					/>
				</Grid>
				<Grid item xs={12} md={4}>
					<CardKeyValue
						label={{ children: t('balance') }}
						value={{
							children: balance
								? formatCurrency({
										currency: account.currency,
										value: balance,
								  })
								: t('na'),
						}}
					/>
				</Grid>
				<Grid item xs={12} md={8}>
					<Entities account={account} />
				</Grid>
				<Grid item xs={12} md={4}>
					<Financing account={account} />
				</Grid>
			</Grid>
		</Container>
	);
};
