// react
import { Dispatch, MouseEvent, ReactNode, SetStateAction, useContext, useState } from 'react';

// types
import {
	Entity,
	EntityLoanPayload,
	EntityPayload,
	EntityType,
	ErrorCause,
	Loan,
	Metadata,
} from '../../../@types';
import { entityTypesReadOnly } from '../../../@types/unions';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	InputLabel,
	Paper,
	SelectChangeEvent,
	Switch,
	Typography,
} from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { SelectCountry } from '../../fields/SelectCountry/SelectCountry';

// forms
import { RepeatableFields } from './RepeatableFields/RepeatableFields';

// uitls
import { getOptionsEntityTypes } from '../../../utils/options';

// props
export interface FormManageEntityProps {
	entity?: Entity;
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	type?: EntityType;
}

export const FormManageEntity = ({
	entity,
	onClose,
	onSuccess,
	setIsLoading,
	type,
}: FormManageEntityProps) => {
	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entityLoans, setEntityLoans] = useState<Loan[]>([]);
	const [entityType, setEntityType] = useState<string>(entity ? entity.type : type || 'PIU');
	const [loans, setLoans] = useState<Loan[]>([]);
	const [sources, setSources] = useState(1);

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// vars
	const entityMetadata = entity?.metadata as Metadata;
	const parentRequiredTypes = ['INTERMEDIARY', 'SUB_PIU', 'SUPPLIER'];
	const isEdit = Boolean(entity);
	const isParentRequired = parentRequiredTypes.includes(type as string);
	const isEntityTypeReadOnly = entityTypesReadOnly.some((type) => type === entityType);

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setEntities(res.data);
		},
	});

	// fetch entity loans
	useFetch({
		isEnabled: Boolean(entity),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${entity?.id}&project=${project?.id}&fields=id,code,entities,status&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntityLoans(res.data);
				setSources(res.data.length);
			}
		},
	});

	const optionsTypes = getOptionsEntityTypes(t);

	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.code,
			value: String(loan.id),
		};
	});

	const optionsEntities = entities
		.filter((e) => e.id !== entity?.id)
		.map((entity) => {
			return {
				label: entity.name,
				value: String(entity.id),
			};
		});

	const optionsPermissions = [
		{
			defaultChecked: entity?.permissions?.entity?.create,
			disabled: isEntityTypeReadOnly,
			id: 'form-manage-entity-permission-create-entity',
			label: t('permissions.onboardDownstreamEntities'),
			name: 'permissionCreateEntity',
			value: 'CREATE_ENTITY',
		},
		{
			defaultChecked: entity?.permissions?.transaction?.create,
			disabled: isEntityTypeReadOnly,
			id: 'form-manage-entity-permission-create-transaction',
			label: t('permissions.recordPayments'),
			name: 'permissionCreateTransaction',
			value: 'CREATE_TRANSACTION',
		},
	];

	const renderSources = () => {
		// waiting until entity and entity loans exist
		// prevents changing default value of uncontrolled inputs
		if (!entity || (entity && entityLoans.length)) {
			const items: ReactNode[] = [];

			// for each source
			for (let i = 0; i < sources; i++) {
				// define default values for fields
				let loanDefaultValue = '';
				let loanLabel = null;
				let parentDefaultValue = '';
				let parentLabel = null;

				// define labels for first set of fields
				if (i === 0) {
					loanLabel = t('financingSource');
					parentLabel = t('parentEntity');
				}

				// define default loan value
				if (entityLoans?.[i]) {
					loanDefaultValue = String(entityLoans?.[i]?.id);
				}

				// define default parent entity value
				if (entityLoans?.[i]?.entities?.[0]?.parent?.id) {
					parentDefaultValue = String(entityLoans?.[i]?.entities?.[0]?.parent?.id);
				}

				items.push(
					<Grid item key={`source-${i}`} xs={12}>
						<RepeatableFields
							ButtonDeleteProps={{
								'aria-label': t('delete'),
								disabled: i === 0,
								onClick: () => setSources(sources - 1),
							}}
							entity={entity}
							entityType={entityType}
							SelectLoanProps={{
								defaultValue: loanDefaultValue,
								id: `form-manage-entity-source-${i}-loan`,
								label: loanLabel,
								name: `source-${i}-loan`,
								options: optionsLoans,
								required: true,
							}}
							SelectParentProps={{
								defaultValue: parentDefaultValue,
								disabled: isEntityTypeReadOnly,
								id: `form-manage-entity-source-${i}-parent`,
								label: parentLabel,
								name: `source-${i}-parent`,
								options: optionsEntities,
								required: isParentRequired,
							}}
						/>
					</Grid>
				);
			}

			return (
				<>
					{items}
					<Grid item xs={12}>
						<Button
							disabled={sources === loans.length}
							fullWidth={true}
							onClick={() => setSources(sources + 1)}
							variant="outlined">
							{t('addFinancingSource')}
						</Button>
					</Grid>
				</>
			);
		}
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// define form data
		const form = document.querySelector('#form-manage-entity') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());
		const metadata: Metadata = {};

		// populate metadata
		Object.keys(entries).forEach((key, i) => {
			const value = entries[key];
			if (key.includes('metadata-')) {
				// group metadata
				const resKey = key.replace('metadata-', '');
				metadata[resKey] = String(value);
			}
		});

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			// set associated entity
			const idParent = Number(entries.parent);

			if (!project) {
				throw new Error('The project does not exist.', {
					cause: {
						id: 'PROJECT_NON_EXISTENT',
					},
				});
			}

			if (!idParent && isParentRequired) {
				throw new Error('Associated parent entity must be selected.', {
					cause: {
						id: 'ENTITY_PARENT_NON_EXISTENT',
					},
				});
			}

			if (entries.loan === '') {
				throw new Error('Financing source must be selected.', {
					cause: {
						id: 'FINANCING_SOURCE_NOT_SELECTED',
					},
				});
			}

			// construct loans array
			const loans: EntityLoanPayload[] = [];

			// for each source
			for (let i = 0; i < sources; i++) {
				// define loan
				const loan = {
					id: Number(entries[`source-${i}-loan`]),
					parent: entries[`source-${i}-parent`] ? Number(entries[`source-${i}-parent`]) : null,
				};

				// check if loan exists
				const loanFound = loans.find((l) => l.id === loan.id);

				if (loanFound) {
					// get option of found loan
					const loanFoundOption = optionsLoans.find((l) => Number(l.value) === loan.id);
					throw new Error(`The loan ${loanFoundOption?.label} can only be selected once.`, {
						cause: {
							id: 'ENTITY_LOANS_IDENTICAL',
						},
					});
				} else {
					// push loan
					loans.push(loan);
				}
			}

			let url = `${process.env.REACT_APP_API_URL}/entities`;

			if (entity) {
				url += `/${entity.id}`;
			}

			// check permissions
			const hasPermissionCreateEntity = Boolean(entries.permissionCreateEntity);
			const hasPermissionCreateTransaction = Boolean(entries.permissionCreateTransaction);

			const body: Partial<EntityPayload> = {
				name: String(entries.name),
				metadata,
				loans,
				permissions: {
					entity: {
						create: hasPermissionCreateEntity,
						delete: hasPermissionCreateEntity,
						update: hasPermissionCreateEntity,
					},
					transaction: {
						create: hasPermissionCreateTransaction,
						delete: hasPermissionCreateTransaction,
						update: hasPermissionCreateTransaction,
					},
				},
				type: entries.type as EntityType,
			};

			if (entries.idExternal) {
				body.idExternal = String(entries.idExternal);
			}

			if (!isEdit) {
				body.isKycVerified = entries.isKycVerified === 'on';
				body.project = project.id;
			}

			const fetchAddEntity = await fetch(url, {
				method: isEdit ? 'PATCH' : 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddEntity = await fetchAddEntity.json();

			if (resAddEntity.error) {
				throw new Error(resAddEntity.error.message, {
					cause: {
						id: resAddEntity.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			let bannerMessage = t('alert.entityCreated', { name: entries.name });

			if (isEdit) {
				bannerMessage = t('alert.entityUpdated', { name: entries.name });
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: bannerMessage,
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (['ENTITY_DUPLICATE', 'ENTITY_DUPLICATE_ID_EXTERNAL'].includes(cause.id)) {
					message = t('error.entityDuplicate', { id: entries.idExternal });
				}

				if (cause.id === 'ENTITY_INVALID_TYPE') {
					message = t('error.entityInvalidType', { type: entries.type });
				}

				if (cause.id === 'ENTITY_LOANS_IDENTICAL') {
					message = t('error.entityLoansIdentical');
				}

				if (cause.id === 'ENTITY_UNAUTHORIZED_PATCH' || cause.id === 'ENTITY_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}

				if (cause.id === 'ENTITY_PARENT_NON_EXISTENT') {
					message = t('error.associatedEntityNotSelected');
				}

				if (cause.id === 'FINANCING_SOURCE_NOT_SELECTED') {
					message = t('error.financingSourceNotSelected');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id="form-manage-entity" name="formAddEntity" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={entity?.name}
						id="form-manage-entity-name"
						label={t('name')}
						name="name"
						required={true}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<Select
						id="form-manage-entity-type"
						label={t('type')}
						name="type"
						onChange={(e: SelectChangeEvent<unknown>) => {
							const { value } = e.target as HTMLSelectElement;
							setEntityType(value);
						}}
						options={optionsTypes}
						required={true}
						value={entityType}
					/>
				</Grid>

				{renderSources()}
				<Grid item xs={12}>
					<Input
						defaultValue={entityMetadata?.address1}
						id="form-manage-entity-address-1"
						label={t('address')}
						name="metadata-address1"
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						defaultValue={entityMetadata?.address2}
						id="form-manage-entity-address-2"
						name="metadata-address2"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={entityMetadata?.city}
						id="form-manage-entity-city"
						label={t('city')}
						name="metadata-city"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectCountry
						defaultValue={entityMetadata?.country}
						id="form-manage-entity-country"
						label={t('country')}
						name="metadata-country"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={entityMetadata?.region}
						id="form-manage-entity-region"
						label={t('region')}
						name="metadata-region"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						defaultValue={entityMetadata?.postcode}
						id="form-manage-entity-postcode"
						label={t('code')}
						name="metadata-postcode"
						required={true}
					/>
				</Grid>
				{!isEntityTypeReadOnly && (
					<>
						<Grid item xs={12}>
							<Typography fontWeight={600} variant="h5">
								{t('permissions')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Paper variant="outlined">
								<Grid container>
									{optionsPermissions.map((permission, i) => {
										const { id, label, ...permissionProps } = permission;
										const isLast = i === optionsPermissions.length - 1;
										return (
											<Grid item key={`permission-${i}`} xs={12}>
												<Box
													sx={(theme) => {
														return {
															padding: theme.spacing(1, 2),
															borderBottom: !isLast
																? `1px ${theme.palette.brand.grey[400]} solid`
																: null,
														};
													}}>
													<Grid
														alignItems="center"
														container
														justifyContent="space-between"
														spacing={2}>
														<Grid item>
															<InputLabel htmlFor={id} sx={{ margin: 0 }}>
																{label}
															</InputLabel>
														</Grid>
														<Grid item>
															<Switch {...permissionProps} color="primary" id={id} size="small" />
														</Grid>
													</Grid>
												</Box>
											</Grid>
										);
									})}
								</Grid>
							</Paper>
						</Grid>
					</>
				)}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={true}
								defaultValue={entity?.isKycVerified ? 'on' : 'off'}
								disabled={Boolean(entity)}
								id="form-manage-entity-is-kyc-verified"
								name="isKycVerified"
								required={true}
							/>
						}
						label={t('formAddEntity.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
