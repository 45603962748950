// react
import { MouseEvent, useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useNavigate } from 'react-router-dom';

// context
import { ContextProject } from '../../Project.context';

// material
import { Container, Grid, Typography } from '@mui/material';

// cards
import { CardAction } from '../../../../cards/CardAction/CardAction';

// icons
import { UploadOutlined as IconUpload } from '@mui/icons-material';

// hooks
import { useSearchParams } from 'react-router-dom';

export const QuickActions = () => {
	// hooks
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	// context
	const { project } = useContext(ContextProject);

	// handlers
	const handleRecordOpEx = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/opex`);
		searchParams.set('dialog', 'recordOpEx');
		setSearchParams(searchParams);
	};

	const handleRecordPayment = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/payments`);
		searchParams.set('dialog', 'recordPayment');
		setSearchParams(searchParams);
	};

	const handleRecordCashBenefit = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/cash-benefits`);
		searchParams.set('dialog', 'recordCashBenefit');
		setSearchParams(searchParams);
	};

	const handleRecordNonCashBenefit = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/non-cash-benefits`);
		searchParams.set('dialog', 'recordNonCashBenefit');
		setSearchParams(searchParams);
	};

	const handleRecordFundTransfer = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/transfers`);
		searchParams.set('dialog', 'recordFundTransfer');
		setSearchParams(searchParams);
	};

	const handleUploadHistoricalTransaction = (e: MouseEvent<HTMLButtonElement>) => {
		navigate(`/project/${project?.idWbg}/transactions/historical`);
		searchParams.set('dialog', 'uploadHistoricalTransactions');
		setSearchParams(searchParams);
	};

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('transactions')}</Typography>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleRecordPayment,
						}}
						IconCircleProps={{ size: 'sm', type: 'payment' }}
						title={{
							children: t('recordPayment'),
						}}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleRecordCashBenefit,
						}}
						IconCircleProps={{ size: 'sm', type: 'cashBenefit' }}
						title={{
							children: t('recordCashBenefit'),
						}}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleRecordNonCashBenefit,
						}}
						IconCircleProps={{ size: 'sm', type: 'nonCashBenefit' }}
						title={{
							children: t('recordNonCashBenefit'),
						}}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleRecordOpEx,
						}}
						IconCircleProps={{ size: 'sm', type: 'opex' }}
						title={{
							children: t('recordOpEx'),
						}}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleRecordFundTransfer,
						}}
						IconCircleProps={{ size: 'sm', type: 'transfer' }}
						title={{
							children: t('recordFundTransfer'),
						}}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardAction
						ButtonProps={{
							onClick: handleUploadHistoricalTransaction,
						}}
						IconCircleProps={{
							icon: <IconUpload />,
							size: 'sm',
							type: 'document',
						}}
						title={{
							children: t('uploadHistoricalTransactions'),
						}}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
