// types
import { financiers, financiersExt, financiersWbg } from '../@types/unions';

// i18n
import { TFunction } from 'i18next';

export const getOptionsEntityTypes = (t: TFunction) => {
	return [
		{
			label: t('auditor'),
			value: 'AUDITOR',
		},
		{
			label: t('borrower'),
			value: 'BORROWER',
		},
		{
			label: t('donor'),
			value: 'DONOR',
		},
		{
			label: t('intermediary'),
			value: 'INTERMEDIARY',
		},
		{
			label: t('piu'),
			value: 'PIU',
		},
		{
			label: t('subPiu'),
			value: 'SUB_PIU',
		},
		{
			label: t('taxAuthority'),
			value: 'TAX_AUTHORITY',
		},
	];
};

export const getOptionsFinanciersExt = () => {
	return financiersExt.map((financier) => {
		return {
			label: financier,
			value: financier,
		};
	});
};

export const getOptionsFinanciersWbg = () => {
	return financiersWbg.map((financier) => {
		return {
			label: financier,
			value: financier,
		};
	});
};

export const getOptionsFinanciers = () => {
	return financiers.map((financier) => {
		return {
			label: financier,
			value: financier,
		};
	});
};
