// react
import { ReactNode, useMemo } from 'react';

// types
import { ActionButton, Entity, Frame, Pagination } from '../../../../@types';

// i18n
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid } from '@mui/material';

// ui
import { EmptyProps } from '../../../ui/Empty/Empty';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardSupplier } from '../../../cards/CardSupplier/CardSupplier';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { AccountBalanceOutlined as IconAccountBalance } from '@mui/icons-material';

// props
export interface TableSuppliersProps {
	frame?: Frame;
	isLoading?: boolean;
	pagination: Pagination;
	suppliers: Entity[];
	refetchEntities?: () => void;
}

export const TableSuppliers = ({
	frame,
	isLoading,
	pagination,
	suppliers,
	refetchEntities,
}: TableSuppliersProps) => {
	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramQ = searchParams.get('q');

	// vars
	const isFrameSupplier = frame === 'SUPPLIER';

	// vars
	const headers = [
		{
			isSortable: true,
			label: t('name'),
			lg: 2,
			value: 'name',
		},
		{
			label: t('wallet'),
			lg: 2,
			value: 'wallet',
		},
	];

	if (!isFrameSupplier) {
		headers.push({
			label: t('entity'),
			lg: 2,
			value: 'parent',
		});
	}

	headers.push(
		{
			label: t('created'),
			lg: isFrameSupplier ? 3 : 1,
			value: 'created',
		},
		{
			label: t('status'),
			lg: 1,
			value: 'status',
		},
		{
			label: t('contracts'),
			lg: 1.5,
			value: 'contracts',
		},
		{
			label: t('traceability'),
			lg: 1.5,
			value: 'traceability',
		}
	);

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('addSupplier'),
					onClick: () => {
						searchParams.set('dialog', 'addSupplier');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.suppliers.content') },
			IconCircleProps: { icon: (<IconAccountBalance />) as ReactNode },
			title: { children: t('empty.suppliers.title') },
		};

		if (paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoading || !suppliers.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{suppliers.map((supplier, i) => {
					return (
						<Grid item key={`supplier-${i}`} xs={12}>
							<CardSupplier frame={frame} supplier={supplier} refetchEntities={refetchEntities} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, frame, isLoading, refetchEntities, suppliers]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FormSearch />
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={suppliers.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
