// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { ErrorCause, Report } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// utils
import { downloadFile } from '../../../utils/files';
import { formatDate } from '../../../utils/dates';

// props
export interface FormDownloadReportProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	report: Report | null;
}

export const FormDownloadReport = ({ onClose, setIsLoading, report }: FormDownloadReportProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const idForm = `form-download-report`;

	// defaults
	const dateToday = new Date();
	const todayYear = dateToday.getFullYear();

	// handler
	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (entries.dateEnd < entries.dateStart) {
				throw new Error('Start date must come before end date.', {
					cause: {
						id: 'REPORT_END_DATE_BEFORE_START_DATE',
					},
				});
			}

			// fetch report by type
			const fetchGetReport = await fetch(
				`${process.env.REACT_APP_API_URL}/reports?dateEnd=${entries.dateEnd}&dateStart=${entries.dateStart}&project=${project?.id}&type=${report?.type}`,
				{
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);
			const resGetReport = await fetchGetReport.json();

			if (resGetReport.error) {
				throw new Error(resGetReport.error.message, {
					cause: {
						id: resGetReport.error.id,
					},
				});
			}

			// download report
			downloadFile({
				data: new Uint8Array(resGetReport?.data?.blob?.data).buffer,
				name: report?.fileName,
				type: 'application/pdf',
			});

			if (onClose) {
				onClose();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.reportDownloaded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'REPORT_INVALID_TYPE') {
					message = t('error.reportInvalidType');
				}
				if (cause.id === 'REPORT_END_DATE_BEFORE_START_DATE') {
					message = t('error.reportEndDateBeforeStartDate');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formDownloadReport" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{report?.type === 'FUND_ALLOCATION' && (
					<Grid item xs={12}>
						<Input
							defaultValue={formatDate({
								date: new Date(`${todayYear}-01-01`),
								format: 'YYYY-MM-DD',
							})}
							id={`${idForm}-start-date`}
							label={t('startDate')}
							name="dateStart"
							required={true}
							type="date"
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<Input
						defaultValue={formatDate({ date: dateToday, format: 'YYYY-MM-DD' })}
						id={`${idForm}-end-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('endDate')}
						name="dateEnd"
						required={true}
						type="date"
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
