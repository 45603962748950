// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { User } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// props
export interface DialogUpdateUserPermissionsProps {
	user: User;
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	onSuccess?: () => void;
}

export const DialogUpdateUserPermissions = ({
	user,
	isOpen,
	onClose,
	onSuccess,
}: DialogUpdateUserPermissionsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// patch user
	const { fetchRequest: updateUserPermissions, isLoading: isLoadingUpdateUserPermissions } =
		useFetch({
			isLazy: true,
			url: `${process.env.REACT_APP_API_URL}/users/${user.id}`,
			options: {
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify({
					permissions: {},
					project: project?.id,
				}),
			},
			onError: (error) => {
				const msg = t('error.default');

				setError(msg);
			},
			onSuccess: () => {
				// set banner
				setBanner({
					hasClose: true,
					message: t('alert.userPermissionsRemoved'),
					severity: 'success',
				});

				if (onSuccess) {
					onSuccess();
				}

				// close dialog
				if (onClose) {
					onClose();
				}
			},
		});

	// handlers
	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// update user permissions
		updateUserPermissions();
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: t('removeUserPermissions'),
					loading: isLoadingUpdateUserPermissions,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'user',
			}}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			subtitle={{
				children: user.fullName,
			}}
			title={{
				children: t('removeUserPermissions'),
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.magenta.600" component="span" fontWeight={600} />,
						}}
						i18nKey="dialogUpdateUserPermissions.content"
						values={{ name: user.fullName }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert icon={false} severity="warning">
						<Typography fontWeight={600}>{t('alert.userNoLongerAble')}</Typography>
						<Box component="ul" sx={{ margin: 0, padding: 0 }}>
							<Box component="li">
								<Typography>{t('permissions.readBeneficiary.label')}</Typography>
							</Box>
						</Box>
					</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
