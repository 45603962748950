// react
import { MouseEventHandler } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Button, IconButton, SxProps } from '@mui/material';

// icons
import {
	ExpandLessOutlined as IconExpandLess,
	ExpandMoreOutlined as IconExpandMore,
} from '@mui/icons-material';

// props
export interface BtnExpandProps {
	hasDisplayToggled?: boolean;
	isExpanded: boolean;
	label: string;
	onClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
	sxButtonProps?: SxProps;
	sxIconButton?: SxProps;
}

export const BtnExpand = ({
	hasDisplayToggled = true,
	isExpanded,
	label,
	onClick,
	sxButtonProps = {},
	sxIconButton = {},
}: BtnExpandProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<>
			{hasDisplayToggled && (
				<Button
					endIcon={isExpanded ? <IconExpandLess /> : <IconExpandMore />}
					onClick={onClick}
					sx={(theme) => {
						return {
							display: {
								lg: 'none',
							},
							...(sxButtonProps as object),
						};
					}}
					variant="text">
					{label}
				</Button>
			)}

			<IconButton
				aria-label={isExpanded ? t('collapse') : t('expand')}
				onClick={onClick}
				size="small"
				sx={(theme) => {
					return {
						display: {
							xs: hasDisplayToggled ? 'none' : 'inline-flex',
							lg: 'inline-flex',
						},
						...(sxIconButton as object),
					};
				}}>
				{isExpanded ? <IconExpandLess /> : <IconExpandMore />}
			</IconButton>
		</>
	);
};
