// react
import { useContext } from 'react';

// types
import { financiersExt } from '../../../../../@types/unions';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextLoans, ContextProject } from '../../Project.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// tables
import { TableFinancingSources } from '../../../../tables/cards/TableFinancingSources/TableFinancingSources';

// ui
import { SummaryFinancingSources } from '../../../../ui/SummaryFinancingSources/SummaryFinancingSources';

// props
export interface DetailsProps {
	hasSummary?: boolean;
}

export const Details = ({ hasSummary = false }: DetailsProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { loans, isLoadingLoans } = useContext(ContextLoans);
	const { isOnboarded } = useContext(ContextProject);

	// vars
	const loansExt = [];
	const loansWbg = [];

	// separate wbg and other loans
	for (let l = 0; l < loans.length; l++) {
		const loan = loans[l];

		if (financiersExt.some((financier) => loan.financier === financier)) {
			loansExt.push(loan);
		} else {
			loansWbg.push(loan);
		}
	}

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">
						{isOnboarded ? t('worldBankFinancingSources') : t('financingSources')}
					</Typography>
				</Grid>
				{hasSummary && (
					<Grid item xs={12}>
						<SummaryFinancingSources loans={loansWbg} />
					</Grid>
				)}
				<Grid item xs={12}>
					<TableFinancingSources
						hasFilters={false}
						hasSearch={false}
						isLoading={isLoadingLoans}
						loans={loansWbg}
					/>
				</Grid>
				{isOnboarded && (
					<>
						<Grid item xs={12}>
							<Typography variant="h2">{t('otherFinancingSources')}</Typography>
						</Grid>
						{hasSummary && (
							<Grid item xs={12}>
								<SummaryFinancingSources loans={loansExt} />
							</Grid>
						)}
						<Grid item xs={12}>
							<TableFinancingSources
								hasFilters={false}
								hasSearch={false}
								isLoading={isLoadingLoans}
								loans={loansExt}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Container>
	);
};
