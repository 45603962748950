// types
import { Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// headers
import { getSourcesHeaders } from './TableBasicNonCashBenefits.headers';

// ui
import { Table, TableHeader } from '../../../ui/Table/Table';

// props
export interface TableBasicNonCashBenefitsProps {
	transactions: Transaction[];
}

export const TableBasicNonCashBenefits = ({ transactions }: TableBasicNonCashBenefitsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers: TableHeader[] = getSourcesHeaders({ t });

	return <Table data={transactions} headers={headers} />;
};
