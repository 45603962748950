// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormAddAccountBalance } from '../../forms/FormAddAccountBalance/FormAddAccountBalance';

// types
import { Account } from '../../../@types';

// props
export interface DialogAddAccountBalanceProps {
	account: Account;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const DialogAddAccountBalance = ({
	account,
	isOpen,
	onClose,
	onSuccess,
}: DialogAddAccountBalanceProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('addFunds'),
					form: 'form-add-account-balance',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			alignItems="center"
			hasClose={true}
			IconCircleProps={{
				type: account.type === 'LOCAL' ? 'local' : 'account',
			}}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: `${t('account')} ${account.name}`,
			}}>
			<FormAddAccountBalance
				account={account}
				onClose={onClose}
				onSuccess={onSuccess}
				setIsLoading={setIsLoading}
			/>
		</DialogActions>
	);
};
