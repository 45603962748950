// types
import { Account, Loan } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Grid, Paper, Typography } from '@mui/material';

// ui
import { financierIconTypes, IconCircle } from '../../../../ui/IconCircle/IconCircle';
import { Table, TableHeader } from '../../../../ui/Table/Table';

// utils
import { formatCurrency } from '../../../../../utils/currency';

// props
export interface FinancingProps {
	account: Account;
}

export const Financing = ({ account }: FinancingProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers = [
		{
			label: t('source'),
			render: (code: string, header: TableHeader, loan: Loan) => {
				return (
					<Grid alignItems="center" container spacing={1} wrap="nowrap">
						<Grid item>
							<IconCircle size="sm" type={financierIconTypes[loan.financier]} />
						</Grid>
						<Grid item>
							<Typography fontWeight={500}>{code}</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'code',
		},
		{
			label: t('balance'),
			render: (balance: number, header: TableHeader, loan: Loan) => {
				if (balance) {
					return formatCurrency({
						currency: loan.currency,
						value: balance,
					});
				}
				return t('na');
			},
			value: 'balance',
		},
	];

	const sources = [];

	if (account.balances.length) {
		for (let i = 0; i < account.balances.length; i++) {
			sources.push({
				...(account.balances[i].loan as Loan),
				balance: account.balances[i].balance,
			});
		}
	} else if (account.loans) {
		for (let i = 0; i < account.loans.length; i++) {
			sources.push({
				...(account.loans[i] as Loan),
				balance: null,
			});
		}
	}

	return (
		<Paper sx={{ height: '100%', overflow: 'hidden' }}>
			<Table color="grey" data={sources} headers={headers} />
		</Paper>
	);
};
