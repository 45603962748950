// react
import { MouseEvent, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Button, Grid } from '@mui/material';

// ui
import { Popover } from '../Popover/Popover';

// icons
import { FilterListOutlined as IconFilterAlt } from '@mui/icons-material';

// ui
import { FilterProps, Filters } from '../Filters/Filters';

export interface BtnFiltersProps {
	filters: FilterProps[];
	hasClose?: boolean;
	hasLabel?: boolean;
	onReset?: () => void;
}

export const BtnFilters = ({
	filters,
	hasClose = true,
	hasLabel = true,
	onReset,
}: BtnFiltersProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);
	const [, setSearchParams] = useSearchParams();

	// handlers
	const handleResetFilters = () => {
		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (onReset) {
			onReset();
		}

		// reset search params
		setSearchParams(new URLSearchParams());
	};

	const handleClose = () => {
		setAnchorMenu(null);
	};

	return (
		<>
			<Button
				aria-label={hasLabel ? undefined : t('toggleFilters')}
				color="primary"
				disabled={!filters.length}
				fullWidth
				onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorMenu(e.currentTarget)}
				startIcon={hasLabel ? <IconFilterAlt /> : undefined}
				variant="outlined">
				{hasLabel ? t('filter') : <IconFilterAlt />}
			</Button>
			<Popover
				actions={[
					{
						children: t('reset'),
						onClick: handleResetFilters,
						fullWidth: true,
						GridProps: {
							xs: 6,
						},
						variant: 'outlined',
					},
					{
						children: t('done'),
						fullWidth: true,
						GridProps: {
							xs: 6,
						},
						onClick: handleClose,
						variant: 'contained',
					},
				]}
				hasClose={hasClose}
				PopoverProps={{
					anchorEl: anchorMenu,
					onClose: handleClose,
					open: Boolean(anchorMenu),
				}}
				title={{
					children: t('filter'),
				}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Filters filters={filters} />
					</Grid>
				</Grid>
			</Popover>
		</>
	);
};
