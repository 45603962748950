// types
import { Loan } from '../../../@types';

// ui
import { IconCircleProps } from '../../ui/IconCircle/IconCircle';

export interface ConstructAmountInputsProps {
	loans: Loan[];
}
export const constructAmountInputs = ({ loans }: ConstructAmountInputsProps) => {
	return loans.map((loan: Loan) => {
		const disabled = loan?.status === 'DISABLED';
		const label = loan?.code;
		const icon = null;

		let type: IconCircleProps['type'] = 'default';

		if (loan) {
			if (loan.financier === 'OTH') {
				type = 'other';
			} else if (loan.financier === 'GOV') {
				type = 'government';
			} else {
				type = 'loan';
			}
		}

		return {
			disabled,
			IconCircleProps: {
				icon,
				type,
			},
			label,
		};
	});
};
