// i18next
import { useTranslation } from 'react-i18next';

// material
import { Container, Grid } from '@mui/material';

// cards
import { CardKeyValue } from '../../../../cards/CardKeyValue/CardKeyValue';

// charts
import { ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';

// props
export interface SummaryProps {
	dataSankey: ChartSankeyProps | null;
}

export const Summary = ({ dataSankey }: SummaryProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const nodesFiltered = dataSankey?.data?.nodes.filter((node) => node.type) || [];
	const sourcesCount = new Map<string, number>();

	let avg: string | number = t('na');
	let highest: string | number = t('na');
	let lowest: string | number = t('na');

	let totalBeneficiaries: string | number = t('na');
	let totalEntities: string | number = t('na');
	let totalSuppliers: string | number = t('na');
	let totalTransactions: string | number = t('na');

	if (nodesFiltered.length) {
		totalBeneficiaries = nodesFiltered.filter((node) => node.type === 'BENEFICIARY').length;
		totalSuppliers = nodesFiltered.filter((node) => node.type === 'SUPPLIER').length;
		totalEntities = nodesFiltered.length - totalBeneficiaries - totalSuppliers;
	}

	if (dataSankey?.data?.links?.length) {
		let totalValue = 0;

		highest = 0;
		lowest = 0;
		totalTransactions = 0;

		for (let i = 0; i < dataSankey.data.links.length; i++) {
			const link = dataSankey.data.links[i];

			if (link?.transactions?.length) {
				for (let j = 0; j < link.transactions.length; j++) {
					const transaction = link.transactions[j];

					if (transaction?.payments?.length) {
						for (let k = 0; k < transaction?.payments?.length; k++) {
							const payment = transaction?.payments[k];

							if (payment?.loan) {
								sourcesCount.set(
									String(payment?.loan?.code),
									(sourcesCount.get(String(payment?.loan?.code)) || 0) + 1
								);
							}
						}
					}
				}
			}
			// add to total value
			totalValue += link.value;

			// add to total transactions
			totalTransactions += link.transactions.length;

			// set first link as lowest and highest
			if (i === 0) {
				highest = link.value;
				lowest = link.value;
			} else {
				// check highest
				if (link.value > highest) {
					highest = link.value;
				}

				// check lowest
				if (link.value < lowest) {
					lowest = link.value;
				}
			}
		}

		avg = Number(totalValue / dataSankey.data.links.length);
		highest = Number(highest);
		lowest = Number(lowest);
	}

	return (
		<Container data-average={avg} data-highest={highest} data-lowest={lowest} maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						label={{ children: t('entities') }}
						IconCircleProps={{ type: 'entity' }}
						value={{ children: totalEntities }}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						label={{ children: t('suppliers') }}
						IconCircleProps={{ type: 'supplier' }}
						value={{ children: totalSuppliers }}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						label={{ children: t('beneficiaries') }}
						IconCircleProps={{ type: 'individual' }}
						value={{ children: totalBeneficiaries }}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						label={{ children: t('transactions') }}
						IconCircleProps={{ type: 'payment' }}
						value={{ children: totalTransactions }}
					/>
				</Grid>
				<Grid item xs={12} lg={true}>
					<CardKeyValue
						label={{ children: t('financingSources') }}
						IconCircleProps={{ type: 'loan' }}
						value={{ children: sourcesCount.size || t('na') }}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
