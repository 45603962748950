// types
import { Benefit, Transaction, TransactionStatus } from '../../../../@types';

// i18n
import { TFunction } from 'i18next';

// mui
import { Typography } from '@mui/material';

// table
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { TableHeader } from '../../../ui/Table/Table';

// ui
import { Time } from '../../../ui/Time/Time';

// props
export interface SourcesHeaderTableProps {
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
}

export const getSourcesHeaders = ({ t, transaction }: SourcesHeaderTableProps): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t('benefit'),
			value: 'benefit',
			render: (benefit: Benefit, header: TableHeader, transaction: Transaction) => {
				let text = t('na');
				if (benefit && transaction.quantity) {
					text = `${transaction.quantity} ${benefit.name}`;
				}
				return <Typography>{text}</Typography>;
			},
		},
		{
			label: t('recorded'),
			value: 'created',
			render: (created: Date) => {
				return <Time date={created} />;
			},
		},
		{
			label: t('status'),
			value: 'status',
			render: (status: TransactionStatus) => {
				return <ChipStatus status={status} />;
			},
		},
	];

	return columns;
};
