// types
import { CountryCode, FlagCode } from '../@types';

// i18n
import i18n from 'i18next';

interface FormatCurrencyParams {
	currency?: string;
	decimals?: number;
	notation?: Intl.NumberFormatOptions['notation'];
	lang?: string;
	value: number;
}

export const formatCurrency = ({
	currency = 'USD',
	decimals = 2,
	notation = 'standard',
	lang = i18n.language,
	value,
}: FormatCurrencyParams) => {
	if (!currency) {
		return new Intl.NumberFormat(lang, {
			maximumFractionDigits: decimals,
			notation,
		}).format(value);
	}

	if (notation === 'compact') {
		return new Intl.NumberFormat(lang, {
			maximumFractionDigits: decimals,
			notation: 'compact',
			compactDisplay: 'short',
			style: 'currency',
			currency: currency,
		}).format(value);
	}

	return new Intl.NumberFormat(lang, {
		maximumFractionDigits: decimals,
		notation,
		style: 'currency',
		currency: currency,
	}).format(value);
};

export const flagsByCurrency: Record<string, CountryCode | FlagCode> = {
	AED: 'AE',
	AFN: 'AF',
	ALL: 'AL',
	AMD: 'AM',
	ANG: 'CW',
	AOA: 'AO',
	ARS: 'AR',
	AUD: 'AU',
	AWG: 'AW',
	AZN: 'AZ',
	BAM: 'BA',
	BBD: 'BB',
	BDT: 'BD',
	BGN: 'BG',
	BHD: 'BH',
	BIF: 'BI',
	BMD: 'BM',
	BND: 'BN',
	BOB: 'BO',
	BRL: 'BR',
	BSD: 'BS',
	BTN: 'BT',
	BWP: 'BW',
	BYR: 'BY',
	BZD: 'BZ',
	CAD: 'CA',
	CDF: 'CD',
	CHF: 'CH',
	CLP: 'CL',
	CNY: 'CN',
	COP: 'CO',
	CRC: 'CR',
	CUP: 'CU',
	CVE: 'CV',
	CZK: 'CZ',
	DJF: 'DJ',
	DKK: 'DK',
	DOP: 'DO',
	DZD: 'DZ',
	EGP: 'EG',
	ERN: 'ER',
	ETB: 'ET',
	EUR: 'EU',
	FJD: 'FJ',
	FKP: 'FK',
	GBP: 'GB',
	GEL: 'GE',
	GHS: 'GH',
	GIP: 'GI',
	GMD: 'GM',
	GNF: 'GN',
	GTQ: 'GT',
	GYD: 'GY',
	HKD: 'HK',
	HNL: 'HN',
	HRK: 'HR',
	HTG: 'HT',
	HUF: 'HU',
	IDR: 'ID',
	ILS: 'IL',
	INR: 'IN',
	IQD: 'IQ',
	IRR: 'IR',
	ISK: 'IS',
	JMD: 'JM',
	JOD: 'JO',
	JPY: 'JP',
	KES: 'KE',
	KGS: 'KG',
	KHR: 'KH',
	KMF: 'KM',
	KPW: 'KP',
	KRW: 'KR',
	KWD: 'KW',
	KYD: 'KY',
	KZT: 'KZ',
	LAK: 'LA',
	LBP: 'LB',
	LKR: 'LK',
	LRD: 'LR',
	LSL: 'LS',
	LTL: 'LT',
	LYD: 'LY',
	MAD: 'EH',
	MDL: 'MD',
	MGA: 'MG',
	MKD: 'MK',
	MMK: 'MM',
	MNT: 'MN',
	MOP: 'MO',
	MRO: 'MR',
	MUR: 'MU',
	MVR: 'MV',
	MWK: 'MW',
	MXN: 'MX',
	MYR: 'MY',
	MZN: 'MZ',
	NAD: 'NA',
	NGN: 'NG',
	NIO: 'NI',
	NOK: 'BV',
	NPR: 'NP',
	NZD: 'CK',
	OMR: 'OM',
	PAB: 'PA',
	PEN: 'PE',
	PGK: 'PG',
	PHP: 'PH',
	PKR: 'PK',
	PLN: 'PL',
	PYG: 'PY',
	QAR: 'QA',
	RON: 'RO',
	RSD: 'RS',
	RUB: 'RU',
	RWF: 'RW',
	SAR: 'SA',
	SBD: 'SB',
	SCR: 'SC',
	SDG: 'SD',
	SEK: 'SE',
	SGD: 'SG',
	SHP: 'SH',
	SLL: 'SL',
	SOS: 'SO',
	SRD: 'SR',
	SSP: 'SS',
	STD: 'ST',
	SYP: 'SY',
	SZL: 'SZ',
	THB: 'TH',
	TJS: 'TJ',
	TMT: 'TM',
	TND: 'TN',
	TOP: 'TO',
	TRY: 'TR',
	TTD: 'TT',
	TWD: 'TW',
	TZS: 'TZ',
	UAH: 'UA',
	UGX: 'UG',
	USD: 'US',
	UYU: 'UY',
	UZS: 'UZ',
	VEF: 'VE',
	VND: 'VN',
	VUV: 'VU',
	WST: 'WS',
	XAF: 'CF',
	XCD: 'AG',
	XOF: 'BF',
	XPF: 'NC',
	YER: 'YE',
	ZAR: 'ZA',
	ZMK: 'ZM',
	ZWL: 'ZW',
};
