// react
import { useContext, useEffect } from 'react';

// router
import { useNavigate } from 'react-router-dom';

// azure
import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

// context
import { ContextUser } from '../App.context';

// props
export interface GetTokenProps {
	account: AccountInfo;
}

export interface IdTokenClaimsProps {
	roles: string[];
	[x: string]: unknown;
}

export const useAuth = () => {
	// hooks
	const isAuthenticated = useIsAuthenticated();
	const { accounts, instance } = useMsal();
	const navigate = useNavigate();

	// context
	const { setUser } = useContext(ContextUser);

	const clearSession = () => {
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('idToken');
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('user');
	};

	const login = async () => {
		return instance
			.loginRedirect({
				scopes: ['user.read'],
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const logout = async () => {
		// session
		const accessToken = sessionStorage.getItem('accessToken');
		const idToken = sessionStorage.getItem('idToken');

		// logout
		await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		});

		// clear session
		clearSession();

		if (isAuthenticated) {
			instance.logoutRedirect({
				postLogoutRedirectUri: '/',
			});
		} else {
			navigate('/');
		}
	};

	useEffect(() => {
		const getToken = async ({ account }: GetTokenProps) => {
			return await instance.acquireTokenSilent({
				account,
				scopes: ['user.read'],
			});
		};

		instance.handleRedirectPromise().then(async () => {
			const sessionUser = sessionStorage.getItem('user');
			const user = sessionUser ? JSON.parse(sessionUser) : null;

			if (!user?.isPersona) {
				if (accounts.length) {
					//console.log('Accounts', accounts);
					const resToken: AuthenticationResult = await getToken({
						account: accounts[0],
					});

					//console.log('Token', resToken);

					if (resToken) {
						// define tokens
						const accessToken = resToken.accessToken;
						const idToken = resToken.idToken;

						// login
						if (!user) {
							const fetchLogin = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
								method: 'POST',
								headers: {
									Authorization: `Bearer ${accessToken}`,
									User: String(idToken),
								},
							});

							await fetchLogin.json();
						}

						// fetch me
						const fetchMe = await fetch(`${process.env.REACT_APP_API_URL}/me`, {
							headers: {
								Authorization: `Bearer ${accessToken}`,
								User: String(idToken),
							},
						});
						const resMe = await fetchMe.json();

						if (resMe.data) {
							const me = resMe.data;
							//console.log('Me', me);

							if (me.status === 'DEACTIVATED') {
								navigate('/?error=deactivated');
							} else {
								const { roles } = resToken.idTokenClaims as IdTokenClaimsProps;
								//console.log('Roles', roles);

								if (roles.length) {
									// set storage
									sessionStorage.setItem('accessToken', accessToken);
									sessionStorage.setItem('idToken', idToken);
									sessionStorage.setItem('token', JSON.stringify(resToken));
									sessionStorage.setItem('user', JSON.stringify(resMe.data));

									// set user
									setUser(resMe.data);
								} else {
									navigate('/?error=auth');
								}
							}
						} else {
							// clear session
							clearSession();

							if (resMe.error.id === 'ACCESS_TOKEN_EXPIRED') {
								navigate('/?error=expired');
							} else {
								navigate('/?error=auth');
							}
						}
					}
				}
			}
		});
	}, [accounts, instance, navigate, setUser]);

	return {
		login,
		logout,
	};
};
