// react
import { useRef, useState } from 'react';

// types
import { Entity, Transaction } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// recharts
import { Sankey, ResponsiveContainer, Tooltip } from 'recharts';
import { Sankey as SankeyProps } from 'recharts/types';

// mui
import { Box } from '@mui/material';

// charts
import { ChartTooltip } from '../ChartTooltip/ChartTooltip';

// theme
import { theme } from '../../../theme';

// dialogs
import { DialogTransactions } from '../../dialogs/DialogTransactions/DialogTransactions';
import { DialogTransactionsEntity } from '../../dialogs/DialogTransactionsEntity/DialogTransactionsEntity';

// props
export interface ChartSankeyProps {
	data: SankeyProps['props']['data'] | null;
	height?: number;
}

export const chartSankeyColors = [
	{
		node: theme.palette.brand.green['500'],
		link: theme.palette.brand.green['100'],
	},
	{
		node: theme.palette.brand.magenta['500'],
		link: theme.palette.brand.magenta['100'],
	},
	{
		node: theme.palette.brand.blue['500'],
		link: theme.palette.brand.blue['100'],
	},
	{
		node: theme.palette.brand.orange['500'],
		link: theme.palette.brand.orange['100'],
	},
	{
		node: theme.palette.brand.purple['500'],
		link: theme.palette.brand.purple['100'],
	},
	{
		node: theme.palette.brand.teal['500'],
		link: theme.palette.brand.teal['100'],
	},
	{
		node: theme.palette.brand.yellow['500'],
		link: theme.palette.brand.yellow['100'],
	},
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartSankeyNode = ({ containerWidth, height, payload, width, x, y }: any) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	const gutter = 8;
	const isOut = x + width + gutter > containerWidth;

	return (
		<>
			<Box
				component="path"
				d={`M ${x},${y} h ${width} v ${height} h -${width} Z`}
				fill={payload.colors.node}
				onClick={() => {
					searchParams.set('dialog', 'dialogTransactionsEntity');
					searchParams.set('dialogNode', payload.index);
					setSearchParams(searchParams);
				}}
				x={x}
				y={y}
				width={width}
				height={height}
				radius="0"
				sx={(theme) => {
					return {
						cursor: 'pointer',
					};
				}}
			/>
			<text
				textAnchor={isOut ? 'end' : 'start'}
				x={isOut ? x - gutter : x + width + gutter}
				y={y + height / 2 + 6}
				fontSize="12"
				fontWeight={600}>
				{payload.name}
			</text>
		</>
	);
};

export const ChartSankeyLink = ({
	linkHovered,
	linkWidth,
	index,
	payload,
	setLinkClicked,
	setLinkHovered,
	sourceControlX,
	sourceRelativeY,
	sourceX,
	sourceY,
	targetControlX,
	targetRelativeY,
	targetX,
	targetY,
	...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	const color =
		linkHovered === null || linkHovered === index
			? payload.source.colors.link
			: theme.palette.brand.grey['200'];

	return (
		<Box
			component="path"
			d={`
			M${sourceX},${sourceY + linkWidth / 2}
			C${sourceControlX},${sourceY + linkWidth / 2}
				${targetControlX},${targetY + linkWidth / 2}
				${targetX},${targetY + linkWidth / 2}
			L${targetX},${targetY - linkWidth / 2}
			C${targetControlX},${targetY - linkWidth / 2}
				${sourceControlX},${sourceY - linkWidth / 2}
				${sourceX},${sourceY - linkWidth / 2}
			Z
		`}
			onClick={() => {
				searchParams.set('dialog', 'dialogTransactions');
				setLinkClicked(payload);
				setSearchParams(searchParams);
			}}
			onMouseEnter={() => {
				setLinkHovered(index);
			}}
			onMouseLeave={() => {
				setLinkHovered(null);
			}}
			fill={color}
			sx={(theme) => {
				return {
					cursor: 'pointer',
				};
			}}
			{...props}
		/>
	);
};

export const ChartSankey = ({ data, height }: ChartSankeyProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramNode = searchParams.get('dialogNode');

	// state
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [linkClicked, setLinkClicked] = useState<any>(null);
	const [linkHovered, setLinkHovered] = useState(null);

	// refs
	const refBox = useRef<HTMLElement>();

	const handleCloseDialog = () => {
		searchParams.delete('dialog');
		searchParams.delete('dialogNode');
		setSearchParams(searchParams);
	};

	if (!data) {
		return <></>;
	}

	let colorIndex = -1;

	// format nodes
	const nodesFormatted = data?.nodes.map((node, index) => {
		// if color index is maximum
		if (colorIndex === chartSankeyColors.length - 1) {
			// reset color index
			colorIndex = 0;
		} else {
			// otherwise increment color index
			colorIndex++;
		}

		return {
			...node,
			colors: chartSankeyColors[colorIndex],
			index,
		};
	});

	// define clicked entity and transactions using paramNode
	const nodeIndex = Number(paramNode);
	const nodeLinks =
		data?.links.filter((link) => link.source === nodeIndex || link.target === nodeIndex) || [];

	const entity = data?.nodes[nodeIndex];
	const transactions = [];

	for (let i = 0; i < nodeLinks.length; i++) {
		for (let t = 0; t < nodeLinks[i].transactions.length; t++) {
			transactions.push(nodeLinks[i].transactions[t]);
		}
	}

	transactions.sort((a, b) => {
		return a.date < b.date ? 1 : -1;
	});

	return (
		<Box
			ref={refBox}
			sx={() => {
				return {
					path: {
						transition: 'all 0.5s',
					},
				};
			}}>
			<ResponsiveContainer width="100%" height={height}>
				<Sankey
					data={{
						...data,
						nodes: nodesFormatted || [],
					}}
					link={
						<ChartSankeyLink
							linkHovered={linkHovered}
							setLinkClicked={setLinkClicked}
							setLinkHovered={setLinkHovered}
						/>
					}
					node={<ChartSankeyNode containerWidth={refBox.current?.clientWidth} />}
					nodePadding={50}>
					<Tooltip content={<ChartTooltip />} />
				</Sankey>
			</ResponsiveContainer>
			<DialogTransactions
				disablePortal={true}
				fromEntity={linkClicked?.source as Entity}
				isOpen={paramDialog === 'dialogTransactions'}
				onClose={handleCloseDialog}
				toEntity={linkClicked?.target as Entity}
				transactions={linkClicked ? (linkClicked.transactions as Transaction[]) : []}
			/>

			<DialogTransactionsEntity
				disablePortal={true}
				entity={entity}
				isOpen={paramDialog === 'dialogTransactionsEntity'}
				onClose={handleCloseDialog}
				transactions={transactions}
			/>
		</Box>
	);
};
