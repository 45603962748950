// react
import { useCallback, useContext, useState } from 'react';

// types
import { Account, AccountBalance, AccountEntity, Loan, Transaction } from '../../../@types';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// icons
import { ModeEditOutlined as IconModeEdit } from '@mui/icons-material';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Box, ChipProps, Grid, Paper, Typography } from '@mui/material';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipBox, ChipBoxProps } from '../../ui/ChipBox/ChipBox';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { Time } from '../../ui/Time/Time';
import { VisibilityTextToggle } from '../../ui/VisibilityTextToggle/VisibilityTextToggle';

// links
import { LinkAccount } from '../../links/LinkAccount/LinkAccount';
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// utils
import { formatCurrency } from '../../../utils/currency';

// dialogs
import { DialogAddAccountBalance } from '../../dialogs/DialogAddAccountBalance/DialogAddAccountBalance';
import { DialogEditAccount } from '../../dialogs/DialogEditAccount/DialogEditAccount';
import { DialogUpdateAccountStatus } from '../../dialogs/DialogUpdateAccountStatus/DialogUpdateAccountStatus';

// icons
import {
	AddCircleOutlineOutlined as IconAddCircle,
	ArrowCircleRightOutlined as IconArrowCircleRight,
	DoDisturbOutlined as IconDoDisturb,
	LocalAtmOutlined as IconLocalAtm,
	PaidOutlined as IconPaid,
	PowerSettingsNewOutlined as IconPowerSettingsNew,
	SwapHorizOutlined as IconSwapHoriz,
} from '@mui/icons-material';

// props
export interface CardAccountProps {
	account: Account;
	refetchAccounts?: () => void;
}

export const CardAccount = ({ account, refetchAccounts }: CardAccountProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogAccount = searchParams.get('dialogAccount');

	// context
	const { isOnboarded: isProjectOnboarded, project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// vars
	const hasCreateTransactionPermission = user?.permissions?.transaction?.create;
	const currency = account.currency;

	// state
	const [transaction, setTransaction] = useState<Transaction | null>(null);

	let total = 0;

	if (account.balances.length) {
		total = account.balances.reduce((total, account) => {
			return total + account.balance;
		}, 0);
	}

	const associatedAccounts = account.accounts as Account[];
	const associatedEntities = (account.entities as AccountEntity[]).map((rel) => rel.entity);

	let dateUpdated = null;

	if (account.modified || account.created) {
		dateUpdated = account.modified || account.created;
	}

	if (transaction?.created) {
		if (dateUpdated && new Date(transaction.created) > new Date(dateUpdated)) {
			dateUpdated = transaction.created;
		}
	}

	// fetch latest transaction
	useFetch({
		isEnabled: Boolean(account.id),
		url: `${process.env.REACT_APP_API_URL}/transactions?account=${account.id}&fields=created&orderBy=created&order=DESC&limit=1`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data?.length) {
				setTransaction(res.data[0]);
			}
		},
	});
	// handlers
	const handleOpenDialogAddAccountBalance = useCallback(() => {
		searchParams.set('dialog', 'addAccountBalance');
		searchParams.set('dialogAccount', String(account.id));
		setSearchParams(searchParams);
	}, [account, searchParams, setSearchParams]);

	// button menu items
	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.transaction?.create) {
		btnMenuItems.push(
			{
				children: t('addToBalance'),
				component: RouterLink,
				icon: <IconAddCircle />,
				onClick: handleOpenDialogAddAccountBalance,
			},
			{
				children: t('recordPayment'),
				component: RouterLink,
				icon: <IconPaid />,
				to: `/project/${project?.idWbg}/transactions/payments?dialog=recordPayment`,
			},
			{
				children: t('recordCashBenefit'),
				component: RouterLink,
				icon: <IconPaid />,
				to: `/project/${project?.idWbg}/transactions/cash-benefits?dialog=recordCashBenefit`,
			},
			{
				children: t('recordOpEx'),
				component: RouterLink,
				icon: <IconLocalAtm />,
				to: `/project/${project?.idWbg}/transactions/opex?dialog=recordOpEx`,
			},
			{
				children: t('recordFundTransfer'),
				component: RouterLink,
				divider: Boolean(user?.permissions?.account?.update),
				icon: <IconSwapHoriz />,
				to: `/project/${project?.idWbg}/transactions/transfers?dialog=recordFundTransfer`,
			}
		);
	}

	btnMenuItems.push({
		children: t('seeDetails'),
		component: RouterLink,
		icon: <IconArrowCircleRight />,
		to: `/project/${project?.idWbg}/account/${account.id}`,
	});

	if (user?.permissions?.account?.update) {
		btnMenuItems.push({
			children: t('edit'),
			icon: <IconModeEdit />,
			onClick: () => {
				searchParams.set('dialog', 'editAccount');
				searchParams.set('dialogAccount', String(account.id));
				setSearchParams(searchParams);
			},
		});
	}

	if (user?.permissions?.account?.update) {
		let btnLabel = t('deactivate');
		let btnIcon = <IconDoDisturb color="error" />;

		if (account.status === 'DEACTIVATED') {
			btnLabel = t('activate');
			btnIcon = <IconPowerSettingsNew color="success" />;
		}

		btnMenuItems.push({
			children: btnLabel,
			icon: btnIcon,
			onClick: () => {
				searchParams.set('dialog', 'updateAccountStatus');
				searchParams.set('dialogAccount', String(account.id));
				setSearchParams(searchParams);
			},
		});
	}

	const constructBalanceChipProps = (item: AccountBalance) => {
		let color = 'teal';
		let label = t('na');
		let value = t('na');

		if (item.loan) {
			const loan = item.loan as Loan;

			label = loan.code;

			if (['GOV', 'OTH'].includes(loan.financier)) {
				color = 'default';
			}
		}

		if (currency && typeof item.balance === 'number') {
			value = formatCurrency({
				currency,
				value: item.balance,
			});
		}

		return {
			color: color as ChipProps['color'],
			label,
			value,
		};
	};

	const balanceChips: ChipBoxProps[] = [];

	if (account.balances.length) {
		for (let i = 0; i < account.balances.length; i++) {
			const balanceChipProps = constructBalanceChipProps(account.balances[i]);
			balanceChips.push(balanceChipProps);
		}
	} else if (account.loans.length) {
		for (let i = 0; i < account.loans.length; i++) {
			const loan = account.loans[i] as Loan;
			const balanceChipProps = constructBalanceChipProps({
				balance: 0,
				currency: loan.currency,
				loan,
			});
			balanceChips.push(balanceChipProps);
		}
	}

	return (
		<Paper sx={{ position: 'relative' }}>
			<Box
				sx={(theme) => {
					return {
						borderBottom: `1px ${theme.palette.grey[300]} solid`,
						padding: theme.spacing(2),
					};
				}}>
				<Grid container spacing={2}>
					<Grid alignSelf="center" item xs={12} lg={2}>
						<Grid alignItems="center" container spacing={2}>
							<Grid item>
								<IconCircle type={account.type === 'LOCAL' ? 'local' : 'account'} />
							</Grid>
							<Grid item>
								<LinkAccount account={account} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} md={4} lg={2}>
						<Typography color="brand.grey.500">{t('accountNumber')}</Typography>
						<Typography component="div" fontWeight={500}>
							{account.num ? (
								<VisibilityTextToggle text={String(account.num)} />
							) : (
								<Typography>{t('na')}</Typography>
							)}
						</Typography>
					</Grid>
					<Grid item xs={6} md={4} lg={2}>
						<Typography color="brand.grey.500">{t('financialInstitution')}</Typography>
						<Typography fontWeight={500}>{account.institution || t('na')}</Typography>
					</Grid>
					<Grid item xs={6} md={4} lg={2}>
						<Typography color="brand.grey.500">{t('lastUpdated')}</Typography>
						{dateUpdated ? (
							<Time
								date={dateUpdated}
								TypographyProps={{ color: 'text.primary', fontWeight: 500 }}
							/>
						) : (
							t('na')
						)}
					</Grid>
					<Grid item xs={6} md={4} lg={2}>
						<Typography color="brand.grey.500">{t('balance')}</Typography>
						{account.balances.length > 0 ? (
							<Amount currency={currency} TypographyProps={{ variant: 'h3' }} value={total} />
						) : (
							<Typography variant="h3">{t('na')}</Typography>
						)}
					</Grid>
					<Grid item xs={6} md={4} lg={1}>
						<Typography color="brand.grey.500">{t('status')}</Typography>
						{isProjectOnboarded ? (
							<ChipStatus
								status={account.status}
								sx={(theme) => {
									return {
										marginTop: theme.spacing(0.5),
									};
								}}
							/>
						) : (
							<Typography fontWeight={500}>{t('na')}</Typography>
						)}
					</Grid>
					{isProjectOnboarded && (
						<Grid
							item
							xs={true}
							sx={(theme) => {
								return {
									position: 'absolute',
									top: theme.spacing(-1),
									right: theme.spacing(1),
									[theme.breakpoints.up('lg')]: {
										position: 'relative',
										top: 'auto',
										right: 'auto',
									},
								};
							}}>
							{hasCreateTransactionPermission && (
								<Grid container justifyContent="flex-end">
									<Grid item>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</Box>
			<Box
				sx={(theme) => {
					return {
						borderBottom: `1px ${theme.palette.brand.grey[300]} solid`,
					};
				}}>
				<Grid alignItems="center" container>
					<Grid item xs={12} md={3} lg={2}>
						<Box
							sx={(theme) => {
								return {
									padding: {
										xs: theme.spacing(2, 2, 0, 2),
										md: theme.spacing(2),
									},
								};
							}}>
							<Typography color="brand.grey.500">{t('financingSources')}</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={9}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid container spacing={2}>
								{balanceChips.length > 0 ? (
									balanceChips.map((chipProps, i) => {
										return (
											<Grid item key={`account-balance-${i}`} xs={12} md="auto">
												<ChipBox {...chipProps} />
											</Grid>
										);
									})
								) : (
									<Grid item xs={12}>
										<Typography>{t('na')}</Typography>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
					};
				}}>
				<Grid alignItems="center" container spacing={2}>
					<Grid item xs={12} md={3} lg={2}>
						<Typography color="brand.grey.500">{t('entities')}</Typography>
					</Grid>
					<Grid item xs={12} md={6} lg={7}>
						{associatedEntities.length > 0 ? (
							<Grid container spacing={{ xs: 1, md: 2 }}>
								{associatedEntities.map((entity, i) => {
									return (
										<Grid item key={`account-associated-entity-${i}`} xs={12} md="auto">
											<LinkEntity entity={entity} />
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Typography>{t('na')}</Typography>
						)}
					</Grid>
					{account.type === 'LOCAL' && (
						<Grid item xs={12} md={3} lg={2}>
							<Typography color="brand.grey.500">{t('designatedAccounts')}</Typography>
							{associatedAccounts.length > 0 ? (
								<Grid container spacing={1}>
									{associatedAccounts.map((associatedAccount, i) => {
										const isLast = i === associatedAccounts.length - 1;
										return (
											<Grid item key={`account-associated-account-${i}`}>
												<Typography fontWeight={500}>
													{associatedAccount.name}
													{!isLast ? ',' : ''}
												</Typography>
											</Grid>
										);
									})}
								</Grid>
							) : (
								<Typography>{t('na')}</Typography>
							)}
						</Grid>
					)}
				</Grid>
			</Box>
			<DialogAddAccountBalance
				account={account}
				isOpen={paramDialog === 'addAccountBalance' && paramDialogAccount === String(account.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogAccount');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchAccounts) {
						refetchAccounts();
					}
				}}
			/>
			<DialogEditAccount
				isOpen={paramDialog === 'editAccount' && paramDialogAccount === String(account.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogAccount');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchAccounts) {
						refetchAccounts();
					}
				}}
			/>
			<DialogUpdateAccountStatus
				account={account}
				isOpen={paramDialog === 'updateAccountStatus' && paramDialogAccount === String(account.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogAccount');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchAccounts) {
						refetchAccounts();
					}
				}}
			/>
		</Paper>
	);
};
