// types
import { Account, AccountEntityRelationship, Entity } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Grid, Paper } from '@mui/material';

// ui
import { entityIconTypes, IconCircle } from '../../../../ui/IconCircle/IconCircle';
import { Table, TableHeader } from '../../../../ui/Table/Table';

// links
import { LinkEntity } from '../../../../links/LinkEntity/LinkEntity';

// utils
import { translateRelationship } from '../../../../../utils/translations';

// props
export interface EntitiesProps {
	account: Account;
}

export const Entities = ({ account }: EntitiesProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers = [
		{
			label: t('entity'),
			render: (name: string, header: TableHeader, entity: Entity) => {
				return (
					<Grid alignItems="center" container spacing={1} wrap="nowrap">
						<Grid item>
							<IconCircle size="sm" type={entityIconTypes[entity.type]} />
						</Grid>
						<Grid item flex={1}>
							<LinkEntity entity={entity} />
						</Grid>
					</Grid>
				);
			},
			value: 'name',
		},
		{
			label: t('type'),
			value: 'type',
		},
		{
			label: t('relationship'),
			render: (relationship: AccountEntityRelationship) => {
				return translateRelationship(relationship, t);
			},
			value: 'relationship',
		},
	];

	const entities = account.entities.map((accountEntity) => {
		return {
			...accountEntity.entity,
			relationship: accountEntity.relationship,
		};
	});

	return (
		<Paper sx={{ height: '100%', overflow: 'hidden' }}>
			<Table color="grey" data={entities} headers={headers} />
		</Paper>
	);
};
