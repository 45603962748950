// mui
import { Box, Grid, Typography, TypographyProps } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../IconCircle/IconCircle';

// props
export interface BoxKeyValueProps {
	IconCircleProps: IconCircleProps;
	label: TypographyProps;
	value: TypographyProps;
}

export const BoxKeyValue = ({ IconCircleProps, label, value }: BoxKeyValueProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					borderColor: theme.palette.divider,
					borderRadius: 2,
					borderStyle: 'solid',
					borderWidth: 1,
					padding: theme.spacing(2),
					height: '100%',
				};
			}}>
			<Grid container spacing={2} sx={{ height: 'calc(100% + 16px)' }}>
				<Grid item xs={12}>
					<Grid container spacing={1}>
						<Grid item xs="auto">
							<IconCircle size="sm" {...IconCircleProps} />
						</Grid>
						<Grid item xs={true}>
							<Typography color="brand.grey.500" fontWeight={600} variant="caption" {...label} />
							<Typography fontWeight={600} {...value} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};
