// react
import { useContext, useMemo, useState } from 'react';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// types
import { Entity, Frame, Pagination, Transaction } from '../../../../@types';
import { transactionStatuses } from '../../../../@types/unions';

// router
import { useLocation, useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import { ContextProject } from '../../../pages/Project/Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// buttons
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// ui
import { BtnExport } from '../../../ui/BtnExport/BtnExport';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterChangeEvent, FilterProps } from '../../../ui/Filters/Filters';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardNonCashBenefit } from '../../../cards/CardNonCashBenefit/CardNonCashBenefit';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// utils
import { translateStatus } from '../../../../utils/translations';

// props
export interface TableNonCashBenefitsProps {
	frame?: Frame;
	isLoading?: boolean;
	pagination: Pagination;
	refetchTransactions?: () => void;
	transactions: Transaction[];
}

export const TableNonCashBenefits = ({
	frame = 'PROJECT',
	isLoading,
	pagination,
	refetchTransactions,
	transactions,
}: TableNonCashBenefitsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramStatus = searchParams.get('status');
	const paramToEntity = searchParams.get('toEntity');

	// defaults
	const defaultFiltersValues = {
		loan: 'all',
		status: 'all',
		toEntity: 'all',
	};

	// state
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [filtersValues, setFiltersValues] = useState({
		status: paramStatus || defaultFiltersValues.status,
		toEntity: paramToEntity || defaultFiltersValues.toEntity,
	});

	// fetch beneficiaries
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/entities?fields=id,name&project=${project?.id}&type=BENEFICIARY`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}
		},
	});

	// vars
	const headers = [
		{
			isSortable: true,
			label: t('date'),
			lg: 2,
			value: 'date',
		},
		{
			label: t('entity'),
			lg: 3,
			value: 'fromEntity',
		},
	];

	if (frame === 'PROJECT') {
		headers.push({
			label: t('beneficiary'),
			lg: 2,
			value: 'toEntity',
		});
	}

	headers.push(
		{
			label: t('status'),
			lg: 2,
			value: 'status',
		},
		{
			label: t('benefit'),
			lg: 2,
			value: 'benefit',
		}
	);

	const idFormFilters = 'form-nonCashBenefits-filters';

	// options
	const optionsBeneficiaries = beneficiaries.map((beneficiary) => {
		return {
			label: beneficiary.name,
			value: String(beneficiary.id),
		};
	});

	const optionsStatus = transactionStatuses.map((status) => {
		return {
			label: translateStatus(status, t),
			value: status,
		};
	});

	// handlers
	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	// filters
	const filters: FilterProps[] = [];

	if (frame === 'PROJECT') {
		filters.push({
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-beneficiary`,
			label: t('beneficiary'),
			name: 'toEntity',
			onChange: handleChangeFilterValue,
			options: optionsBeneficiaries,
			type: 'SELECT',
			value: filtersValues.toEntity,
		});
	}

	filters.push({
		hasAllOption: true,
		hasNoneOption: false,
		hasSearchParam: true,
		id: `${idFormFilters}-status`,
		label: t('status'),
		name: 'status',
		onChange: handleChangeFilterValue,
		options: optionsStatus,
		type: 'SELECT',
		value: filtersValues.status,
	});

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [],
			content: {
				children: t('empty.nonCashBenefits.content'),
			},
			IconCircleProps: {
				icon: <IconPaid />,
			},
			title: { children: t('empty.nonCashBenefits.title') },
		};

		if (
			emptyProps.actions &&
			user?.permissions.transaction.create &&
			!location.pathname.includes('entity')
		) {
			emptyProps.actions.push({
				children: t('recordNonCashBenefit'),
				onClick: () => {
					searchParams.set('dialog', 'recordNonCashBenefit');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		}

		// redefine empty content if search params exist
		if ([...searchParams].length && !paramDialog) {
			emptyProps.content = {
				children: t('empty.default.content'),
			};
			emptyProps.IconCircleProps = {
				icon: null,
			};
		}

		return emptyProps;
	}, [location, paramDialog, searchParams, setSearchParams, user]);

	const content = useMemo(() => {
		if (isLoading || !transactions.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{transactions.map((transaction, i) => {
					return (
						<Grid item key={`transaction-non-cash-benefit-${i}`} xs={12}>
							<CardNonCashBenefit
								refetchTransactions={refetchTransactions}
								transaction={transaction}
							/>
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoading, refetchTransactions, transactions]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={true}>
						<FormSearch />
					</Grid>
					<Grid item xs={6} md="auto">
						<BtnExport disabled={true} />
					</Grid>
					<Grid item container xs={6} md="auto">
						<BtnFilters
							filters={filters}
							onReset={() => {
								setFiltersValues(defaultFiltersValues);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={transactions.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
