// react
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import {
	Account,
	CsvField,
	CsvRow,
	ErrorCause,
	Loan,
	Metadata,
	MetadataValue,
	Option,
	TransactionPayload,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// forms
import { FormTableFields } from '../FormTableFields/FormTableFields';

// fields
import { InputFile } from '../../fields/InputFile/InputFile';
import { Select } from '../../fields/Select/Select';
import { SelectExpenseType } from '../../fields/SelectExpenseType/SelectExpenseType';

// ui
import { BannerProps } from '../../ui/Banner/Banner';

// utils
import { parseCsv } from '../../../utils/files';

// props
export interface FormUploadTransactionsProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormUploadTransactions = ({
	onClose,
	onSuccess,
	setIsLoading,
}: FormUploadTransactionsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// hooks
	const { t } = useTranslation();

	// defaults
	const defaultFields: CsvField[] = [
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('beneficiaryId'),
			name: 'toEntityExternal',
			type: 'NUMBER',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('amount'),
			name: 'payments-amount',
			type: 'NUMBER',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('date'),
			name: 'date',
			type: 'DATE',
		},
	];

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
	const [csvRows, setCsvRows] = useState<CsvRow[]>([]);
	const [fields, setFields] = useState<CsvField[]>(defaultFields);
	const [loans, setLoans] = useState<Loan[]>([]);

	// fetch accounts
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,institution,name,currency`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setAccounts(res.data);
			}
		},
	});

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=code,id,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// options
	const optionsAccounts: Option[] = accounts.map((account) => {
		return {
			label: account.name,
			secondary: account.institution,
			value: account.id,
		};
	});

	const optionsFinancingSources: Option[] = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.code,
			value: loan.id,
		};
	});

	const optionsHeaders: Option[] = csvHeaders.map((header: string) => {
		return {
			label: header,
			value: header,
		};
	});

	// vars
	const formId = 'form-upload-transactions';

	const handleCsv = (e: ProgressEvent<FileReader>) => {
		// define result as string
		const result = e.target?.result as string;

		if (result) {
			const { rows, headers } = parseCsv({ string: result, separator: ',' });

			// set csv headers and rows
			setCsvHeaders(headers);
			setCsvRows(rows);
		}
	};

	const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files?.length === 1) {
			const file = e.target.files[0];
			const reader = new FileReader();

			// process csv
			reader.onload = handleCsv;
			reader.readAsText(file);
		}
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		try {
			// define form data
			const form = document.querySelector(`#${formId}`) as HTMLFormElement;
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());

			// define account
			const accountSelected = accounts.find(
				(account) => account.id === Number(entries.fromAccount)
			);

			// define transactions
			const transactions = [];

			// for each row
			for (let i = 0; i < csvRows.length; i++) {
				const row = csvRows[i];

				// define transaction
				const transaction: Partial<TransactionPayload> = {
					expenseType: Number(entries.expenseType),
					fromAccount: Number(entries.fromAccount),
					metadata: {
						uuid: crypto.randomUUID(),
					},
					payments: [],
					project: project?.id,
					type: 'CASH_BENEFIT',
				};

				// for each field
				for (let f = 0; f < fields.length; f++) {
					const field = fields[f];

					if (!field.header && field.required) {
						throw new Error('Please select corresponding fields for all the required fields.', {
							cause: {
								id: 'BULK_UPLOAD_FIELD_EMPTY',
							},
						});
					}

					if (field.header) {
						let key: string = field.name;
						let value: MetadataValue = row[field.header];

						// format number
						if (field.type === 'NUMBER') {
							value = Number(value);
						}

						// format date
						if (field.type === 'DATE') {
							value = new Date(String(value));
						}

						if (field.name.includes('metadata-') && transaction.metadata) {
							// group metadata
							key = field.name.replace('metadata-', '');
							transaction.metadata[key as keyof Metadata] = value;
						} else if (field.name.includes('payments-')) {
							// group payments
							key = field.name.replace('payments-', '');

							if (key === 'amount' && transaction.payments) {
								transaction.payments.push({
									amount: Number(value),
									currency: String(accountSelected?.currency),
									loan: Number(entries.financingSource),
								});
							}
						} else {
							// add key and value to transaction
							transaction[field.name] = value;
						}
					}
				}

				transactions.push(transaction);
			}

			const fetchPostTransactions = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'post',
				body: JSON.stringify(transactions),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resPostTransactions = await fetchPostTransactions.json();

			if (resPostTransactions.error) {
				throw new Error(resPostTransactions.error.message, {
					cause: {
						id: resPostTransactions.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			const failedLength = resPostTransactions.data.failed.length;
			const succeededLength = resPostTransactions.data.succeeded.length;

			const banner: BannerProps = {
				hasClose: true,
				message: t('alert.transactionsUploadedPartial', {
					failed: failedLength,
					succeeded: succeededLength,
				}),
				severity: 'info',
			};

			const allSucceed = failedLength === 0;
			const allFailed = failedLength === transactions.length;

			if (allSucceed) {
				banner.message = t('alert.transactionsUploadedSuccess');
				banner.severity = 'success';
			} else if (allFailed) {
				banner.message = t('alert.transactionsUploadedError');
				banner.severity = 'error';
			}

			// set banner
			setBanner(banner);
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'BULK_UPLOAD_FIELD_EMPTY') {
				message = t('error.bulkUploadFieldEmpty');
			}

			// set error
			setError(message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} name="formUploadTransactions" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={4}>
					<Select
						id={`${formId}-sending-account`}
						label={t('sendingAccount')}
						name="fromAccount"
						options={optionsAccounts}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Select
						hasNoneOption={false}
						id={`${formId}-financing-source`}
						label={t('financingSource')}
						name="financingSource"
						options={optionsFinancingSources}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<SelectExpenseType
						id={`${formId}-expense-type`}
						label={t('expenseType')}
						name="expenseType"
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<InputFile
						helperText={t('acceptedFileFormats', { formats: '.csv' })}
						id={`${formId}-file-picker`}
						inputProps={{ accept: '.csv' }}
						label={t('selectFile')}
						onChange={handleSelectFile}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTableFields
						fields={fields}
						formId={formId}
						options={optionsHeaders}
						rows={csvRows}
						setFields={setFields}
						titleTotal={t('totalTransactions')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
