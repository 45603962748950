// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Benefit, ExpenseType, Transaction } from '../../../@types';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';

// forms
import { FormUploadDocuments } from '../../forms/FormUploadDocuments/FormUploadDocuments';

// utils
import { formatCurrency } from '../../../utils/currency';

// props
export interface DialogUploadDocumentsProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
	transaction: Transaction;
}

export const DialogUploadDocuments = ({
	isOpen,
	onClose,
	onSuccess,
	transaction,
}: DialogUploadDocumentsProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	// vars
	const amount = formatCurrency({
		currency: transaction.currency,
		value: transaction.amount,
	});

	let subtitle: DialogActionsProps['subtitle'] = undefined;

	if (transaction.benefit && transaction.quantity) {
		subtitle = {
			children: `${transaction.quantity} ${(transaction.benefit as Benefit).name}`,
		};
	} else if (transaction.amount) {
		subtitle = {
			children: amount,
		};

		if (transaction.expenseType) {
			subtitle.children = `${(transaction.expenseType as ExpenseType).name} (${amount})`;
		}
	}

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('uploadDocuments'),
					form: 'form-upload-documents',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			alignItems="center"
			hasClose={true}
			IconCircleProps={{
				type: 'document',
			}}
			isOpen={isOpen}
			onClose={onClose}
			subtitle={subtitle}
			title={{
				children: t('uploadDocuments'),
			}}>
			<FormUploadDocuments
				onClose={onClose}
				setIsLoading={setIsLoading}
				onSuccess={onSuccess}
				transaction={transaction}
			/>
		</DialogActions>
	);
};
