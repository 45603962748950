// react
import { useContext } from 'react';

// context
import { ContextLoans, ContextProject } from '../../Project.context';

// types
import { Project } from '../../../../../@types';

// i18next
import { useTranslation } from 'react-i18next';

// material
import { Alert, Container, Grid, Typography } from '@mui/material';

// tables
import { TableFinancingSources } from '../../../../tables/cards/TableFinancingSources/TableFinancingSources';

// pages
import { SummaryFinancingSources } from '../../../../ui/SummaryFinancingSources/SummaryFinancingSources';

// props
export interface LoansProps {
	hasSummary?: boolean;
	project: Project;
}

export const Loans = ({ hasSummary = false, project }: LoansProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { isLoadingLoans, loans } = useContext(ContextLoans);
	const { isOnboarded } = useContext(ContextProject);

	const numOfLoansToEnable = loans.reduce((num, loan) => {
		return loan.status === 'DISABLED' ? num + 1 : num;
	}, 0);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('financingSources')}</Typography>
				</Grid>
				{project.status === 'PENDING' && (
					<Grid item xs={12}>
						<Alert severity="warning" variant="outlined">
							{t('alert.loansCannotBeEnabled')}
						</Alert>
					</Grid>
				)}
				{project.status === 'ENABLED' && numOfLoansToEnable > 0 && (
					<Grid item xs={12}>
						<Alert severity="info" variant="outlined">
							{t('alert.loansToEnable')}
						</Alert>
					</Grid>
				)}
				{hasSummary && (
					<Grid item xs={12}>
						<SummaryFinancingSources loans={loans} />
					</Grid>
				)}
				<Grid item xs={12}>
					<TableFinancingSources
						hasFilters={isOnboarded}
						isLoading={isLoadingLoans}
						loans={loans}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
