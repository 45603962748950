// react
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

// types
import { BenefitPayload, ErrorCause, Benefit } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// ui
import { Loading } from '../../ui/Loading/Loading';

// props
export interface FormEditBenefitProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormEditBenefit = ({ onClose, onSuccess, setIsLoading }: FormEditBenefitProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [benefit, setBenefit] = useState<Benefit | null>(null);
	const [error, setError] = useState<string | null>(null);
	const [searchParams] = useSearchParams();
	const [values, setValues] = useState({
		name: '',
	});

	// params
	const paramBenefit = searchParams.get('dialogBenefit');

	// context
	const { setBanner } = useContext(ContextBanner);

	// vars
	const idForm = 'form-edit-benefit';

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch benefit
	const { isLoading: isLoadingBenefit } = useFetch({
		url: `${process.env.REACT_APP_API_URL}/benefits/${paramBenefit}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data && res.data.length) {
				setBenefit(res.data[0]);
			}
		},
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (!paramBenefit) {
				throw new Error('An ID is required', {
					cause: {
						id: 'ID_NON_EXISTENT',
					},
				});
			}

			const body: BenefitPayload = {
				name: String(entries.name),
			};

			const fetchEditBenefit = await fetch(
				`${process.env.REACT_APP_API_URL}/benefits/${paramBenefit}`,
				{
					method: 'PATCH',
					body: JSON.stringify(body),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);
			const resEditBenefit = await fetchEditBenefit.json();

			if (resEditBenefit.error) {
				throw new Error(resEditBenefit.error.message, {
					cause: {
						id: resEditBenefit.error.id,
						details: resEditBenefit.error?.details,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.benefitUpdated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'BENEFIT_NON_EXISTENT') {
					message = t('error.benefitNonExistent', { id: benefit?.id });
				}

				if (cause.id === 'ID_NON_EXISTENT') {
					message = t('error.idNonExistent');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	const handleChangeValue = (e: ChangeEvent | SelectChangeEvent<unknown>) => {
		const { name, value } = e.target as HTMLInputElement;
		setValues({
			...values,
			[name]: value,
		});
	};

	// content
	const renderContent = () => {
		if (isLoadingBenefit) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						onChange={handleChangeValue}
						required={true}
						value={values.name}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</>
		);
	};

	// set default values when benefit changes
	useEffect(() => {
		if (benefit) {
			setValues({
				name: benefit.name,
			});
		}
	}, [benefit]);

	return (
		<form id={`${idForm}`} name="formEditBenefit" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</form>
	);
};
