// types
import { EntityType } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../../../ui/IconCircle/IconCircle';
import { Table } from '../../../../ui/Table/Table';

// charts
import { ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';

// utils
import { translateType } from '../../../../../utils/translations';

// props
export interface EntitiesProps {
	dataSankey: ChartSankeyProps | null;
}

export const Entities = ({ dataSankey }: EntitiesProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const entityTypesCount = new Map<EntityType, number>();

	// calculate entity types count
	if (dataSankey?.data?.nodes?.length) {
		for (let i = 0; i < dataSankey.data.nodes.length; i++) {
			const node = dataSankey.data.nodes[i];

			if (node.type !== null) {
				entityTypesCount.set(node?.type, (entityTypesCount.get(node?.type) || 0) + 1);
			}
		}
	}

	const data = Array.from(entityTypesCount.entries(), ([type, total]) => ({ type, total })).sort(
		(a, b) => b.total - a.total
	);

	let totalNodes = 0;

	if (dataSankey?.data?.nodes) {
		// filter out nodes that don't have type
		totalNodes = dataSankey.data.nodes.filter((node) => node.type).length;
	}

	const headers = [
		{
			label: t('type'),
			render: (type: EntityType) => {
				const IconCircleProps: IconCircleProps = {
					size: 'sm',
					type: 'entity',
				};

				if (type === 'BENEFICIARY') {
					IconCircleProps.type = 'individual';
				} else if (type === 'SUPPLIER') {
					IconCircleProps.type = 'supplier';
				}

				return (
					<Grid alignItems="center" container spacing={1}>
						<Grid item>
							<IconCircle {...IconCircleProps} />
						</Grid>
						<Grid item>
							<Typography fontWeight={500}>{translateType(type, t)}</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'type',
		},
		{
			label: t('total'),
			render: (total: number) => {
				return (
					<Grid container spacing={1}>
						<Grid item>
							<Typography>{total}</Typography>
						</Grid>
						{totalNodes > 0 && (
							<Grid item>
								<Typography color="brand.grey.500">
									({Math.round((total / totalNodes) * 100)}%)
								</Typography>
							</Grid>
						)}
					</Grid>
				);
			},
			value: 'total',
		},
	];

	return (
		<Paper sx={{ height: '100%' }}>
			<Grid container>
				<Grid item xs={12}>
					<Box sx={{ padding: 2 }}>
						<Typography variant="h3">{t('participants')}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Table color="grey" data={data} headers={headers} />
				</Grid>
			</Grid>
		</Paper>
	);
};
