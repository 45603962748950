// types
import { TransactionType } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../../../ui/IconCircle/IconCircle';
import { Table } from '../../../../ui/Table/Table';

// charts
import { ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';

// utils
import { translateTransactionType } from '../../../../../utils/translations';

// props
export interface TransactionsProps {
	dataSankey: ChartSankeyProps | null;
}

export const Transactions = ({ dataSankey }: TransactionsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const transactions = [];
	const typesCount = new Map<TransactionType, number>();

	// calculate transaction types count
	if (dataSankey?.data?.links?.length) {
		for (let i = 0; i < dataSankey.data.links.length; i++) {
			const linkTransactions = dataSankey.data.links[i].transactions;

			for (let j = 0; j < linkTransactions.length; j++) {
				typesCount.set(
					linkTransactions[j]?.type,
					(typesCount.get(linkTransactions[j]?.type) || 0) + 1
				);
				transactions.push(linkTransactions[j]);
			}
		}
	}

	const data = Array.from(typesCount, ([type, total]) => ({ type, total })).sort(
		(a, b) => b.total - a.total
	);

	const headers = [
		{
			label: t('type'),
			render: (type: TransactionType) => {
				const IconCircleProps: IconCircleProps = {
					size: 'sm',
					type: 'payment',
				};

				if (type === 'CASH_BENEFIT') {
					IconCircleProps.type = 'cashBenefit';
				} else if (type === 'OPEX') {
					IconCircleProps.type = 'opex';
				} else if (type === 'TRANSFER') {
					IconCircleProps.type = 'transfer';
				}

				return (
					<Grid alignItems="center" container spacing={1}>
						<Grid item>
							<IconCircle {...IconCircleProps} />
						</Grid>
						<Grid item>
							<Typography fontWeight={500}>{translateTransactionType(type, t)}</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'type',
		},
		{
			label: t('total'),
			render: (total: number) => {
				return (
					<Grid container spacing={1}>
						<Grid item>
							<Typography>{total}</Typography>
						</Grid>
						<Grid item>
							<Typography color="brand.grey.500">
								({Math.round((total / transactions.length) * 100)}%)
							</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'total',
		},
	];

	return (
		<Paper sx={{ height: '100%' }}>
			<Grid container>
				<Grid item xs={12}>
					<Box sx={{ padding: 2 }}>
						<Typography variant="h3">{t('transactions')}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Table color="grey" data={data} headers={headers} />
				</Grid>
			</Grid>
		</Paper>
	);
};
