// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { DocumentType, ExpenseType } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// props
export interface DialogUpdateExpenseTypesProps {
	expenseTypes: ExpenseType[];
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export const DialogUpdateExpenseTypes = ({
	expenseTypes,
	isOpen,
	onClose,
}: DialogUpdateExpenseTypesProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// handlers
	const handleSubmit = async (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		const promises = [];

		// for each expense type
		for (let i = 0; i < expenseTypes.length; i++) {
			const docTypes = expenseTypes[i].documentTypes as DocumentType[];

			const fetchUpdateExpenseType = fetch(
				`${process.env.REACT_APP_API_URL}/expense-types/${expenseTypes[i].id}`,
				{
					method: 'PATCH',
					body: JSON.stringify({
						documentTypes: docTypes.map((dt) => {
							return {
								id: dt.id,
								isRequired: dt.isRequired,
							};
						}),
					}),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);

			promises.push(fetchUpdateExpenseType);
		}

		Promise.all(promises)
			.then((responses) => {
				// set banner
				setBanner({
					hasClose: true,
					message: t('alert.expenseTypesUpdated'),
					severity: 'success',
				});

				// close dialog
				if (onClose) {
					onClose();
				}
			})
			.catch((error) => {
				console.error(error);

				const msg = t('error.default');

				setError(msg);
			})
			.finally(() => {
				// clear loading
				setIsLoading(false);
			});
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: t('saveExpenseTypes'),
					loading: isLoading,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{ type: 'payment' }}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			title={{
				children: t('saveExpenseTypes'),
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>{t('dialogUpdateExpenseTypes.content')}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{t('alert.updateExpenseTypes')}</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
