// react
import { ReactNode, SyntheticEvent, useEffect, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// types
import { Option } from '../../../@types';

// mui
import {
	Autocomplete as MuiAutocomplete,
	AutocompleteChangeReason,
	AutocompleteProps as MuiAutocompleteProps,
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';

// ui
import TextField from '@mui/material/TextField';

// icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type MuiAutocompletePropsOmit = Omit<
	MuiAutocompleteProps<Option, boolean | undefined, boolean | undefined, boolean | undefined>,
	'renderInput'
>;

export interface AutocompleteProps extends Omit<MuiAutocompletePropsOmit, 'options'> {
	checkedIcon?: ReactNode;
	hasAllOption?: boolean;
	hasSearchParam?: boolean;
	icon?: ReactNode;
	label?: string;
	name?: string;
	options?: Option[];
	required?: boolean;
}

export const Autocomplete = ({
	checkedIcon = <CheckBoxIcon fontSize="medium" />,
	defaultValue,
	disableCloseOnSelect = true,
	hasAllOption = false,
	hasSearchParam = false,
	icon = <CheckBoxOutlineBlankIcon fontSize="medium" />,
	id,
	label,
	multiple = true,
	name,
	onChange,
	options = [],
	placeholder = '',
	required,
	size = 'small',
	value,
	...props
}: AutocompleteProps) => {
	// hooks
	const { t } = useTranslation();

	// defaults
	const defaultSelected = useMemo(() => {
		let valueNew: MuiAutocompletePropsOmit['value'] = multiple ? [] : '';

		if (defaultValue) {
			valueNew = defaultValue;
		} else if (value) {
			valueNew = value;
		}

		return valueNew;
	}, [defaultValue, multiple, value]);

	// state
	const [selected, setSelected] = useState<MuiAutocompletePropsOmit['value']>(defaultSelected);
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	const isSelectedArray = Array.isArray(selected);
	const selectedValues = (selected ? (selected as Option[]) : []).map((option) => option.value);

	// handlers
	const handleChangeValue = (
		e: SyntheticEvent<Element>,
		selected: Option | Option[] | null,
		reason: AutocompleteChangeReason
	) => {
		if (selected) {
			const { checked } = e.target as HTMLInputElement;
			let paramNew;
			let selectedNew = selected;

			// if selected is array
			if (Array.isArray(selectedNew)) {
				selectedNew = selected as Option[];

				// look for "all" option
				const optionAll = selectedNew.find((option) => option.label === t('all'));

				if (optionAll) {
					// set all options
					setSelected(checked ? options : []);
				} else {
					// filter out "all" option
					selectedNew = selectedNew.filter((option) => option.label !== t('all'));

					// define search param
					paramNew = selectedNew.map((option) => option.value).join(',');

					// set selected options
					setSelected(selectedNew);
				}
			} else {
				// define search param
				paramNew = selectedNew.value as string;

				// set selected options
				setSelected(selected);
			}

			if (hasSearchParam) {
				if (!paramNew) {
					searchParams.delete(name as string);
				} else {
					searchParams.set(name as string, paramNew);
				}

				setSearchParams(searchParams);
			}

			if (onChange) {
				onChange(e, selectedNew, reason);
			}
		}
	};

	// options
	const isAllOptionsDisabled = options.filter((o) => o.disabled).length === options.length;

	const optionAll = {
		disabled: isAllOptionsDisabled,
		label: t('all'),
		value: 'all',
	};

	// add all option
	if (!required && hasAllOption) {
		if (!options.find((option) => option.label === t('all'))) {
			options.unshift(optionAll);
		}
	}

	const isAllSelected =
		selected === 'all' || (isSelectedArray && selected.length === options.length - 1);

	// set selected when value changes
	useEffect(() => {
		if (value) {
			setSelected(value);
		}
	}, [value]);

	return (
		<FormControl sx={{ width: '100%' }}>
			{label && (
				<InputLabel
					htmlFor={id}
					required={required}
					shrink={false}
					sx={{ position: 'static', transform: 'none' }}>
					{label}
				</InputLabel>
			)}
			<MuiAutocomplete
				disableCloseOnSelect={true}
				getOptionLabel={(option) => (option as Option).label}
				id={id}
				isOptionEqualToValue={(option, value) => option.value === value.value}
				multiple={multiple}
				onChange={(e, selected, reason) => {
					handleChangeValue(e, selected as Option, reason);
				}}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				options={options as Option[]}
				renderInput={(params) => <TextField {...params} name={name} placeholder={placeholder} />}
				size={size}
				renderOption={(props, option) => {
					const isAllOption = option.value === 'all';
					const isIndeterminate =
						isAllOption &&
						isSelectedArray &&
						selected.length > 0 &&
						selected.length < options.length - 1;

					return (
						<MenuItem {...props}>
							<Checkbox
								checked={selectedValues.includes(option.value) || (isAllOption && isAllSelected)}
								checkedIcon={checkedIcon}
								disableRipple={true}
								icon={icon}
								id={`${id}-checkbox-${option.value}`}
								indeterminate={isIndeterminate}
								name={`${id}-checkbox-${option.label}`}
								value={option.value}
							/>
							<ListItemText primary={option.label} secondary={option.secondary} />
						</MenuItem>
					);
				}}
				renderTags={() => {
					// define label as comma separated list
					let label = (selected as Option[]).map((option) => option.label).join(', ');

					if (
						selectedValues.includes('all') ||
						(selected as Option[]).length === options.length - 1
					) {
						label = t('all');
					}

					return (
						<Typography
							sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '80%',
								whiteSpace: 'nowrap',
							}}>
							{label}
						</Typography>
					);
				}}
				value={value !== undefined ? value : selected}
				{...props}
			/>
		</FormControl>
	);
};
