// react
import { SyntheticEvent, useContext, useState } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// types
import { Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// headers
import { getTransactionsHeaders } from './TableBasicSoe.headers';

// ui
import { Table, TableHeader, TableProps } from '../../../ui/Table/Table';
import { ContextProject } from '../../../pages/Project/Project.context';

// props
export interface TableBasicSoeProps extends Omit<TableProps, 'data' | 'headers'> {
	transactions?: Transaction[];
	isLoading?: boolean;
}

export const TableBasicSoe = ({
	transactions = [],
	isLoading = false,
	...props
}: TableBasicSoeProps) => {
	// state
	const [transactionsUnchecked, setTransactionsUnchecked] = useState<number[]>([]);

	// hooks
	const { t } = useTranslation();
	const { project } = useContext(ContextProject);
	const [searchParams, setSearchParams] = useSearchParams();

	// handlers
	const handleChangeCheckBox = (e: SyntheticEvent<Element>, id: number) => {
		const { checked } = e.target as HTMLInputElement;
		let transactionsUncheckedNew = structuredClone(transactionsUnchecked);
		if (checked) {
			transactionsUncheckedNew = transactionsUncheckedNew.filter((txId) => txId !== id);
		} else {
			transactionsUncheckedNew.push(id);
		}
		setTransactionsUnchecked(transactionsUncheckedNew);

		if (transactionsUncheckedNew.length > 0) {
			searchParams.set('id', transactionsUncheckedNew.map((id) => `!${id}`).join(','));
		} else {
			searchParams.delete('id');
		}
		setSearchParams(searchParams);
	};

	// vars
	const headers: TableHeader[] = getTransactionsHeaders({
		t,
		project,
		handleChangeCheckBox,
		transactionsUnchecked,
	});

	return (
		<Table
			borderRadius={2}
			color="blue"
			colorBorder="grey"
			hasDividers={true}
			hasStripes={true}
			headers={headers}
			sxTd={{
				textWrap: {
					xs: 'nowrap',
				},
			}}
			{...props}
			data={transactions}
			isLoading={isLoading}
		/>
	);
};
