// l18n
import { TFunction } from 'i18next';

// mui
import { Grid, Typography } from '@mui/material';

// table
import { TableHeader } from '../../../ui/Table/Table';

// types
import { ExpenseType, Transaction, TransactionStatus, TransactionType } from '../../../../@types';

// ui
import { Amount } from '../../../ui/Amount/Amount';
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { IconCircle, transactionIconTypes } from '../../../ui/IconCircle/IconCircle';
import { Time } from '../../../ui/Time/Time';

// utils
import { translateTransactionType } from '../../../../utils/translations';

// props
export interface GetTransactionsHeadersParams {
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
}

export const getTransactionsHeaders = ({ t }: GetTransactionsHeadersParams): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t('type'),
			value: 'type',
			render: (type: TransactionType) => {
				return (
					<Grid alignItems="center" container wrap="nowrap" spacing={1}>
						<Grid item>
							<IconCircle size="sm" type={transactionIconTypes[type]} />
						</Grid>
						<Grid flex={1} item>
							<Typography fontWeight={600}>{translateTransactionType(type, t)}</Typography>
						</Grid>
					</Grid>
				);
			},
		},
		{
			label: t('date'),
			value: 'date',
			render: (date: Date, header: TableHeader, transaction: Transaction) => {
				return <Time date={new Date(date || transaction.created)} />;
			},
		},
		{
			label: t('expenseType'),
			value: 'expenseType',
			render: (expenseType: ExpenseType) => {
				return <Typography>{expenseType ? expenseType.name : t('na')}</Typography>;
			},
		},
		{
			label: t('status'),
			value: 'status',
			render: (status: TransactionStatus) => {
				return <ChipStatus status={status} />;
			},
		},
		{
			label: t('amount'),
			value: 'amount',
			render: (amount: number, header: TableHeader, transaction: Transaction) => {
				return <Amount currency={transaction.currency} value={amount} />;
			},
		},
	];

	return columns;
};
