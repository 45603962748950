// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import {
	Benefit,
	Entity,
	ErrorCause,
	TransactionMetadata,
	TransactionPayload,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { FilePicker } from '../../fields/FilePicker/FilePicker';
import { Select } from '../../fields/Select/Select';

// utils
import { formatDate } from '../../../utils/dates';

// props
export interface FormAddNonCashBenefitProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddNonCashBenefit = ({
	onClose,
	onSuccess,
	setIsLoading,
}: FormAddNonCashBenefitProps) => {
	// hooks
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// params
	const paramToEntity = searchParams.get('dialogToEntity');

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [benefits, setBenefits] = useState<Benefit[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [files, setFiles] = useState<File[]>([]);

	// vars
	const idForm = 'form-add-non-cash-benefit';
	const userEntity = user?.entity ? (user?.entity as Entity) : null;
	const hasEntityField = Boolean(!userEntity);

	// fetch beneficiaries
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=BENEFICIARY`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,wallet&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU&populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch benefits
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/benefits?project=${project?.id}&fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBenefits(res.data);
			}
		},
	});

	// options
	const optionsBeneficiaries = beneficiaries.map((beneficiary) => {
		return {
			label: beneficiary.name,
			value: beneficiary.id,
		};
	});

	const optionsBenefits = benefits.map((benefit) => {
		return {
			label: benefit.name,
			value: benefit.id,
		};
	});

	const optionsEntities = entities.map((entity) => {
		return {
			disabled: entity.wallet?.status === 'INACTIVE',
			label: entity.name,
			value: entity.id,
		};
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-add-non-cash-benefit') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// set loader
			setIsLoading(true);

			const payload: TransactionPayload = {
				benefit: Number(entries.benefit),
				date: formatDate({ date: new Date(entries.date as string), format: 'YYYY-MM-DD' }),
				fromEntity: Number(userEntity?.id || entries.fromEntity),
				metadata: {
					uuid: crypto.randomUUID(),
				},
				project: project?.id,
				quantity: Number(entries.quantity),
				status: 'COMPLETED',
				toEntity: Number(paramToEntity || entries.toEntity),
				type: 'NON_CASH_BENEFIT',
			};

			if (entries.comments) {
				payload.metadata = {
					...(payload.metadata as TransactionMetadata),
					comments: entries.comments as string,
				};
			}

			const fetchAddTransaction = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(payload),
			});
			const resAddTransaction = await fetchAddTransaction.json();

			if (resAddTransaction.error) {
				throw new Error(resAddTransaction.error.message, {
					cause: {
						id: resAddTransaction.error.id,
					},
				});
			}

			const transaction = resAddTransaction.data;

			// for each file
			for (let i = 0; i < files.length; i++) {
				// define form data
				const formData = new FormData();

				// define path
				const dateToday = new Date();
				const year = dateToday.getFullYear();
				const month = new Intl.DateTimeFormat('en-US', {
					month: '2-digit',
				}).format(dateToday);

				// append form data
				formData.append(
					'path',
					`bldt/projects/${project?.id}/transactions/non-cash-benefits/${transaction.id}/${year}/${month}/`
				);
				formData.append('file', files[i]);
				formData.append('transaction', String(transaction.id));

				if (project) {
					formData.append('project', String(project.id));
				}

				// POST document
				const fetchPostDoc = await fetch(`${process.env.REACT_APP_API_URL}/documents`, {
					method: 'post',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				const resPostDoc = await fetchPostDoc.json();

				if (resPostDoc.error) {
					throw new Error(resPostDoc.error.message, {
						cause: {
							id: resPostDoc.error.id,
						},
					});
				}
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.nonCashBenefitRecorded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'TRANSACTION_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formAddNonCashBenefit" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{hasEntityField && (
					<Grid item xs={12} md={paramToEntity ? 12 : 6}>
						<Select
							id={`${idForm}-from-entity`}
							label={t('entity')}
							name="fromEntity"
							options={optionsEntities}
							required={true}
						/>
					</Grid>
				)}
				{!paramToEntity && (
					<Grid item xs={12} md={hasEntityField ? 6 : 12}>
						<Select
							id={`${idForm}-to-entity`}
							label={t('beneficiary')}
							name="toEntity"
							options={optionsBeneficiaries}
							required={true}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={6}>
					<Select
						id={`${idForm}-benefit`}
						label={t('benefit')}
						name="benefit"
						options={optionsBenefits}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-quantity`}
						label={t('quantity')}
						name="quantity"
						required={true}
						type="number"
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('date')}
						name="date"
						required={true}
						type="date"
					/>
				</Grid>
				<Grid item xs={12}>
					<FilePicker onChange={(e, filesSelected) => setFiles(filesSelected)} />
				</Grid>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-comments`}
						label={t('comments')}
						minRows={2}
						multiline={true}
						name="comments"
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
