// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Benefit, Entity, Transaction } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextUser } from '../../../App.context';

// mui
import { Alert, Box, Grid, Paper } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// tables
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// dialogs
import { DialogUploadDocuments } from '../../dialogs/DialogUploadDocuments/DialogUploadDocuments';

// ui
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { LineItem } from '../../ui/LineItem/LineItem';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// icons
import { InsertDriveFileOutlined as IconInsertDriveFile } from '@mui/icons-material';

// theme
import { theme } from '../../../theme';
import { MenuItemProps } from '../../ui/Menu/Menu';

// props
export interface CardNonCashBenefitProps {
	refetchTransactions?: () => void;
	transaction: Transaction;
}

export const CardNonCashBenefit = ({
	refetchTransactions,
	transaction,
}: CardNonCashBenefitProps) => {
	// context
	const { user } = useContext(ContextUser);

	// hooks
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogTransaction = searchParams.get('dialogTransaction');

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [tabValue, setTabValue] = useState('documents');

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const tabs = [
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('comments'),
			value: 'comments',
		},
	];

	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.document?.create && user?.permissions?.transaction?.update) {
		btnMenuItems.push({
			icon: <IconInsertDriveFile />,
			children: t('uploadDocuments'),
			onClick: () => {
				searchParams.set('dialog', 'uploadDocuments');
				searchParams.set('dialogTransaction', String(transaction.id));
				setSearchParams(searchParams);
			},
		});
	}

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type="nonCashBenefit" />
									</Grid>
									<Grid item>
										<Time date={transaction.date} />
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} lg={3}>
							<RowKeyValue
								label={t('entity')}
								value={<LinkEntity entity={transaction.fromEntity as Entity} />}
							/>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue label={t('beneficiary')} value={(transaction.toEntity as Entity).name} />
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue label={t('status')} value={<ChipStatus status={transaction.status} />} />
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('benefit')}
								value={`${transaction.quantity} ${(transaction.benefit as Benefit).name}`}
							/>
						</Grid>
						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="documents">
							<TableBasicDocs transaction={transaction} />
						</TabPanel>
						<TabPanel value="comments">
							{transaction?.metadata?.comments ? (
								<LineItem
									content={transaction?.metadata?.comments}
									IconCircleProps={{ size: 'sm', type: 'comment' }}
								/>
							) : (
								<Alert severity="info">{t('alert.empty')}</Alert>
							)}
						</TabPanel>
					</TabContext>
				)}
			</Paper>

			<DialogUploadDocuments
				isOpen={
					paramDialog === 'uploadDocuments' && paramDialogTransaction === String(transaction.id)
				}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogTransaction');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchTransactions) {
						refetchTransactions();
					}
				}}
				transaction={transaction}
			/>
		</>
	);
};
