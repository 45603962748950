// react
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

// mui
import { Container, Grid, Typography } from '@mui/material';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject, ContextSuppliers } from '../Project.context';

// router
import { Outlet, Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';

// ui
import { Tabs } from '../../../ui/Tabs/Tabs';

// dialogs
import { DialogUploadSuppliers } from '../../../dialogs/DialogUploadSuppliers/DialogUploadSuppliers';
import { DialogAddSupplier } from '../../../dialogs/DialogAddSupplier/DialogAddSupplier';

export const Suppliers = () => {
	// hooks
	const { t } = useTranslation();
	const location = useLocation();

	// context
	const { project } = useContext(ContextProject);
	const { refetchSuppliers } = useContext(ContextSuppliers);

	// defaults
	const defaultTabValue = useMemo(() => {
		if (location.pathname.includes('documents')) {
			return 'documents';
		}
		return 'details';
	}, [location]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState(defaultTabValue);

	// vars
	const paramDialog = searchParams.get('dialog');
	const urlBase = `/project/${project?.idWbg}/suppliers`;
	const tabs = [
		{
			component: RouterLink,
			label: t('details'),
			to: `${urlBase}/details`,
			value: 'details',
		},
		{
			component: RouterLink,
			label: t('documents'),
			to: `${urlBase}/documents`,
			value: 'documents',
		},
	];

	// handlers
	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// set active tab
	useEffect(() => {
		let tabValueByLocation = 'details';

		if (location.pathname.includes('documents')) {
			tabValueByLocation = 'documents';
		}
		setTabValue(tabValueByLocation);
	}, [location]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Container maxWidth={false}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h2">{t('suppliers')}</Typography>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Grid item xs={12}>
					<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
				</Grid>
				<Grid item xs={12}>
					<Outlet />
				</Grid>
			</Grid>
			<DialogUploadSuppliers
				isOpen={paramDialog === 'uploadSuppliers'}
				onClose={() => {
					setSearchParams((params) => {
						params.delete('dialog');
						return params;
					});
				}}
				onSuccess={() => {
					refetchSuppliers();
				}}
			/>
			<DialogAddSupplier
				isOpen={paramDialog === 'addSupplier'}
				onClose={() => {
					setSearchParams((params) => {
						params.delete('dialog');
						return params;
					});
				}}
				onSuccess={() => {
					refetchSuppliers();
				}}
			/>
		</>
	);
};
