// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Entity, Loan, MetadataField, Payment, Transaction, User } from '../../../@types';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// tables
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';
import { TableBasicNonCashBenefits } from '../../tables/basic/TableBasicNonCashBenefits/TableBasicNonCashBenefits';
import { TableBasicPayments } from '../../tables/basic/TableBasicPayments/TableBasicPayments';
import { TableBasicUsers } from '../../tables/basic/TableBasicUsers/TableBasicUsers';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle, IconCircleType } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Metadata } from '../../ui/Metadata/Metadata';

// dialogs
import { DialogEditBeneficiary } from '../../dialogs/DialogEditBeneficiary/DialogEditBeneficiary';
import { DialogUpdateEntityStatus } from '../../dialogs/DialogUpdateEntityStatus/DialogUpdateEntityStatus';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// icons
import {
	DoDisturbOutlined as IconDoDisturb,
	ModeEditOutlined as IconModeEdit,
	PaidOutlined as IconPaid,
	PowerSettingsNewOutlined as IconPowerSettingsNew,
	VolunteerActivismOutlined as IconVolunteerActivism,
} from '@mui/icons-material';

// utils
import { formatCurrency } from '../../../utils/currency';
import { translateType } from '../../../utils/translations';

// theme
import { theme } from '../../../theme';

// props
export interface CardBeneficiaryProps {
	beneficiary: Entity;
	refetchBeneficiaries?: () => void;
	refetchEntities?: () => void;
}

export const CardBeneficiary = ({
	beneficiary,
	refetchBeneficiaries,
	refetchEntities,
}: CardBeneficiaryProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramDialog = searchParams.get('dialog');
	const paramDialogBeneficiary = searchParams.get('dialogBeneficiary');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [cashBenefits, setCashBenefits] = useState<Transaction[]>([]);
	const [isExpanded, setIsExpanded] = useState(false);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [nonCashBenefits, setNonCashBenefits] = useState<Transaction[]>([]);
	const [tabValue, setTabValue] = useState('cashBenefits');
	const [users, setUsers] = useState<User[]>([]);

	// fetch loans
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${beneficiary.id}&fields=id,idWbg,entities&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch users
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/users?entity=${beneficiary.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}
		},
	});

	// fetch cash benefits
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/transactions?type=CASH_BENEFIT&toEntity=${beneficiary.id}&populate=expenseType,fromAccount,payments.loan`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setCashBenefits(res.data);
			}
		},
	});

	// fetch non cash benefits
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/transactions?type=NON_CASH_BENEFIT&toEntity=${beneficiary.id}&populate=benefit,fromEntity`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setNonCashBenefits(res.data);
			}
		},
	});

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const associatedEntity = loans?.[0]?.entities?.[0]?.parent;

	// metadata vars
	let metadataProtected;
	let metadataTotalBenefits;
	let metadataCurrency;

	const tabs = [
		{
			label: t('cashBenefits'),
			value: 'cashBenefits',
		},
		{
			label: t('nonCashBenefits'),
			value: 'nonCashBenefits',
		},
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('users'),
			value: 'users',
		},
	];

	if (beneficiary.metadata && Array.isArray(beneficiary?.metadata)) {
		// set publicly accessible metadata
		metadataTotalBenefits = beneficiary?.metadata?.find(
			(metadata) => metadata.name === 'totalBenefits'
		);
		metadataCurrency = beneficiary?.metadata?.find((metadata) => metadata.name === 'currency');

		// filter protected metadata
		metadataProtected = beneficiary.metadata.filter((metadata) => metadata.isProtected);

		if (metadataProtected.length) {
			tabs.push({
				label: t('metadata'),
				value: 'metadata',
			});
		}
	}
	let totalBenefitsValue;
	if (metadataTotalBenefits?.value && metadataCurrency?.value) {
		totalBenefitsValue = formatCurrency({
			currency: String(metadataCurrency.value),
			value: Number(metadataTotalBenefits.value),
		});
	} else if (metadataTotalBenefits?.value) {
		totalBenefitsValue = metadataTotalBenefits.value;
	} else {
		totalBenefitsValue = t('na');
	}

	const payments: Payment[] = [];

	// for each cash benefit
	for (let i = 0; i < cashBenefits.length; i++) {
		const transaction = cashBenefits[i];

		// for each payment
		for (let p = 0; p < transaction.payments.length; p++) {
			transaction.payments[p].transaction = transaction as Transaction;
			payments.push(transaction.payments[p]);
		}
	}

	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions.transaction.create) {
		btnMenuItems.push(
			{
				children: t('recordCashBenefit'),
				component: RouterLink,
				icon: <IconPaid />,
				to: `/project/${
					project?.idWbg
				}/transactions/cash-benefits?dialog=recordCashBenefit&dialogToEntity=${String(
					beneficiary.id
				)}`,
			},
			{
				children: t('recordNonCashBenefit'),
				component: RouterLink,
				divider: true,
				icon: <IconVolunteerActivism />,
				to: `/project/${
					project?.idWbg
				}/transactions/non-cash-benefits?dialog=recordNonCashBenefit&dialogToEntity=${String(
					beneficiary.id
				)}`,
			}
		);
	}

	if (user?.entity && user?.permissions.entity.update) {
		btnMenuItems.push({
			children: t('edit'),
			icon: <IconModeEdit />,
			onClick: () => {
				searchParams.set('dialog', 'editBeneficiary');
				searchParams.set('dialogBeneficiary', String(beneficiary.id));
				setSearchParams(searchParams);
			},
		});
	}

	if (user?.permissions.entity.update) {
		if (beneficiary.wallet?.status !== 'INACTIVE') {
			btnMenuItems.push({
				children: t('deactivate'),
				icon: <IconDoDisturb color="error" />,
				onClick: () => {
					searchParams.set('dialog', 'updateBeneficiaryStatus');
					searchParams.set('dialogBeneficiary', String(beneficiary.id));
					setSearchParams(searchParams);
				},
			});
		} else {
			btnMenuItems.push({
				children: t('activate'),
				icon: <IconPowerSettingsNew color="success" />,
				onClick: () => {
					searchParams.set('dialog', 'updateBeneficiaryStatus');
					searchParams.set('dialogBeneficiary', String(beneficiary.id));
					setSearchParams(searchParams);
				},
			});
		}
	}

	const currency = payments?.[0]?.currency;
	const totalPaid = payments.reduce((total, payment) => {
		return (total += payment.amount);
	}, 0);

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
						overflow: 'hidden',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={beneficiary.subtype?.toLowerCase() as IconCircleType} />
									</Grid>
									<Grid item>
										<Typography
											color="brand.grey.500"
											component="div"
											fontWeight={600}
											variant="caption">
											{beneficiary.idExternal}
										</Typography>
										<Typography component="div" fontWeight={600}>
											{beneficiary.name}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('wallet')}
								value={
									beneficiary.wallet?.address ? (
										<BlockchainHash hash={beneficiary.wallet.address} />
									) : (
										t('na')
									)
								}
							/>
						</Grid>

						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('type')}
								value={<Typography>{translateType(beneficiary.subtype, t)}</Typography>}
							/>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('entity')}
								value={
									associatedEntity ? (
										<LinkEntity entity={associatedEntity} />
									) : (
										<Typography>{t('na')}</Typography>
									)
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('status')}
								value={<ChipStatus status={beneficiary.wallet?.status || 'ACTIVE'} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('paid')}
								value={<Amount currency={currency} notation="compact" value={totalPaid} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue label={t('totalBenefits')} value={totalBenefitsValue} />
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('traceability')}
								value={<ChipTraceability traceability={beneficiary.traceability} />}
							/>
						</Grid>

						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="cashBenefits">
							<TableBasicPayments payments={payments} />
						</TabPanel>
						<TabPanel value="nonCashBenefits">
							<TableBasicNonCashBenefits transactions={nonCashBenefits} />
						</TabPanel>
						<TabPanel value="documents">
							<TableBasicDocs entity={beneficiary.id} />
						</TabPanel>
						<TabPanel value="users">
							<TableBasicUsers data={users} />
						</TabPanel>
						<TabPanel value="metadata">
							<Box
								sx={(theme) => {
									return {
										backgroundColor: theme.palette.brand.blue[100],
									};
								}}>
								<Metadata data={beneficiary?.metadata as MetadataField[]} />
							</Box>
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogEditBeneficiary
				isOpen={
					paramDialog === 'editBeneficiary' && paramDialogBeneficiary === String(beneficiary.id)
				}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogBeneficiary');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchBeneficiaries) {
						refetchBeneficiaries();
					}
				}}
			/>
			<DialogUpdateEntityStatus
				entity={beneficiary}
				isOpen={
					paramDialog === 'updateBeneficiaryStatus' &&
					paramDialogBeneficiary === String(beneficiary.id)
				}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogBeneficiary');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchEntities) {
						refetchEntities();
					}
				}}
			/>
		</>
	);
};
