// types
import { Entity, Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// headers
import { getTransactionsHeaders } from './TableBasicTransactionsEntity.headers';

// ui
import { Table, TableHeader, TableProps } from '../../../ui/Table/Table';

// props
export interface TableBasicTransactionsEntityProps extends Omit<TableProps, 'data' | 'headers'> {
	entity: Entity;
	transactions?: Transaction[];
}

export const TableBasicTransactionsEntity = ({
	entity,
	transactions = [],
	...props
}: TableBasicTransactionsEntityProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers: TableHeader[] = getTransactionsHeaders({ entity, t });

	return <Table {...props} data={transactions} headers={headers} />;
};
