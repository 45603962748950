// react
import { ChangeEvent, useContext, useState } from 'react';

// types
import { DocumentType, Entity, ExpenseType } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// context
import { ContextProject } from '../../Project.context';
import { ContextUser } from '../../../../../App.context';

// mui
import { Box, Checkbox, Container, Grid, Paper, Switch, SxProps, Typography } from '@mui/material';

// forms
import { FormAddDocType } from '../../../../forms/FormAddDocType/FormAddDocType';
import { FormAddExpenseType } from '../../../../forms/FormAddExpenseType/FormAddExpenseType';
import { FormSearch } from '../../../../forms/FormSearch/FormSearch';

// dialogs
import { DialogDeleteDocumentType } from '../../../../dialogs/DialogDeleteDocumentType/DialogDeleteDocumentType';
import { DialogDeleteExpenseType } from '../../../../dialogs/DialogDeleteExpenseType/DialogDeleteExpenseType';
import { DialogEditDocumentType } from '../../../../dialogs/DialogEditDocumentType/DialogEditDocumentType';
import { DialogEditExpenseType } from '../../../../dialogs/DialogEditExpenseType/DialogEditExpenseType';
import { DialogUpdateExpenseTypes } from '../../../../dialogs/DialogUpdateExpenseTypes/DialogUpdateExpenseTypes';

// ui
import { BtnMenu } from '../../../../ui/BtnMenu/BtnMenu';
import { Empty } from '../../../../ui/Empty/Empty';
import { TableHeaders } from '../../../../ui/TableHeaders/TableHeaders';

// icons
import {
	DeleteOutlined as IconDelete,
	ModeEditOutlined as IconModeEdit,
} from '@mui/icons-material';

export const ExpenseTypes = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [activeExpenseType, setActiveExpenseType] = useState<ExpenseType | null>(null);
	const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
	const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
	const [expenseTypesNew, setExpenseTypesNew] = useState<ExpenseType[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramQ = searchParams.get('q');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// vars
	const isWfa = user?.role === 'WFA';
	const userEntity = user?.entity as Entity;

	let activeDocTypes = activeExpenseType?.documentTypes as DocumentType[];
	let urlDocumentTypes = `${process.env.REACT_APP_API_URL}/document-types?order=ASC&orderBy=name`;
	let urlExpenseTypes = `${process.env.REACT_APP_API_URL}/expense-types?project=${project?.id}&populate=documentTypes&order=ASC&orderBy=name`;

	if (user?.entity) {
		urlDocumentTypes += `&entity=null,${userEntity.id}`;
		urlExpenseTypes += `&entity=null,${userEntity.id}`;
	}

	// fetch doc types
	const { isLoading: isLoadingDocumentTypes, refetch: refetchDocumentTypes } = useFetch({
		isEnabled: Boolean(user),
		url: urlDocumentTypes,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocTypes(res.data);
			}
		},
	});

	// fetch expense types
	const { isLoading: isLoadingExpenseTypes, refetch: refetchExpenseTypes } = useFetch({
		isEnabled: Boolean(project?.id && user),
		url: urlExpenseTypes,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setExpenseTypes(res.data);
				setExpenseTypesNew(res.data);
				setActiveExpenseType(res.data[0]);
			}
		},
	});

	// handlers
	const handleChangeDocType = (e: ChangeEvent<HTMLInputElement>, docType: DocumentType) => {
		if (e.target.checked) {
			// add doc type
			activeDocTypes.push(docType);
		} else {
			// remove doc type
			activeDocTypes = activeDocTypes.filter((d) => d.id !== docType.id);
		}

		// clone new expense types
		const expenseTypesCloned = structuredClone(expenseTypesNew);

		// define active expense type from clone
		const expenseTypeToBeUpdated = expenseTypesCloned.find(
			(exp) => exp.id === activeExpenseType?.id
		);

		if (expenseTypeToBeUpdated) {
			// format and set doc types
			expenseTypeToBeUpdated.documentTypes = activeDocTypes.map((dt) => {
				return {
					...dt,
					isRequired: 'isRequired' in dt ? dt.isRequired : true,
				};
			});

			setActiveExpenseType(expenseTypeToBeUpdated);
			setExpenseTypesNew(expenseTypesCloned);
		}
	};

	const handleChangeSwitch = (e: ChangeEvent<HTMLInputElement>, docType: DocumentType) => {
		// clone new expense types
		const expenseTypesCloned = structuredClone(expenseTypesNew);

		// find active expense type
		const expenseTypeToBeUpdated = expenseTypesCloned.find(
			(exp) => exp.id === activeExpenseType?.id
		);

		if (expenseTypeToBeUpdated) {
			// find selected doc type
			const docTypeFound = (expenseTypeToBeUpdated?.documentTypes as DocumentType[]).find(
				(d) => d.id === docType.id
			);

			// update is required
			if (docTypeFound) {
				docTypeFound.isRequired = e.target.checked;
			}

			setActiveExpenseType(expenseTypeToBeUpdated);
			setExpenseTypesNew(expenseTypesCloned);
		}
	};

	// filter doc types by search query
	const docTypesFiltered = docTypes.filter(
		(docType) => !paramQ || docType.name.toLowerCase().includes(paramQ.toLowerCase())
	);

	return (
		<>
			<Container maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={4}>
						<Paper
							sx={(theme) => {
								return {
									position: 'relative',
									padding: theme.spacing(2),
									height: '100%',
									minHeight: {
										lg: 600,
									},
								};
							}}>
							<Grid
								container
								direction="column"
								spacing={2}
								sx={(theme) => {
									return {
										height: `calc(100% + ${theme.spacing(2)})`,
									};
								}}>
								<Grid item>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="h3">{t('expenseTypes')}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item lg={true}>
									<Box
										sx={{
											position: 'relative',
											height: {
												lg: '100%',
											},
										}}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<FormAddExpenseType
													onSuccess={() => {
														refetchExpenseTypes();
													}}
												/>
											</Grid>
											{expenseTypes.length ? (
												<Grid item xs={12}>
													<Box
														sx={(theme) => {
															return {
																overflowY: 'auto',
																maxHeight: {
																	lg: 700,
																},
															};
														}}>
														<Grid container spacing={2}>
															{expenseTypesNew.map((expenseType, i) => {
																const docTypeId = `expense-type-${expenseType.id}`;
																const isActive = expenseType.id === activeExpenseType?.id;

																let isEditable = false;

																if (
																	(isWfa && !expenseType.entity) ||
																	expenseType.entity === userEntity?.id
																) {
																	isEditable = true;
																}

																return (
																	<Grid item key={docTypeId} xs={12}>
																		<Box
																			onClick={() => {
																				setActiveExpenseType(expenseType);
																			}}
																			sx={(theme) => {
																				return {
																					borderRadius: 2,
																					padding: theme.spacing(2),
																					cursor: 'pointer',
																					...(isActive
																						? {
																								backgroundColor: theme.palette.brand.blue[600],
																								color: theme.palette.common.white,
																						  }
																						: {
																								backgroundColor: theme.palette.brand.grey[100],
																								'&:hover': {
																									backgroundColor: theme.palette.brand.grey[200],
																								},
																						  }),
																				};
																			}}>
																			<Grid
																				alignItems="center"
																				container
																				justifyContent="space-between"
																				spacing={2}>
																				<Grid item xs={true}>
																					<Grid alignItems="center" container spacing={1}>
																						<Grid item>
																							<Box
																								className="count"
																								sx={(theme) => {
																									return {
																										backgroundColor: theme.palette.common.white,
																										borderColor: theme.palette.divider,
																										borderStyle: 'solid',
																										borderWidth: 1,
																										borderRadius: '50%',
																										display: 'flex',
																										alignItems: 'center',
																										justifyContent: 'center',
																										width: 30,
																										height: 30,
																										...(isActive && {
																											backgroundColor:
																												theme.palette.brand.blue[800],
																											borderColor: theme.palette.brand.blue[800],
																										}),
																									};
																								}}>
																								<Typography fontWeight={600}>
																									{expenseType.documentTypes.length}
																								</Typography>
																							</Box>
																						</Grid>
																						<Grid item flex={1}>
																							<Typography fontWeight={600}>
																								{expenseType.name}
																							</Typography>
																						</Grid>
																					</Grid>
																				</Grid>
																				{isEditable && (
																					<Grid item xs="auto">
																						<BtnMenu
																							items={[
																								{
																									children: t('edit'),
																									icon: <IconModeEdit />,
																									onClick: (e) => {
																										e.stopPropagation();

																										searchParams.set('dialog', 'editExpenseType');
																										searchParams.set(
																											'dialogExpenseType',
																											String(expenseType.id)
																										);
																										setSearchParams(searchParams);
																									},
																								},
																								{
																									children: t('delete'),
																									disabled: true,
																									icon: <IconDelete color="error" />,
																									onClick: (e) => {
																										e.stopPropagation();

																										searchParams.set('dialog', 'deleteExpenseType');
																										searchParams.set(
																											'dialogExpenseType',
																											String(expenseType.id)
																										);
																										setSearchParams(searchParams);
																									},
																								},
																							]}
																						/>
																					</Grid>
																				)}
																			</Grid>
																		</Box>
																	</Grid>
																);
															})}
														</Grid>
													</Box>
												</Grid>
											) : (
												<Empty
													content={{ children: t('empty.expenseTypes.content') }}
													IconCircleProps={{ color: 'grey', type: 'payment' }}
													isLoading={isLoadingExpenseTypes}
													sx={(theme) => {
														return {
															padding: theme.spacing(2),
															pointerEvents: 'none',
															position: {
																lg: 'absolute',
															},
															width: '100%',
															height: '100%',
														};
													}}
													title={{ children: t('empty.expenseTypes.title') }}
												/>
											)}
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={8}>
						<Paper
							sx={(theme) => {
								return {
									position: 'relative',
									padding: theme.spacing(2),
									height: '100%',
								};
							}}>
							<Grid
								container
								direction="column"
								spacing={2}
								sx={(theme) => {
									return {
										height: `calc(100% + ${theme.spacing(2)})`,
									};
								}}>
								<Grid item>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="h3">{t('documentTypes')}</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid item lg={true}>
									<Box
										sx={{
											position: 'relative',
											height: {
												lg: '100%',
											},
										}}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<FormAddDocType
													onSuccess={() => {
														refetchDocumentTypes();
													}}
												/>
											</Grid>
											{docTypes.length ? (
												<>
													<Grid item xs={12}>
														<FormSearch />
													</Grid>
													<Grid item xs={12}>
														<Grid container spacing={2}>
															<Grid item xs={12}>
																<TableHeaders
																	hasBorder={true}
																	hasShadow={false}
																	headers={[
																		{
																			label: t('name'),
																			xs: 8,
																			lg: 9,
																			value: 'name',
																		},
																		{
																			label: t('mandatory'),
																			xs: 2,
																			value: 'required',
																		},
																	]}
																	size="sm"
																	spacing={2}
																/>
															</Grid>
															<Grid item xs={12}>
																<Box
																	sx={(theme) => {
																		return {
																			overflowY: 'auto',
																			maxHeight: {
																				lg: 610,
																			},
																		};
																	}}>
																	<Grid container spacing={2}>
																		{docTypesFiltered.map((docType, i) => {
																			const docTypeId = `doc-type-${docType.id}`;
																			const docTypeActive = activeDocTypes?.find(
																				(d) => d.id === docType.id
																			);
																			const isChecked = Boolean(docTypeActive);
																			const isRequired = Boolean(docTypeActive?.isRequired);

																			let isEditable = false;

																			if (
																				(isWfa && !docType.entity) ||
																				docType.entity === userEntity?.id
																			) {
																				isEditable = true;
																			}

																			return (
																				<Grid item key={docTypeId} xs={12}>
																					<Box
																						component="label"
																						htmlFor={docTypeId}
																						sx={(theme) => {
																							let sx: SxProps = {
																								backgroundColor: theme.palette.brand.grey[100],
																								borderRadius: 2,
																								padding: theme.spacing(1, 1.5),
																								display: 'flex',
																								cursor: 'pointer',
																							};

																							if (isChecked) {
																								sx = {
																									...sx,
																									backgroundColor: theme.palette.brand.grey[200],
																								};
																							}

																							return {
																								...sx,
																								'&:hover': {
																									backgroundColor: theme.palette.brand.grey[200],
																								},
																							};
																						}}>
																						<Grid
																							alignItems="center"
																							container
																							justifyContent="space-between"
																							spacing={2}>
																							<Grid item xs={8} lg={9}>
																								<Grid alignItems="center" container spacing={1}>
																									<Grid item>
																										<Checkbox
																											checked={isChecked}
																											disableRipple={true}
																											id={docTypeId}
																											onChange={(e) =>
																												handleChangeDocType(e, docType)
																											}
																											sx={{ padding: 0 }}
																										/>
																									</Grid>
																									<Grid item flex={1}>
																										{docType.name}
																									</Grid>
																								</Grid>
																							</Grid>
																							<Grid hidden={!isChecked} item xs={true}>
																								<Switch
																									checked={isRequired}
																									onChange={(e) => handleChangeSwitch(e, docType)}
																									size="small"
																									sx={(theme) => {
																										return {
																											marginLeft: '-14px',
																										};
																									}}
																								/>
																							</Grid>
																							{isEditable && (
																								<Grid item xs="auto">
																									<BtnMenu
																										items={[
																											{
																												children: t('edit'),
																												icon: <IconModeEdit />,
																												onClick: (e) => {
																													e.stopPropagation();

																													searchParams.set(
																														'dialog',
																														'editDocumentType'
																													);
																													searchParams.set(
																														'dialogDocumentType',
																														String(docType.id)
																													);
																													setSearchParams(searchParams);
																												},
																											},
																											{
																												children: t('delete'),
																												disabled: true,
																												icon: <IconDelete color="error" />,
																												onClick: (e) => {
																													e.stopPropagation();

																													searchParams.set(
																														'dialog',
																														'deleteDocumentType'
																													);
																													searchParams.set(
																														'dialogDocumentType',
																														String(docType.id)
																													);
																													setSearchParams(searchParams);
																												},
																											},
																										]}
																									/>
																								</Grid>
																							)}
																						</Grid>
																					</Box>
																				</Grid>
																			);
																		})}
																	</Grid>
																</Box>
															</Grid>
														</Grid>
													</Grid>
												</>
											) : (
												<Empty
													content={{ children: t('empty.documentTypes.content') }}
													isLoading={isLoadingDocumentTypes}
													IconCircleProps={{
														color: 'grey',
														type: 'document',
													}}
													sx={(theme) => {
														return {
															padding: theme.spacing(2),
															pointerEvents: 'none',
															position: {
																lg: 'absolute',
															},
															width: '100%',
															height: '100%',
														};
													}}
													title={{ children: t('empty.documentTypes.title') }}
												/>
											)}
										</Grid>
									</Box>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			<DialogDeleteDocumentType
				isOpen={paramDialog === 'deleteDocumentType'}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogDocumentType');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					refetchDocumentTypes();
				}}
			/>
			<DialogDeleteExpenseType
				isOpen={paramDialog === 'deleteExpenseType'}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogExpenseType');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					refetchExpenseTypes();
				}}
			/>
			<DialogEditDocumentType
				isOpen={paramDialog === 'editDocumentType'}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogDocumentType');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					refetchDocumentTypes();
				}}
			/>
			<DialogEditExpenseType
				isOpen={paramDialog === 'editExpenseType'}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogExpenseType');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					refetchExpenseTypes();
				}}
			/>
			<DialogUpdateExpenseTypes
				expenseTypes={expenseTypesNew}
				isOpen={paramDialog === 'updateExpenseTypes'}
				onClose={() => {
					searchParams.delete('dialog');
					setSearchParams(searchParams);
				}}
			/>
		</>
	);
};
