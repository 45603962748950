// react
import { ReactNode, useContext, useEffect, useState } from 'react';

// types
import { EntityType, Option, Loan } from '../../../../../@types';
import { entityTypesReadOnly } from '../../../../../@types/unions';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// orgchart
import { Tree, TreeNode } from 'react-organizational-chart';

// context
import { ContextProject } from '../../Project.context';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// mui
import { Box, Container, Grid, Paper, SelectChangeEvent, Typography } from '@mui/material';

// theme
import { theme } from '../../../../../theme';

// fields
import { Select } from '../../../../fields/Select/Select';

// local
import { Node } from './Node/Node';

// img
import { LogoWorldBankMark } from '../../../../img/LogoWorldBankMark';
import { IconCircle, IconCircleProps } from '../../../../ui/IconCircle/IconCircle';

// props
export interface EntityNode {
	children?: EntityNode[];
	icon?: ReactNode;
	id?: number;
	name: string;
	type?: EntityType;
}

export interface LegendItem {
	color?: IconCircleProps['color'];
	label: string;
	type: IconCircleProps['type'];
	value: EntityType;
}

export interface ExtractBorrowersParams {
	nodes: EntityNode[] | undefined;
	types?: EntityType[];
}

export const Hierarchy = () => {
	// hooks
	const { t } = useTranslation();

	// state
	const [hierarchy, setHierarchy] = useState<EntityNode[]>([]);
	const [loan, setLoan] = useState('');
	const [loans, setLoans] = useState<Loan[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramLoan = searchParams.get('loan');

	// context
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const hierarchyFiltered = structuredClone(hierarchy);

	// fetch loans
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch hierarchy
	const { fetchRequest: fetchHierarchy } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/hierarchies/${loan}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setHierarchy(res.data);
			}
		},
	});

	const extractEntities = ({
		nodes = [],
		types = [...entityTypesReadOnly],
	}: ExtractBorrowersParams) => {
		const extractedEntities: EntityNode[] = [];

		for (let i = 0; i < nodes.length; i++) {
			const node = nodes[i];

			if (types.some((type) => type === node.type)) {
				const spliced = nodes.splice(i, 1);
				extractedEntities.push(spliced[0]);
				i--;
			}

			if (node.children) {
				extractEntities({ nodes: node.children, types });
			}
		}
		return extractedEntities;
	};

	// extract entities
	const extractedEntities: EntityNode[] = extractEntities({
		nodes: hierarchyFiltered,
	});

	const optionsLoans: Option[] = loans.map((loan: Loan) => ({
		label: loan.code,
		value: String(loan.id),
	}));

	const legend: LegendItem[] = [
		{
			label: t('piu'),
			type: 'entity',
			value: 'PIU',
		},
		{
			label: t('subPiu'),
			type: 'subPiu',
			value: 'SUB_PIU',
		},
		{
			color: 'orange',
			label: t('intermediary'),
			type: 'entity',
			value: 'INTERMEDIARY',
		},
		{
			color: 'green',
			label: t('supplier'),
			type: 'supplier',
			value: 'SUPPLIER',
		},
		{
			color: 'blue',
			label: t('beneficiary'),
			type: 'organization',
			value: 'BENEFICIARY',
		},
		{
			label: t('borrower'),
			type: 'borrower',
			value: 'BORROWER',
		},
		{
			label: t('auditor'),
			type: 'auditor',
			value: 'AUDITOR',
		},
		{
			label: t('donor'),
			type: 'donor',
			value: 'DONOR',
		},
		{
			label: t('taxAuthority'),
			type: 'tax',
			value: 'TAX_AUTHORITY',
		},
	];

	const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
		const { value } = event.target;

		// set search params
		searchParams.set('loan', String(value));
		setSearchParams(searchParams);

		// set loan
		setLoan(String(value));
	};

	const renderTreeNode = (entity: EntityNode) => {
		const childrenFiltered: EntityNode[] = [];
		let numOfBeneficiaries = 0;
		let numOfSuppliers = 0;

		if (entity.children) {
			for (let i = 0; i < entity.children.length; i++) {
				const child = entity.children[i];

				if (child.type === 'BENEFICIARY') {
					numOfBeneficiaries++;
				} else if (child.type === 'SUPPLIER') {
					numOfSuppliers++;
				} else {
					childrenFiltered.push(child);
				}
			}
		}

		const nodes: ReactNode[] = [];

		if (numOfBeneficiaries > 0) {
			nodes.push(
				<TreeNode
					key={`entity-id-${entity.id}-beneficiaries`}
					label={
						<Node legend={legend} summary={{ count: numOfBeneficiaries, type: 'BENEFICIARY' }} />
					}
				/>
			);
		}

		if (numOfSuppliers > 0) {
			nodes.push(
				<TreeNode
					key={`entity-id-${entity.id}-suppliers`}
					label={<Node legend={legend} summary={{ count: numOfSuppliers, type: 'SUPPLIER' }} />}
				/>
			);
		}

		return (
			<TreeNode key={`entity-id-${entity.id}`} label={<Node entity={entity} legend={legend} />}>
				{nodes}
				{childrenFiltered.map(renderTreeNode)}
			</TreeNode>
		);
	};

	// set default loan
	useEffect(() => {
		if (paramLoan) {
			setLoan(paramLoan);
		} else if (loans.length) {
			setLoan(String(loans[0].id));
		}
	}, [loans, paramLoan]);

	// fetch hierarchy when loan changes
	useEffect(() => {
		if (loan) {
			fetchHierarchy();
		}
	}, [fetchHierarchy, loan]);

	return (
		<Grid container rowSpacing={2}>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Grid alignItems="stretch" container justifyContent="space-between" spacing={2}>
						<Grid item xs={12} lg={12}>
							<Paper
								sx={(theme) => {
									return {
										padding: theme.spacing(2),
										display: 'flex',
										alignItems: 'center',
										height: '100%',
									};
								}}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h3">{t('legend')}</Typography>
									</Grid>
									{legend.map((item, i) => {
										return (
											<Grid item key={`legend-item-${i}`} xs={6} md={4} lg="auto">
												<Grid alignItems="center" container spacing={1}>
													<Grid item>
														<IconCircle color={item.color} size="sm" type={item.type} />
													</Grid>
													<Grid flex={1} item>
														<Typography fontWeight={500}>{item.label}</Typography>
													</Grid>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Grid container justifyContent="flex-end" spacing={2}>
								<Grid item xs={12} md={4}>
									<Select
										onChange={handleSelectChange}
										options={optionsLoans}
										hasNoneOption={false}
										value={loan}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>

			<Grid item xs={12}>
				<Box
					sx={(theme) => {
						return {
							borderBottomColor: theme.palette.divider,
							borderBottomStyle: 'solid',
							borderBottomWidth: 1,
							padding: {
								xs: theme.spacing(2, 0, 4, 0),
								md: theme.spacing(4, 0, 6, 0),
							},
						};
					}}>
					<Container maxWidth={false}>
						<Grid container justifyContent="center" spacing={2}>
							<Grid item>
								<Node
									entity={{
										icon: <LogoWorldBankMark hasGradient={true} style={{ width: 32 }} />,
										name: 'World Bank Group',
									}}
								/>
							</Grid>
							{extractedEntities.map((entity) => {
								return (
									<Grid item>
										<Node
											entity={{
												name: entity.name,
												type: entity.type,
											}}
											legend={legend}
										/>
									</Grid>
								);
							})}
						</Grid>
					</Container>
				</Box>
			</Grid>

			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Paper
						elevation={0}
						sx={(theme) => {
							return {
								backgroundColor: 'transparent',
								padding: {
									xs: theme.spacing(2, 0),
									md: theme.spacing(4, 0),
								},
								width: '100%',
								overflowX: 'auto',
							};
						}}>
						<Grid container>
							<Grid item xs={12}>
								<Box
									sx={(theme) => {
										// remove line stemming from WBG node
										return {
											'& > ul > li > ul': {
												paddingTop: theme.spacing(0),
											},
											'& > ul > li > ul::before': {
												display: 'none',
											},
										};
									}}>
									<Tree
										label={null}
										lineColor={theme.palette.brand.grey[400]}
										lineWidth="1px"
										nodePadding="5px">
										{hierarchyFiltered.map((entity) => {
											return renderTreeNode(entity);
										})}
									</Tree>
								</Box>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};
