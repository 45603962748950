// types
import { Loan } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../../../ui/IconCircle/IconCircle';
import { Table } from '../../../../ui/Table/Table';

// charts
import { ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';

// props
export interface SourcesProps {
	dataSankey: ChartSankeyProps | null;
}

export const Sources = ({ dataSankey }: SourcesProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const payments = [];
	const sourcesCount = new Map<string, number>();

	// calculate sources count
	if (dataSankey?.data?.links?.length) {
		for (let i = 0; i < dataSankey.data.links.length; i++) {
			const link = dataSankey.data.links[i];

			if (link?.transactions?.length) {
				for (let j = 0; j < link.transactions.length; j++) {
					const transaction = link.transactions[j];

					if (transaction?.payments?.length) {
						for (let k = 0; k < transaction?.payments?.length; k++) {
							const payment = transaction?.payments[k];

							if (payment?.loan) {
								payments.push(payment);
								sourcesCount.set(
									String(payment?.loan?.code),
									(sourcesCount.get(String(payment?.loan?.code)) || 0) + 1
								);
							}
						}
					}
				}
			}
		}
	}

	const data = Array.from(sourcesCount, ([code, total]) => ({
		loan: { code },
		total,
	})).sort((a, b) => b.total - a.total);

	const headers = [
		{
			label: t('source'),
			render: (loan: Loan) => {
				const IconCircleProps: IconCircleProps = {
					size: 'sm',
					type: 'loan',
				};

				return (
					<Grid alignItems="center" container spacing={1}>
						<Grid item>
							<IconCircle {...IconCircleProps} />
						</Grid>
						<Grid item>
							<Typography fontWeight={500}>{loan.code}</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'loan',
		},
		{
			label: t('total'),
			render: (total: number) => {
				return (
					<Grid container spacing={1}>
						<Grid item>
							<Typography>{total}</Typography>
						</Grid>
						<Grid item>
							<Typography color="brand.grey.500">
								({Math.round((total / payments.length) * 100)}%)
							</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'total',
		},
	];

	return (
		<Paper sx={{ height: '100%' }}>
			<Grid container>
				<Grid item xs={12}>
					<Box sx={{ padding: 2 }}>
						<Typography variant="h3">{t('financingSources')}</Typography>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Table color="grey" data={data} headers={headers} />
				</Grid>
			</Grid>
		</Paper>
	);
};
