// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// types
import { Account, ActionButton } from '../../../../@types';
import { transactionStatuses } from '../../../../@types/unions';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import { ContextTransfers, ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// icons
import { SwapHorizOutlined as IconTransfer } from '@mui/icons-material';

// ui
import { BtnExport } from '../../../ui/BtnExport/BtnExport';
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterChangeEvent, FilterProps } from '../../../ui/Filters/Filters';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardTransfer } from '../../../cards/CardTransfer/CardTransfer';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// utils
import { translateStatus } from '../../../../utils/translations';

export const TableTransfers = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);
	const { transfers, isLoadingTransfers, paginationTransfers } = useContext(ContextTransfers);

	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramExpenseType = searchParams.get('expenseType');
	const paramFromAccount = searchParams.get('fromAccount');
	const paramQ = searchParams.get('q');
	const paramStatus = searchParams.get('status');
	const paramToAccount = searchParams.get('toAccount');

	// defaults
	const defaultFiltersValues = {
		fromAccount: 'all',
		status: 'all',
		toAccount: 'all',
	};

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [filtersValues, setFiltersValues] = useState({
		fromAccount: paramFromAccount || defaultFiltersValues.fromAccount,
		status: paramStatus || defaultFiltersValues.status,
		toAccount: paramToAccount || defaultFiltersValues.toAccount,
	});

	// fetch accounts
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// vars
	const headers = [
		{
			isSortable: true,
			label: t('date'),
			lg: 2,
			value: 'date',
		},
		{
			label: t('fromAccount'),
			lg: 2,
			value: 'fromAccount',
		},
		{
			label: t('amountSent'),
			lg: 2,
			value: 'amountSent',
		},
		{
			label: t('status'),
			lg: 1.5,
			value: 'status',
		},
		{
			label: t('toAccount'),
			lg: 2,
			value: 'toAccount',
		},
		{
			label: t('amountReceived'),
			lg: 2,
			value: 'amountReceived',
		},
	];

	const idFormFilters = 'form-transfers-filters';

	// options
	const optionsStatus = transactionStatuses.map((status) => {
		return { label: translateStatus(status, t), value: status };
	});

	const optionsAccounts = accounts.map((account) => {
		return { label: account.name, value: String(account.id) };
	});

	// handlers
	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	// filters
	const filters: FilterProps[] = [
		{
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-from-account`,
			label: t('fromAccount'),
			name: 'fromAccount',
			onChange: handleChangeFilterValue,
			options: optionsAccounts,
			type: 'SELECT',
			value: filtersValues.fromAccount,
		},
		{
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-to-account`,
			label: t('toAccount'),
			name: 'toAccount',
			onChange: handleChangeFilterValue,
			options: optionsAccounts,
			type: 'SELECT',
			value: filtersValues.toAccount,
		},
		{
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-status`,
			label: t('status'),
			name: 'status',
			onChange: handleChangeFilterValue,
			options: optionsStatus,
			type: 'SELECT',
			value: filtersValues.status,
		},
	];

	// memos
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('recordFundTransfer'),
					onClick: () => {
						searchParams.set('dialog', 'recordFundTransfer');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.transfers.content') },
			IconCircleProps: { icon: (<IconTransfer />) as ReactNode },
			title: { children: t('empty.transfers.title') },
		};

		if (paramQ || paramExpenseType) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, paramExpenseType, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoadingTransfers || !transfers.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingTransfers} />;
		}

		return (
			<Grid container spacing={2}>
				{transfers.map((transfer, i) => {
					return (
						<Grid item key={`transfer-${i}`} xs={12}>
							<CardTransfer transfer={transfer} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, transfers, isLoadingTransfers]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={true}>
						<FormSearch />
					</Grid>
					<Grid item xs={6} md="auto">
						<BtnExport resource="TRANSFER" />
					</Grid>
					<Grid item xs={6} md="auto">
						<BtnFilters
							filters={filters}
							onReset={() => {
								setFiltersValues(defaultFiltersValues);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={transfers.length} pagination={paginationTransfers} />
			</Grid>
		</Grid>
	);
};
