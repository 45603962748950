// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { Benefit, Feedback } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, AlertTitle, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';
import { Loading } from '../../ui/Loading/Loading';

// props
export interface DialogDeleteBenefitProps {
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	onSuccess?: () => void;
}

export const DialogDeleteBenefit = ({ isOpen, onClose, onSuccess }: DialogDeleteBenefitProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramBenefit = searchParams.get('dialogBenefit');

	// context
	const { setBanner } = useContext(ContextBanner);

	// state
	const [benefit, setBenefit] = useState<Benefit | null>(null);
	const [feedback, setFeedback] = useState<Feedback | null>(null);

	// fetch benefit
	const { isLoading: isLoadingBenefit } = useFetch({
		isEnabled: Boolean(paramBenefit),
		url: `${process.env.REACT_APP_API_URL}/benefits/${paramBenefit}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBenefit(res.data[0]);
			}
		},
	});

	// delete benefit
	const { fetchRequest: deleteBenefit, isLoading: isLoadingDeleteBenefit } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/benefits/${paramBenefit}`,
		options: {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onError: (error) => {
			const feedback: Feedback = {
				children: t('error.default'),
			};

			if (error.id === 'BENEFIT_TRANSACTIONS_EXIST') {
				feedback.children = t('error.benefitTransactionsExist');
				feedback.title = {
					children: t('benefitCannotBeDeleted'),
				};
			}

			if (error.id === 'BENEFIT_UNAUTHORIZED_DELETE') {
				feedback.children = t('error.unauthorized');
			}

			setFeedback(feedback);
		},
		onSuccess: () => {
			// set banner
			setBanner({
				hasClose: true,
				message: t('alert.benefitDeleted', { name: benefit?.name }),
				severity: 'success',
			});

			if (onSuccess) {
				onSuccess();
			}

			// close dialog
			if (onClose) {
				onClose();
			}
		},
	});

	// handlers
	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear feedback
		setFeedback(null);

		// delete benefit
		deleteBenefit();
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear feedback
		setFeedback(null);

		if (onClose) {
			onClose(e);
		}
	};

	const renderContent = () => {
		if (isLoadingBenefit) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.blue.600" component="span" fontWeight={600} />,
						}}
						i18nKey="dialogDeleteBenefit.content"
						values={{ name: benefit?.name }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{t('alert.deleteBenefit')}</Alert>
				</Grid>
				{feedback && (
					<Grid item xs={12}>
						<Alert severity="error">
							{feedback.title && <AlertTitle {...feedback.title} />}
							{feedback.children}
						</Alert>
					</Grid>
				)}
			</>
		);
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: t('deleteBenefit'),
					loading: isLoadingDeleteBenefit,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'benefit',
			}}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			subtitle={{
				children: isLoadingBenefit ? '' : benefit?.name,
			}}
			title={{
				children: t('deleteBenefit'),
			}}>
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</DialogActions>
	);
};
