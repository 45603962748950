// react
import { FormEvent, SyntheticEvent, useContext, useEffect, useState } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// types
import {
	Entity,
	ExpenseType,
	Loan,
	Option,
	Pagination,
	Transaction,
	WithdrawalApplication,
} from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// pages
import { SoeFilters } from './Filters/Filters';

// mui
import { Button, Container, Grid, Paper, SelectChangeEvent, Typography } from '@mui/material';
import { theme } from '../../../../../theme';

// forms
import { FormSearch } from '../../../../forms/FormSearch/FormSearch';

// dialogs
import { DialogExportSoeReport } from '../../../../dialogs/DialogExportSoeReport/DialogExportSoeReport';

// tables
import { TableBasicSoe } from '../../../../tables/basic/TableBasicSoe/TableBasicSoe';

// ui
import { FilterProps } from '../../../../ui/Filters/Filters';
import { TableFooter } from '../../../../ui/TableFooter/TableFooter';

// context
import { ContextProject } from '../../Project.context';
import { ContextUser } from '../../../../../App.context';

// utils
import { defaultPagination } from '../../../../../utils/defaults';
import { formatDate } from '../../../../../utils/dates';

// props
export type ChangeFilterEvent = SyntheticEvent<Element, Event> | SelectChangeEvent<unknown>;

export const Soe = () => {
	// hooks
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	// defaults
	const dateToday = new Date();
	const todayYear = dateToday.getFullYear();

	const filtersValuesDefaults: Record<string, string | string[]> = {
		amountMin: '',
		amountMax: '',
		currency: '',
		expenseType: [],
		dateEnd: formatDate({ date: dateToday, format: 'YYYY-MM-DD' }),
		dateStart: formatDate({ date: new Date(`${todayYear}-01-01`), format: 'YYYY-MM-DD' }),
		loan: '',
		type: [],
		withdrawalApplication: '',
	};

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
	const [filtersValues, setFiltersValues] = useState(filtersValuesDefaults);
	const [isFiltered, setIsFiltered] = useState(false);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [paginationTransactions, setPaginationTransactions] = useState<Pagination>({
		...defaultPagination,
		page: 1,
	});
	const [transactions, setTransactions] = useState<Transaction[] | []>([]);
	const [withdrawalApplications, setWithdrawalApplications] = useState<WithdrawalApplication[]>([]);

	// params
	const paramDialog = searchParams.get('dialog');
	const paramId = searchParams.get('id');
	const paramLimit = Number(searchParams.get('limit') || 25);
	const paramPage = Number(searchParams.get('page') || 1);

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch expense types
	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	useFetch({
		isEnabled: Boolean(project?.id && user),
		url: `${process.env.REACT_APP_API_URL}/expense-types?project=${project?.id}&entity=${paramEntity}&populate=documentTypes`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setExpenseTypes(res.data);
			}
		},
	});

	// fetch withdrawal applications
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/withdrawal-applications?project=${project?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setWithdrawalApplications(res.data);
			}
		},
	});

	// fetch transactions
	let urlFetchTransactions = `${process.env.REACT_APP_API_URL}/transactions?populate=contract,expenseType,fromAccount,fromEntity,toAccount,toEntity,withdrawalApplication&page=${paramPage}&limit=${paramLimit}`;

	// define filters keys
	const filtersKeys = Object.keys(filtersValues);

	// add each filter value to url
	for (let i = 0; i < filtersKeys.length; i++) {
		const key = filtersKeys[i];

		if (Array.isArray(filtersValues[key])) {
			if (filtersValues[key].length) {
				urlFetchTransactions += `&${key}=${(filtersValues[key] as string[]).join(',')}`;
			}
		} else if (filtersValues[key]) {
			urlFetchTransactions += `&${key}=${filtersValues[key]}`;
		}
	}

	// add id param
	if (paramId) {
		urlFetchTransactions += `&id=${paramId}`;
	}

	const { fetchRequest: fetchTransactions } = useFetch({
		isLazy: true,
		url: urlFetchTransactions,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTransactions(res.data);
			}

			if (res.pagination) {
				setPaginationTransactions(res.pagination);
			}
		},
	});

	// options
	const optionsLoans: Option[] = loans
		.map((loan) => {
			return {
				disabled: loan.status === 'DISABLED',
				label: loan.code,
				value: String(loan.id),
			};
		})
		.sort((a: Option, b: Option) => {
			return a.label > b.label ? 1 : -1;
		});

	const optionsExpenseType = expenseTypes.map((expenseType) => {
		return {
			label: expenseType.name,
			value: String(expenseType.id),
		};
	});

	const optionsTransactionTypes = [
		{
			label: t('cashBenefit'),
			value: 'CASH_BENEFIT',
		},
		{
			label: t('operatingExpense'),
			value: 'OPEX',
		},
		{
			label: t('payment'),
			value: 'PAYMENT',
		},
		{
			label: t('replenishment'),
			value: 'REPLENISHMENT',
		},
		{
			label: t('transfer'),
			value: 'TRANSFER',
		},
	];

	const optionsWa = withdrawalApplications.map((wa) => {
		return {
			label: wa.idExternal,
			value: wa.id,
		};
	});

	// vars
	const idFormFilters = 'form-soe-filters';
	const filters: FilterProps[] = [
		{
			defaultValue: loans?.length ? String(loans[0].id) : '',
			id: `${idFormFilters}-loan`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			label: t('financingSource'),
			name: 'loan',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'loan'),
			options: optionsLoans,
			required: true,
			type: 'SELECT',
			value: filtersValues.loan,
		},
		{
			id: `${idFormFilters}-type`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			label: t('transactionType'),
			multiple: true,
			name: 'type',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'type'),
			options: optionsTransactionTypes,
			type: 'SELECT',
			value: filtersValues.type,
		},

		{
			id: `${idFormFilters}-expense-type`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			label: t('expenseType'),
			multiple: true,
			name: 'expenseType',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'expenseType'),
			options: optionsExpenseType,
			type: 'SELECT',
			value: filtersValues.expenseType,
		},
		{
			label: t('startDate'),
			id: `${idFormFilters}-date-start`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			name: 'dateStart',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'dateStart'),
			InputProps: {
				inputProps: {
					min: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
						.toISOString()
						.split('T')[0],
					max: new Date().toISOString().split('T')[0],
				},
				type: 'date',
			},
			required: true,
			type: 'INPUT',
			value: filtersValues.dateStart,
		},
		{
			label: t('endDate'),
			id: `${idFormFilters}-date-end`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			name: 'dateEnd',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'dateEnd'),
			InputProps: {
				inputProps: {
					min: new Date(new Date().setFullYear(new Date().getFullYear() - 1))
						.toISOString()
						.split('T')[0],
					max: new Date().toISOString().split('T')[0],
				},
				type: 'date',
			},
			required: true,
			type: 'INPUT',
			value: filtersValues.dateEnd,
		},
		{
			label: t('minAmount'),
			id: `${idFormFilters}-amount-min`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			name: 'amountMin',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'amountMin'),
			InputProps: {
				inputProps: { min: 0 },
				type: 'number',
			},
			type: 'INPUT',
			value: filtersValues.amountMin,
		},
		{
			label: t('maxAmount'),
			id: `${idFormFilters}-amount-max`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			name: 'amountMax',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'amountMax'),
			InputProps: {
				inputProps: { min: 0 },
				type: 'number',
			},
			type: 'INPUT',
			value: filtersValues.amountMax,
		},
		{
			label: t('currency'),
			hasNoneOption: false,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			id: `${idFormFilters}-currency`,
			name: 'currency',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'currency'),
			type: 'CURRENCY',
			value: filtersValues.currency,
		},
		{
			hasNoneOption: true,
			id: `${idFormFilters}-withdrawal-application`,
			GridProps: { lg: 4, xl: 3, xs: 12 },
			label: t('withdrawalApplication'),
			name: 'withdrawalApplication',
			onChange: (e: ChangeFilterEvent) => handleChangeFilter(e, 'withdrawalApplication'),
			options: optionsWa,
			type: 'SELECT',
			value: filtersValues.withdrawalApplication,
		},
	];

	// handlers
	const handleSubmitFilters = async (e: FormEvent) => {
		e.preventDefault();
		if (isFiltered) {
			fetchTransactions();
		} else {
			setIsFiltered(true);
		}
	};

	const handleExportReport = async () => {
		searchParams.set('dialog', 'exportSoeReport');
		setSearchParams(searchParams);
	};

	const handleChangeFilter = (e: ChangeFilterEvent, prop: string) => {
		const { value } = e.target as HTMLInputElement;
		setFiltersValues((prevFilters) => {
			return {
				...prevFilters,
				[prop]: value,
			};
		});
	};

	// fetch transactions when limit or page changes
	useEffect(() => {
		if (isFiltered) {
			fetchTransactions();
		}
	}, [fetchTransactions, isFiltered, paramLimit, paramPage]);

	return (
		<Grid>
			<Container maxWidth={false}>
				<Grid container spacing={theme.spacing(2)}>
					<Grid item xs={12}>
						<Typography variant="h2">{t('soe')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<SoeFilters
							filters={filters}
							onReset={() => {
								setFiltersValues(filtersValuesDefaults);
								setIsFiltered(false);
								setTransactions([]);
								setSearchParams('');
							}}
							onSubmit={handleSubmitFilters}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item container justifyContent="space-between" spacing={2}>
								<Grid item xs={true}>
									<FormSearch />
								</Grid>
								<Grid item xs="auto">
									<Button
										disabled={transactions.length === 0 || !isFiltered}
										variant="contained"
										onClick={handleExportReport}>
										{t('exportReport')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Paper>
							<TableBasicSoe isLoading={false} transactions={transactions} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<TableFooter
							hasSelectRows={true}
							numShowing={transactions.length}
							pagination={paginationTransactions}
						/>
					</Grid>
				</Grid>
				<DialogExportSoeReport
					expenseTypes={expenseTypes}
					filtersValues={filtersValues}
					isOpen={paramDialog === 'exportSoeReport'}
					loans={loans}
					onClose={() => {
						searchParams.delete('dialog');
						searchParams.delete('exportSoeReport');
						setSearchParams(searchParams);
					}}
				/>
			</Container>
		</Grid>
	);
};
