// i18n
import { useTranslation } from 'react-i18next';

// recharts
import { TooltipProps } from 'recharts';
import { ValueType, NameType } from 'recharts/types/component/DefaultTooltipContent';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// utils
import { translateType } from '../../../utils/translations';

export const ChartTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
	// hooks
	const { t } = useTranslation();

	// vars
	let maxWidth = 300;

	if (active && payload?.length) {
		const isSankeyLink = payload[0]?.payload?.payload?.source;
		const isSankeyNode = payload[0]?.payload?.payload?.wallet;

		let content;

		if (isSankeyLink) {
			const link = payload[0].payload.payload;
			const value = Number(payload[0].value);

			let type: IconCircleProps['type'] = 'payment';

			if (link.transactions[0].type === 'TRANSFER') {
				type = 'transfer';
			}

			maxWidth = 300;

			content = (
				<>
					<Grid item xs={12}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid container columnSpacing={2}>
								<Grid item>
									<IconCircle size="sm" type={type} />
								</Grid>
								<Grid item xs={true}>
									<Amount
										currency={link.transactions[0].currency}
										TypographyProps={{ fontWeight: 600 }}
										value={value}
									/>
									<Typography color="brand.grey.500" variant="caption">
										{link.transactions.length}{' '}
										{link.transactions.length === 1 ? t('transaction') : t('transactions')}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(0, 2, 2, 2),
								};
							}}>
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Typography fontWeight={600}>{t('sending')}</Typography>
									<Typography color="brand.grey.500" variant="caption">
										{link.source.name}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Typography fontWeight={600}>{t('receiving')}</Typography>
									<Typography color="brand.grey.500" variant="caption">
										{link.target.name}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</>
			);
		}

		if (isSankeyNode) {
			const entity = payload[0]?.payload?.payload;
			let type: IconCircleProps['type'] = 'entity';

			if (entity.type === 'SUPPLIER') {
				type = 'supplier';
			}

			if (entity.type === 'BENEFICIARY') {
				type = 'individual';

				if (entity.subtype === 'ORGANIZATION') {
					type = 'organization';
				}
			}

			content = (
				<>
					<Grid item xs={12}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid container spacing={2}>
								<Grid item>
									<IconCircle size="sm" type={type} />
								</Grid>
								<Grid item xs={true}>
									<Typography fontWeight={600}>{entity.name}</Typography>
									<Typography color="brand.grey.500" variant="caption">
										{translateType(entity.type, t)}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</>
			);
		}

		return (
			<Paper
				sx={(theme) => {
					return {
						//padding: theme.spacing(2),
						maxWidth,
					};
				}}>
				<Grid container>{content}</Grid>
			</Paper>
		);
	}

	return null;
};
