// react
import { useContext, useMemo, useState } from 'react';

// types
import { Loan, Pagination } from '../../../../@types';

// i18next
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../../pages/Project/Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardLoan } from '../../../cards/CardLoan/CardLoan';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterChangeEvent, FilterProps } from '../../../ui/Filters/Filters';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { PaymentsOutlined as IconPayments } from '@mui/icons-material';

// options
import { getOptionsFinanciers } from '../../../../utils/options';

// props
export interface TableFinancingSourcesProps {
	hasFilters?: boolean;
	hasSearch?: boolean;
	isLoading?: boolean;
	loans: Loan[];
	pagination?: Pagination;
}

export const TableFinancingSources = ({
	hasFilters = true,
	hasSearch = true,
	isLoading,
	loans,
	pagination,
}: TableFinancingSourcesProps) => {
	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramFinancier = searchParams.get('financier');

	// options
	const optionsFinanciers = getOptionsFinanciers();

	// defaults
	const defaultFiltersValues = {
		financier: optionsFinanciers.map((option) => option.value),
	};

	// state
	const [filtersValues, setFiltersValues] = useState({
		financier: paramFinancier ? paramFinancier.split(',') : defaultFiltersValues.financier,
	});

	// vars
	const idFormFilters = `form-financing-sources-filters`;

	const headers = [
		{
			label: t('id'),
			lg: 2,
			value: 'id',
		},
		{
			label: t('name'),
			lg: 2,
			value: 'name',
		},
		{
			label: t('closing'),
			lg: 1.5,
			value: 'dateClosing',
		},
		{
			label: t('amount'),
			lg: 1.5,
			value: 'amount',
		},
		{
			label: t('undisbursed'),
			lg: 1.5,
			value: 'undisbursed',
		},
		{
			label: t('traceability'),
			lg: 1,
			value: 'traceability',
		},
		{
			label: t('status'),
			lg: 1.5,
			value: 'status',
		},
	];

	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	// filters
	const filters: FilterProps[] = [
		{
			hasAllOption: true,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${idFormFilters}-financier`,
			label: t('financier'),
			multiple: true,
			name: 'financier',
			onChange: handleChangeFilterValue,
			options: optionsFinanciers,
			type: 'SELECT',
			value: filtersValues.financier,
		},
	];

	const content = useMemo(() => {
		if (isLoading || !project || !loans.length) {
			const actions: EmptyProps['actions'] = [];

			if (user?.permissions?.loan.create) {
				actions.push({
					children: t('addFinancingSource'),
					onClick: () => {
						searchParams.set('dialog', 'addFinancingSource');
						setSearchParams(searchParams);
					},
					variant: 'contained',
				});
			}

			return (
				<CardEmpty
					empty={{
						actions,
						content: { children: t('empty.loans.content') },
						IconCircleProps: { icon: <IconPayments /> },
						title: { children: t('empty.loans.title') },
					}}
					isLoading={isLoading}
				/>
			);
		}

		return (
			<Grid container spacing={2}>
				{loans.map((loan, i) => {
					return (
						<Grid item key={`loan-${i}`} xs={12}>
							<CardLoan loan={loan} project={project} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [isLoading, loans, project, searchParams, setSearchParams, user]);

	return (
		<Grid container spacing={2}>
			{(hasSearch || hasFilters) && (
				<Grid item xs={12}>
					<Grid container justifyContent={hasSearch ? 'flex-start' : 'flex-end'} spacing={2}>
						{hasSearch && (
							<Grid item xs={12} md={true}>
								<FormSearch />
							</Grid>
						)}
						{hasFilters && (
							<Grid item xs={12} md="auto">
								<BtnFilters
									filters={filters}
									onReset={() => {
										setFiltersValues(defaultFiltersValues);
									}}
								/>
							</Grid>
						)}
					</Grid>
				</Grid>
			)}
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			{pagination && (
				<Grid item xs={12}>
					<TableFooter numShowing={loans.length} pagination={pagination} />
				</Grid>
			)}
		</Grid>
	);
};
