// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// types
import { ActionButton, Project } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import { ContextEntities, ContextProject } from '../../../pages/Project/Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterChangeEvent } from '../../../ui/Filters/Filters';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardEntity } from '../../../cards/CardEntity/CardEntity';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { AccountBalanceOutlined as IconAccountBalance } from '@mui/icons-material';

// utils
import { getOptionsEntityTypes } from '../../../../utils/options';

// props
export interface TableEntitiesProps {
	project: Project;
}

export const TableEntities = ({ project }: TableEntitiesProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramQ = searchParams.get('q');
	const paramType = searchParams.get('type');

	// defaults
	const defaultFiltersValues = {
		type: 'all',
	};

	// state
	const [filtersValues, setFiltersValues] = useState({
		type: paramType || defaultFiltersValues.type,
	});

	// context
	const { entities, isLoadingEntities, paginationEntities, refetchEntities } =
		useContext(ContextEntities);
	const { isOnboarded: isProjectOnboarded } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// vars
	const idFormFilters = 'form-entities-filters';

	const headers = [
		{
			isSortable: true,
			label: t('name'),
			lg: 3,
			value: 'name',
		},
		{
			label: t('wallet'),
			lg: 2,
			value: 'wallet',
		},
		{
			isSortable: true,
			label: t('created'),
			lg: 1.5,
			value: 'created',
		},
		{
			label: t('status'),
			lg: 1,
			value: 'status',
		},
		{
			label: t('type'),
			lg: 1.5,
			value: 'type',
		},
		{
			label: t('traceability'),
			lg: 3,
			value: 'traceability',
		},
	];

	// handlers
	const handleChangeFilterValue = (e: FilterChangeEvent) => {
		const { name, value } = e.target as HTMLInputElement;
		setFiltersValues({
			...filtersValues,
			[name]: value,
		});
	};

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [],
			content: { children: t('empty.entities.content') },
			IconCircleProps: { icon: (<IconAccountBalance />) as ReactNode },
			title: { children: t('empty.entities.title') },
		};

		if (paramQ || paramType) {
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		} else if (isProjectOnboarded && user?.permissions?.entity?.create && emptyProps.actions) {
			emptyProps.actions.push({
				children: t('addEntity'),
				onClick: () => {
					searchParams.set('dialog', 'addEntity');
					setSearchParams(searchParams);
				},
				variant: 'contained' as ActionButton['variant'],
			});
		}

		return emptyProps;
	}, [isProjectOnboarded, paramQ, paramType, searchParams, setSearchParams, user]);

	const content = useMemo(() => {
		if (isLoadingEntities || !entities.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingEntities} />;
		}

		return (
			<Grid container spacing={2}>
				{entities.map((entity, i) => {
					return (
						<Grid item key={`entity-${i}`} xs={12}>
							<CardEntity entity={entity} project={project} refetchEntities={refetchEntities} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, entities, isLoadingEntities, project, refetchEntities]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item xs="auto">
						<BtnFilters
							filters={[
								{
									hasAllOption: true,
									hasNoneOption: false,
									hasSearchParam: true,
									id: `${idFormFilters}-type`,
									label: t('type'),
									name: 'type',
									onChange: handleChangeFilterValue,
									options: getOptionsEntityTypes(t),
									type: 'SELECT',
									value: filtersValues.type,
								},
							]}
							onReset={() => {
								setFiltersValues(defaultFiltersValues);
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={entities.length} pagination={paginationEntities} />
			</Grid>
		</Grid>
	);
};
