// react
import { useEffect, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';

// forms
import { FormAddNonCashBenefit } from '../../forms/FormAddNonCashBenefit/FormAddNonCashBenefit';

// props
export interface DialogAddNonCashBenefitProps {
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const DialogAddNonCashBenefit = ({
	isOpen,
	onClose,
	onSuccess,
}: DialogAddNonCashBenefitProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// state
	const [entity, setEntity] = useState<Entity | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();

	// params
	const paramDialogToEntity = searchParams.get('dialogToEntity');

	// fetch entity
	const { fetchRequest: fetchToEntity } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities/${paramDialogToEntity}?fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntity(res.data[0]);
			}
		},
	});

	// vars
	const propsDialogActions: Partial<DialogActionsProps> = {
		actions: [
			{
				children: t('cancel'),
				onClick: onClose,
			},
			{
				children: t('recordNonCashBenefit'),
				form: 'form-add-non-cash-benefit',
				loading: isLoading,
				type: 'submit',
				variant: 'contained',
			},
		],
		title: {
			children: t('recordNonCashBenefit'),
			variant: 'h3',
		},
	};

	if (entity) {
		propsDialogActions.subtitle = {
			children: entity.name,
		};
	}

	// set entity when paramDialogToEntity changes
	useEffect(() => {
		if (paramDialogToEntity) {
			fetchToEntity();
		} else {
			setEntity(null);
		}
	}, [paramDialogToEntity, fetchToEntity]);

	return (
		<DialogActions hasClose={true} isOpen={isOpen} onClose={onClose} {...propsDialogActions}>
			<FormAddNonCashBenefit onClose={onClose} onSuccess={onSuccess} setIsLoading={setIsLoading} />
		</DialogActions>
	);
};
