// react
import { useContext, useMemo } from 'react';

// types
import { Entity } from '../../../../../@types';

// context
import { ContextEntitiesSummary } from '../../Project.context';

// i18n
import { useTranslation } from 'react-i18next';

// material
import { Container, Grid, Typography } from '@mui/material';

// cards
import { CardKeyValue } from '../../../../cards/CardKeyValue/CardKeyValue';

export const Summary = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { entitiesSummary, isLoadingEntitiesSummary } = useContext(ContextEntitiesSummary);

	const summary = useMemo(() => {
		let auditors = 0;
		let borrowers = 0;
		let donors = 0;
		let intermediaries = 0;
		let pius = 0;
		let subPius = 0;
		let taxAuthorities = 0;

		for (let i = 0; i < entitiesSummary.length; i++) {
			const entity: Entity = entitiesSummary[i];

			switch (entity.type) {
				case 'AUDITOR': {
					auditors++;
					break;
				}
				case 'BORROWER': {
					borrowers++;
					break;
				}
				case 'DONOR': {
					donors++;
					break;
				}

				case 'INTERMEDIARY': {
					intermediaries++;
					break;
				}

				case 'PIU': {
					pius++;
					break;
				}

				case 'SUB_PIU': {
					subPius++;
					break;
				}

				case 'TAX_AUTHORITY': {
					taxAuthorities++;
					break;
				}
			}
		}

		return {
			auditors,
			borrowers,
			donors,
			intermediaries,
			pius,
			subPius,
			taxAuthorities,
		};
	}, [entitiesSummary]);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('entities')}</Typography>
				</Grid>
				<Grid item xs={6} lg={4}>
					<CardKeyValue
						IconCircleProps={{ type: 'entity' }}
						isLoading={isLoadingEntitiesSummary}
						label={{ children: t('pius') }}
						value={{ children: summary.pius }}
					/>
				</Grid>
				<Grid item xs={6} lg={4}>
					<CardKeyValue
						IconCircleProps={{ type: 'subPiu' }}
						isLoading={isLoadingEntitiesSummary}
						label={{ children: t('subPius') }}
						value={{ children: summary.subPius }}
					/>
				</Grid>

				<Grid item xs={12} lg={4}>
					<Grid container spacing={2}>
						<Grid item xs={6} lg={true}>
							<CardKeyValue
								isLoading={isLoadingEntitiesSummary}
								label={{ children: t('intermediaries') }}
								value={{ children: summary.intermediaries }}
							/>
						</Grid>
						<Grid item xs={6} lg={true}>
							<CardKeyValue
								isLoading={isLoadingEntitiesSummary}
								label={{ children: t('borrowers') }}
								value={{ children: summary.borrowers }}
							/>
						</Grid>
						<Grid item xs={6} lg={true}>
							<CardKeyValue
								isLoading={isLoadingEntitiesSummary}
								label={{ children: t('auditors') }}
								value={{ children: summary.auditors }}
							/>
						</Grid>
						<Grid item xs={6} lg={true}>
							<CardKeyValue
								isLoading={isLoadingEntitiesSummary}
								label={{ children: t('donors') }}
								value={{ children: summary.donors }}
							/>
						</Grid>
						<Grid item xs={12} lg={true}>
							<CardKeyValue
								isLoading={isLoadingEntitiesSummary}
								label={{ children: t('taxAuthorities') }}
								value={{ children: summary.taxAuthorities }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
