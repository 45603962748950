// react
import { createContext, Dispatch, SetStateAction } from 'react';

// defaults
import { defaultPagination } from '../../../utils/defaults';

// types
import {
	Account,
	Activity,
	Contract,
	Document,
	Entity,
	Loan,
	Pagination,
	Project,
	Transaction,
	User,
} from '../../../@types';

// accounts

export interface ContextAccountsProps {
	accounts: Account[];
	isLoadingAccounts: boolean;
	setAccounts: Dispatch<SetStateAction<Account[]>>;
	refetchAccounts: () => void;
}

export const ContextAccounts = createContext<ContextAccountsProps>({
	accounts: [],
	isLoadingAccounts: false,
	setAccounts: () => {
		console.log('SET ACCOUNTS');
	},
	refetchAccounts: () => {
		console.log('REFETCH ACCOUNTS');
	},
});

// activities

export interface ContextActivitiesProps {
	activities: Activity[];
	isLoadingActivities: boolean;
	setActivities: Dispatch<SetStateAction<Activity[]>>;
	refetchActivities: () => void;
}

export const ContextActivities = createContext<ContextActivitiesProps>({
	activities: [],
	isLoadingActivities: false,
	setActivities: () => {
		console.log('SET ACTIVITIES');
	},
	refetchActivities: () => {
		console.log('REFETCH ACTIVITY');
	},
});

// contracts

export interface ContextContractsProps {
	isLoadingContracts: boolean;
	contracts: Contract[];
	paginationContracts: Pagination;
	setContracts: Dispatch<SetStateAction<Contract[]>>;
	setPaginationContracts: Dispatch<SetStateAction<Pagination>>;
	refetchContracts: () => void;
}

export const ContextContracts = createContext<ContextContractsProps>({
	isLoadingContracts: false,
	contracts: [],
	paginationContracts: defaultPagination,
	setContracts: () => {
		console.log('SET CONTRACTS');
	},
	setPaginationContracts: () => {
		console.log('SET PAGINATION');
	},
	refetchContracts: () => {
		console.log('REFETCH CONTRACTS');
	},
});

// entities

export interface ContextEntitiesProps {
	entities: Entity[];
	isLoadingEntities: boolean;
	paginationEntities: Pagination;
	setEntities: Dispatch<SetStateAction<Entity[]>>;
	setPaginationEntities: Dispatch<SetStateAction<Pagination>>;
	refetchEntities: () => void;
}

export const ContextEntities = createContext<ContextEntitiesProps>({
	entities: [],
	isLoadingEntities: false,
	paginationEntities: defaultPagination,
	setEntities: () => {
		console.log('SET ENTITIES');
	},
	setPaginationEntities: () => {
		console.log('SET PAGINATION');
	},
	refetchEntities: () => {
		console.log('REFETCH ENTITIES');
	},
});

// entities summary

export interface ContextEntitiesSummaryProps {
	entitiesSummary: Entity[];
	isLoadingEntitiesSummary: boolean;
	setEntitiesSummary: Dispatch<SetStateAction<Entity[]>>;
	refetchEntitiesSummary: () => void;
}

export const ContextEntitiesSummary = createContext<ContextEntitiesSummaryProps>({
	entitiesSummary: [],
	isLoadingEntitiesSummary: false,
	setEntitiesSummary: () => {
		console.log('SET ENTITIES');
	},
	refetchEntitiesSummary: () => {
		console.log('REFETCH ENTITIES');
	},
});

// loans

export interface ContextLoansProps {
	isLoadingLoans: boolean;
	loans: Loan[];
	paginationLoans: Pagination;
	setLoans: Dispatch<SetStateAction<Loan[]>>;
	refetchLoans: () => void;
}

export const ContextLoans = createContext<ContextLoansProps>({
	isLoadingLoans: false,
	loans: [],
	paginationLoans: defaultPagination,
	setLoans: () => {
		console.log('SET LOANS');
	},
	refetchLoans: () => {
		console.log('REFETCH LOANS');
	},
});

// operating expenses

export interface ContextOpExProps {
	isLoadingOpEx: boolean;
	paginationOpEx: Pagination;
	opEx: Transaction[];
	refetchOpEx: () => void;
	setPaginationOpEx: Dispatch<SetStateAction<Pagination>>;
	setOpEx: Dispatch<SetStateAction<Transaction[]>>;
}

export const ContextOpEx = createContext<ContextOpExProps>({
	isLoadingOpEx: false,
	paginationOpEx: defaultPagination,
	opEx: [],
	setOpEx: () => {
		console.log('SET OPEX');
	},
	setPaginationOpEx: () => {
		console.log('SET PAGINATION');
	},
	refetchOpEx: () => {
		console.log('REFETCH OPEX');
	},
});

// payments

export interface ContextPaymentsProps {
	isLoadingPayments: boolean;
	paginationPayments: Pagination;
	payments: Transaction[];
	refetchPayments: () => void;
	setPaginationPayments: Dispatch<SetStateAction<Pagination>>;
	setPayments: Dispatch<SetStateAction<Transaction[]>>;
}

export const ContextPayments = createContext<ContextPaymentsProps>({
	isLoadingPayments: false,
	paginationPayments: defaultPagination,
	payments: [],
	refetchPayments: () => {
		console.log('REFETCH PAYMENTS');
	},
	setPayments: () => {
		console.log('SET PAYMENTS');
	},
	setPaginationPayments: () => {
		console.log('SET PAGINATION');
	},
});

// cash benefits

export interface ContextCashBenefitsProps {
	cashBenefits: Transaction[];
	isLoadingCashBenefits: boolean;
	paginationCashBenefits: Pagination;
	refetchCashBenefits: () => void;
	setCashBenefits: Dispatch<SetStateAction<Transaction[]>>;
	setPaginationCashBenefits: Dispatch<SetStateAction<Pagination>>;
}

export const ContextCashBenefits = createContext<ContextCashBenefitsProps>({
	cashBenefits: [],
	isLoadingCashBenefits: false,
	paginationCashBenefits: defaultPagination,
	refetchCashBenefits: () => {
		console.log('REFETCH CASH BENEFITS');
	},
	setCashBenefits: () => {
		console.log('SET CASH BENEFITS');
	},
	setPaginationCashBenefits: () => {
		console.log('SET CASH BENEFITS');
	},
});

// non cash benefits

export interface ContextNonCashBenefitsProps {
	isLoadingNonCashBenefits: boolean;
	nonCashBenefits: Transaction[];
	paginationNonCashBenefits: Pagination;
	refetchNonCashBenefits: () => void;
	setNonCashBenefits: Dispatch<SetStateAction<Transaction[]>>;
	setPaginationNonCashBenefits: Dispatch<SetStateAction<Pagination>>;
}

export const ContextNonCashBenefits = createContext<ContextNonCashBenefitsProps>({
	isLoadingNonCashBenefits: false,
	nonCashBenefits: [],
	paginationNonCashBenefits: defaultPagination,
	refetchNonCashBenefits: () => {
		console.log('REFETCH NON CASH BENEFITS');
	},
	setNonCashBenefits: () => {
		console.log('SET NON CASH BENEFITS');
	},
	setPaginationNonCashBenefits: () => {
		console.log('SET NON CASH BENEFITS');
	},
});

// transfers

export interface ContextTransfersProps {
	isLoadingTransfers: boolean;
	paginationTransfers: Pagination;
	transfers: Transaction[];
	refetchTransfers: () => void;
	setPaginationTransfers: Dispatch<SetStateAction<Pagination>>;
	setTransfers: Dispatch<SetStateAction<Transaction[]>>;
}

export const ContextTransfers = createContext<ContextTransfersProps>({
	isLoadingTransfers: false,
	paginationTransfers: defaultPagination,
	transfers: [],
	setTransfers: () => {
		console.log('SET TRANSFERS');
	},
	setPaginationTransfers: () => {
		console.log('SET PAGINATION');
	},
	refetchTransfers: () => {
		console.log('REFETCH TRANSFERS');
	},
});

// historical transactions

export interface ContextHistoricalTransactionsProps {
	isLoadingHistoricalTransactions: boolean;
	paginationHistoricalTransactions: Pagination;
	historicalTransactions: Document[];
	refetchHistoricalTransactions: () => void;
	setPaginationHistoricalTransactions: Dispatch<SetStateAction<Pagination>>;
	setHistoricalTransactions: Dispatch<SetStateAction<Document[]>>;
}

export const ContextHistoricalTransactions = createContext<ContextHistoricalTransactionsProps>({
	isLoadingHistoricalTransactions: false,
	paginationHistoricalTransactions: defaultPagination,
	historicalTransactions: [],
	setHistoricalTransactions: () => {
		console.log('SET HISTORICAL TRANSACTIONS');
	},
	setPaginationHistoricalTransactions: () => {
		console.log('SET PAGINATION');
	},
	refetchHistoricalTransactions: () => {
		console.log('REFETCH HISTORICAL TRANSACTIONS');
	},
});

// project

export interface ContextProjectProps {
	isLoadingProject: boolean;
	isOnboarded: boolean;
	project: Project | null;
	setProject: Dispatch<SetStateAction<Project | null>>;
	refetchProject: () => void;
}

export const ContextProject = createContext<ContextProjectProps>({
	isLoadingProject: false,
	isOnboarded: false,
	project: null,
	setProject: () => {
		console.log('SET PROJECT');
	},
	refetchProject: () => {
		console.log('REFETCH PROJECT');
	},
});

// suppliers

export interface ContextSuppliersProps {
	isLoadingSuppliers: boolean;
	paginationSuppliers: Pagination;
	setPaginationSuppliers: Dispatch<SetStateAction<Pagination>>;
	setSuppliers: Dispatch<SetStateAction<Entity[]>>;
	suppliers: Entity[];
	refetchSuppliers: () => void;
}

export const ContextSuppliers = createContext<ContextSuppliersProps>({
	isLoadingSuppliers: false,
	paginationSuppliers: defaultPagination,
	setPaginationSuppliers: () => {
		console.log('SET PAGINATION');
	},
	setSuppliers: () => {
		console.log('SET SUPPLIERS');
	},
	suppliers: [],
	refetchSuppliers: () => {
		console.log('REFETCH SUPPLIERS');
	},
});

// beneficiaries

export interface ContextBeneficiariesProps {
	isLoadingBeneficiaries: boolean;
	paginationBeneficiaries: Pagination;
	setPaginationBeneficiaries: Dispatch<SetStateAction<Pagination>>;
	setBeneficiaries: Dispatch<SetStateAction<Entity[]>>;
	beneficiaries: Entity[];
	refetchBeneficiaries: () => void;
}

export const ContextBeneficiaries = createContext<ContextBeneficiariesProps>({
	isLoadingBeneficiaries: false,
	paginationBeneficiaries: defaultPagination,
	setPaginationBeneficiaries: () => {
		console.log('SET PAGINATION');
	},
	setBeneficiaries: () => {
		console.log('SET BENEFICIARIES');
	},
	beneficiaries: [],
	refetchBeneficiaries: () => {
		console.log('REFETCH BENEFICIARIES');
	},
});

// documents suppliers
export interface ContextDocumentsSuppliersProps {
	isLoadingDocumentsSuppliers: boolean;
	paginationDocumentsSuppliers: Pagination;
	setPaginationDocumentsSuppliers: Dispatch<SetStateAction<Pagination>>;
	setDocumentsSuppliers: Dispatch<SetStateAction<Document[]>>;
	documentsSuppliers: Document[];
	refetchDocumentsSuppliers: () => void;
}

export const ContextDocumentsSuppliers = createContext<ContextDocumentsSuppliersProps>({
	isLoadingDocumentsSuppliers: false,
	paginationDocumentsSuppliers: defaultPagination,
	setPaginationDocumentsSuppliers: () => {
		console.log('SET PAGINATION');
	},
	setDocumentsSuppliers: () => {
		console.log('SET DOCUMENTS');
	},
	documentsSuppliers: [],
	refetchDocumentsSuppliers: () => {
		console.log('REFETCH DOCUMENTS');
	},
});

// documents beneficiaries
export interface ContextDocumentsBeneficiariesProps {
	isLoadingDocumentsBeneficiaries: boolean;
	paginationDocumentsBeneficiaries: Pagination;
	setPaginationDocumentsBeneficiaries: Dispatch<SetStateAction<Pagination>>;
	setDocumentsBeneficiaries: Dispatch<SetStateAction<Document[]>>;
	documentsBeneficiaries: Document[];
	refetchDocumentsBeneficiaries: () => void;
}

export const ContextDocumentsBeneficiaries = createContext<ContextDocumentsBeneficiariesProps>({
	isLoadingDocumentsBeneficiaries: false,
	paginationDocumentsBeneficiaries: defaultPagination,
	setPaginationDocumentsBeneficiaries: () => {
		console.log('SET PAGINATION');
	},
	setDocumentsBeneficiaries: () => {
		console.log('SET DOCUMENTS');
	},
	documentsBeneficiaries: [],
	refetchDocumentsBeneficiaries: () => {
		console.log('REFETCH DOCUMENTS');
	},
});

// users with permissions
export interface ContextUsersPermissionsProps {
	isLoadingUsersPermissions: boolean;
	paginationUsersPermissions: Pagination;
	setPaginationUsersPermissions: Dispatch<SetStateAction<Pagination>>;
	setUsersPermissions: Dispatch<SetStateAction<User[]>>;
	refetchUsersPermissions: () => void;
	usersPermissions: User[];
}

export const ContextUsersPermissions = createContext<ContextUsersPermissionsProps>({
	isLoadingUsersPermissions: false,
	paginationUsersPermissions: defaultPagination,
	setPaginationUsersPermissions: () => {
		console.log('SET PAGINATION');
	},
	setUsersPermissions: () => {
		console.log('SET USERS WITH PERMISSIONS');
	},
	refetchUsersPermissions: () => {
		console.log('REFETCH USERS WITH PERMISSIONS');
	},
	usersPermissions: [],
});
