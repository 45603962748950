// react
import { useContext, useEffect, useState } from 'react';

// types
import { Form, Pagination } from '../../../../../@types';

// context
import { ContextProject } from '../../../../pages/Project/Project.context';
import { ContextUser } from '../../../../../App.context';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';
import { useSearchParams } from 'react-router-dom';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableForms } from '../../../../tables/cards/TableForms/TableForms';

// utils
import { defaultPagination } from '../../../../../utils/defaults';

export const Forms = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const [searchParams] = useSearchParams();

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// params
	const paramOrder = searchParams.get('order') || 'DESC';
	const paramOrderBy = searchParams.get('orderBy') || 'created';
	const paramPage = Number(searchParams.get('page') || 1);

	// state
	const [forms, setForms] = useState<Form[]>([]);
	const [paginationForms, setPaginationForms] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});

	// vars
	const entityId = typeof user?.entity === 'number' ? user?.entity : user?.entity?.id;

	// fetch forms
	const { fetchRequest: getForms, isLoading: isLoadingForms } = useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${entityId}&page=${paramPage}&limit=${paginationForms.perPage}&status=PUBLISHED&populate=user&orderBy=${paramOrderBy}&order=${paramOrder}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				console.log(res.data);
				setForms(res.data);
			}
			if (res.pagination) {
				setPaginationForms(res.pagination);
			}
		},
	});

	// get forms when page param changes
	useEffect(() => {
		getForms();
	}, [getForms, paramOrder, paramOrderBy, paramPage]);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableForms forms={forms} isLoading={isLoadingForms} pagination={paginationForms} />
				</Grid>
			</Grid>
		</Container>
	);
};
