// react
import { MouseEvent, ReactNode, useState } from 'react';

// types
import { Order, Sort } from '../../../@types';

// i18n
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import {
	Alert,
	Box,
	Grid,
	SxProps,
	Table as MuiTable,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';

// ui
import { Empty } from '../Empty/Empty';
import { FnSort } from '../TableHeaders/TableHeaders';

// theme
import { theme } from '../../../theme';

// icons
import {
	KeyboardArrowDownOutlined as IconKeyboardArrowDown,
	KeyboardArrowUpOutlined as IconKeyboardArrowUp,
} from '@mui/icons-material';

// props
export const tableHeaderPositions = ['left', 'right'];

export type TableHeaderPosition = (typeof tableHeaderPositions)[number];

export interface TableHeader {
	align?: TableHeaderPosition;
	isSortable?: boolean;
	label: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	render?: (value: any, header: TableHeader, data: any, index: number) => ReactNode;
	sx?: SxProps;
	value: string;
}

// table
export interface TableProps {
	borderRadius?: number;
	children?: ReactNode;
	color?: 'blue' | 'grey';
	colorBorder?: 'blue' | 'grey';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
	frame?: 'CARD' | 'DEFAULT';
	hasBorder?: boolean;
	hasDividers?: boolean;
	hasStripes?: boolean;
	headers: TableHeader[];
	isLoading?: boolean;
	isStacked?: boolean;
	isSticky?: boolean;
	onSort?: FnSort;
	sxTable?: SxProps;
	sxTd?: SxProps;
}

export const Table = ({
	borderRadius,
	children,
	color = 'blue',
	colorBorder = 'grey',
	data,
	frame = 'DEFAULT',
	headers,
	hasStripes = false,
	hasBorder = false,
	hasDividers = false,
	isLoading,
	isStacked = false,
	isSticky = false,
	onSort,
	sxTable = {},
	sxTd = {},
}: TableProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// state
	const [activeSort, setActiveSort] = useState<Sort | undefined>(undefined);

	// vars
	const cellPadding = frame === 'CARD' ? theme.spacing(1.5, 3) : theme.spacing(2);
	const colors = {
		blue: {
			active: theme.palette.brand.blue[400],
			background: theme.palette.brand.blue[100],
			border: theme.palette.brand.blue[500],
			header: theme.palette.brand.blue[700],
			inactive: theme.palette.brand.blue[700],
		},
		grey: {
			active: theme.palette.brand.grey[800],
			background: theme.palette.brand.grey[100],
			border: theme.palette.brand.grey[400],
			header: theme.palette.brand.grey[800],
			inactive: theme.palette.brand.grey[400],
		},
	};

	const handleSort = (e: MouseEvent<HTMLButtonElement>, value: TableHeader['value']) => {
		// define new order
		const orderNew: Order = activeSort?.order === 'ASC' ? 'DESC' : 'ASC';

		// run callback
		if (onSort) {
			onSort({ e, order: orderNew, value });
		}

		// set active sorted header
		if (setActiveSort) {
			setActiveSort({
				order: orderNew,
				value,
			});
		}

		// set search params
		searchParams.set('orderBy', value);
		searchParams.set('order', orderNew);
		setSearchParams(searchParams);
	};

	const renderTable = () => {
		if (isLoading) {
			return (
				<Box
					sx={(theme) => {
						return {
							padding: theme.spacing(2),
							height: '100%',
						};
					}}>
					<Empty
						hasContent={false}
						hasTitle={false}
						isLoading={isLoading}
						sxBox={{ height: '100%' }}
					/>
				</Box>
			);
		}

		const thead = (
			<TableHead
				sx={(theme) => {
					let sx: SxProps = {
						backgroundColor: colors[color].background,
					};

					if (isStacked) {
						sx = {
							...sx,
							display: 'none',
							[theme.breakpoints.up('lg')]: {
								display: 'table-header-group',
							},
						};
					}

					if (isSticky) {
						sx = {
							...sx,
							position: 'sticky',
							top: 0,
						};
					}

					return sx;
				}}>
				<TableRow>
					{headers.map((header) => {
						const isSortActive = header.value === activeSort?.value;

						const th = (
							<Grid alignItems="center" container spacing={1}>
								<Grid item>
									<Typography fontWeight={600} variant="caption">
										{header.label}
									</Typography>
								</Grid>
								{header.isSortable && (
									<Grid item>
										<Grid
											container
											sx={(theme) => {
												return {
													'& svg': {
														transition: 'all 200ms',
														display: 'block !important',
														width: `${theme.spacing(2)} !important`,
														height: 'auto !important',
													},
												};
											}}>
											<Grid
												item
												xs={12}
												sx={(theme) => {
													return {
														'& svg': {
															color:
																isSortActive && activeSort?.order === 'ASC'
																	? colors[color].active
																	: colors[color].inactive,
														},
													};
												}}>
												<IconKeyboardArrowUp />
											</Grid>
											<Grid
												item
												xs={12}
												sx={(theme) => {
													return {
														'& svg': {
															color:
																isSortActive && activeSort?.order === 'DESC'
																	? colors[color].active
																	: colors[color].inactive,
														},
													};
												}}>
												<IconKeyboardArrowDown />
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						);

						const thButton = (
							<Box
								component="button"
								onClick={(e: MouseEvent<HTMLButtonElement>) => handleSort(e, header.value)}
								sx={(theme) => {
									return {
										color: colors[color].header,
										'&:hover': {
											'& svg': {
												opacity: 1,
											},
										},
									};
								}}>
								{th}
							</Box>
						);

						return (
							<TableCell
								key={`table-header-${header.value}-${header.label}`}
								sx={(theme) => {
									let sx: SxProps = {
										borderBottom: hasBorder ? `1px ${colors[colorBorder].border} solid` : null,
										color: colors[color].header,
										padding: cellPadding,
										textAlign: 'left',
										whiteSpace: 'nowrap',
										...(header.sx as object),
									};

									if (header.align === 'right') {
										sx = {
											...sx,
											textAlign: {
												lg: 'right',
											},
										};
									}

									return sx;
								}}>
								{header.isSortable ? thButton : th}
							</TableCell>
						);
					})}
				</TableRow>
			</TableHead>
		);

		let tbody: ReactNode = (
			<TableRow>
				<TableCell
					colSpan={headers.length}
					sx={(theme) => {
						return {
							padding: theme.spacing(1),
						};
					}}>
					<Alert severity="info">{t('alert.empty')}</Alert>
				</TableCell>
			</TableRow>
		);

		if (data.length > 0) {
			tbody =
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				data.map((item: any, i: number) => {
					let sx: SxProps = {};

					if (hasDividers && i < data.length - 1) {
						sx = {
							...sx,
							borderBottomColor: theme.palette.divider,
							borderBottomStyle: 'solid',
							borderBottomWidth: 1,
						};
					}

					if (isStacked) {
						sx = {
							...sx,
							display: 'block',
							padding: theme.spacing(2),
							[theme.breakpoints.up('lg')]: {
								display: 'table-row',
								padding: 0,
							},
							'&:nth-child(odd)': {
								backgroundColor: theme.palette.brand.grey[100],
							},
						};
					}

					return (
						<TableRow key={`table-row-${i}`} sx={sx}>
							{headers.map((header) => {
								const content = header.render ? (
									header.render(item[header.value], header, item, i)
								) : (
									<Typography component="span">{item[header.value]}</Typography>
								);

								return (
									<TableCell
										key={`table-data-${header.label}-${i}`}
										sx={(theme) => {
											let sx: SxProps = {
												padding: cellPadding,
												textAlign: 'left',
												textWrap: {
													xs: 'nowrap',
													md: 'wrap',
												},
												whiteSpace: frame === 'CARD' ? 'nowrap' : 'wrap',
												...(sxTd as object),
											};

											if (hasStripes) {
												sx = {
													...sx,
													bgcolor: i % 2 ? theme.palette.grey[50] : theme.palette.common.white,
												};
											}

											if (header.align === 'right') {
												sx = {
													...sx,
													textAlign: {
														lg: 'right',
													},
												};
											}

											if (isStacked) {
												sx = {
													...sx,
													display: 'grid',
													gridTemplateColumns: '1fr 1fr',
													padding: 0,
													[theme.breakpoints.up('lg')]: {
														display: 'table-cell',
														padding: theme.spacing(1, 3),
													},
												};
											}

											return sx;
										}}>
										{isStacked && (
											<Typography
												color="brand.grey.500"
												fontWeight={600}
												sx={(theme) => {
													return {
														display: {
															lg: 'none',
														},
													};
												}}
												variant="caption">
												{header.label}
											</Typography>
										)}{' '}
										{content}
									</TableCell>
								);
							})}
						</TableRow>
					);
				});
		}

		return (
			<MuiTable
				sx={(theme) => {
					return {
						borderCollapse: 'collapse',
						width: '100%',
						...(sxTable as object),
					};
				}}>
				{thead}
				<TableBody>{tbody}</TableBody>
				{children}
			</MuiTable>
		);
	};

	return (
		<Box
			sx={(theme) => {
				const sx: SxProps = {
					border: hasBorder ? `1px ${colors[colorBorder].border} solid` : null,
					borderRadius: borderRadius || null,
					overflowX: 'auto',
					height: '100%',
				};

				return sx;
			}}>
			{renderTable()}
		</Box>
	);
};
