// types
import { Loan } from '../../../@types';

// i18next
import { useTranslation } from 'react-i18next';

// material
import { Grid } from '@mui/material';

// cards
import { CardKeyValue } from '../../cards/CardKeyValue/CardKeyValue';

// utils
import { formatNumber } from '../../../utils/format';

// props
export interface SummaryFinancingSourcesProps {
	loans: Loan[];
}

export const SummaryFinancingSources = ({ loans }: SummaryFinancingSourcesProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	let amount = 0;
	let disbursed = 0;

	for (let i = 0; i < loans.length; i++) {
		amount += loans[i].amount;
		disbursed += loans[i].disbursed;
	}

	const undisbursed = amount - disbursed;

	let percent = 0;

	if (typeof amount === 'number' && typeof disbursed === 'number') {
		if (amount > 0) {
			percent = Math.round((disbursed / amount) * 100);
		}
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={6} lg={3}>
				<CardKeyValue
					label={{ children: t('totalAmount') }}
					value={{ children: formatNumber(amount) }}
				/>
			</Grid>
			<Grid item xs={6} lg={3}>
				<CardKeyValue
					label={{ children: t('undisbursed') }}
					value={{ children: formatNumber(undisbursed) }}
				/>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardKeyValue
					label={{ children: t('disbursed') }}
					percent={percent}
					value={{ children: formatNumber(disbursed) }}
				/>
			</Grid>
		</Grid>
	);
};
