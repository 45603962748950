// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Alert, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// fields
import { Input } from '../../fields/Input/Input';

// props
export interface FormAddBenefitProps {
	onClose?: () => void;
	onSuccess?: () => void;
}

export const FormAddBenefit = ({ onClose, onSuccess }: FormAddBenefitProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// vars
	const idForm = 'form-add-benefit';

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-add-benefit') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// set loader
			setIsLoading(true);

			const fetchAddBenefit = await fetch(`${process.env.REACT_APP_API_URL}/benefits`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify({
					name: entries.name,
					project: project?.id,
				}),
			});
			const resAddBenefit = await fetchAddBenefit.json();

			if (resAddBenefit.error) {
				throw new Error(resAddBenefit.error.message, {
					cause: {
						id: resAddBenefit.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.benefitCreated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'BENEFIT_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formAddBenefit" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Input id={`${idForm}-name`} label={t('name')} name="name" required={true} />
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
				<Grid item xs={12}>
					<LoadingButton loading={isLoading} type="submit" variant="contained">
						{t('addBenefit')}
					</LoadingButton>
				</Grid>
			</Grid>
		</form>
	);
};
