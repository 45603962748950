// react
import { ReactNode, useMemo } from 'react';

// types
import { ActionButton, Frame, Pagination, User } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid } from '@mui/material';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardUser } from '../../../cards/CardUser/CardUser';

// ui
import { TableFooter } from '../../../ui/TableFooter/TableFooter';
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';

// icons
import { PersonOutlined as IconPerson } from '@mui/icons-material';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

// props
export interface TableUsersProps {
	frame?: Frame;
	isLoading: boolean;
	pagination?: Pagination;
	refetchUsers?: () => void;
	users: User[];
}

export const TableUsers = ({
	frame,
	isLoading,
	pagination,
	refetchUsers,
	users,
}: TableUsersProps) => {
	// hooks
	const { t } = useTranslation();

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramQ = searchParams.get('q');

	// vars
	const headers = [
		{
			isSortable: true,
			label: t('name'),
			lg: 3,
			value: 'firstName',
		},
		{
			label: t('jobTitle'),
			lg: 2,
			value: 'title',
		},
		{
			label: t('email'),
			lg: 1.5,
			value: 'email',
		},
		{
			label: t('phone'),
			lg: 1.5,
			value: 'phone',
		},
		{
			label: t('status'),
			lg: 1.5,
			value: 'status',
		},
		{
			isSortable: true,
			label: t('lastUpdated'),
			lg: 1.5,
			value: 'modified',
		},
	];

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('addUser'),
					onClick: () => {
						searchParams.set('dialog', 'addUser');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.users.content') },
			IconCircleProps: { icon: (<IconPerson />) as ReactNode },
			isLoading,
			title: { children: t('empty.users.title') },
		};

		if (paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [isLoading, paramQ, searchParams, setSearchParams, t]);

	const content = useMemo(() => {
		if (isLoading || !users.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{users.map((user, i) => {
					return (
						<Grid item key={`user-${i}`} xs={12}>
							<CardUser frame={frame} refetchUsers={refetchUsers} user={user} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, frame, isLoading, refetchUsers, users]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<FormSearch />
			</Grid>
			<Grid item xs={12}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			{pagination && (
				<Grid item xs={12}>
					<TableFooter numShowing={users.length} pagination={pagination} />
				</Grid>
			)}
		</Grid>
	);
};
