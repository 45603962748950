// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// types
import { DocumentMetadata, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { FilePicker } from '../../fields/FilePicker/FilePicker';
import { Input } from '../../fields/Input/Input';

// props
export interface FormUploadHistoricalTransactionsProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormUploadHistoricalTransactions = ({
	onClose,
	onSuccess,
	setIsLoading,
}: FormUploadHistoricalTransactionsProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);
	const [files, setFiles] = useState<File[]>([]);

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const formId = 'form-upload-historical-transactions';

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (files.length === 0) {
				throw new Error('At least one document needs to be uploaded', {
					cause: {
						id: 'TRANSACTION_DOCUMENTS_NON_EXISTENT',
					},
				});
			}

			// define date vars
			const dateToday = new Date();
			const year = dateToday.getFullYear();
			const month = new Intl.DateTimeFormat('en-US', {
				month: '2-digit',
			}).format(dateToday);

			// for each file
			for (let i = 0; i < files.length; i++) {
				// define form data
				const formData = new FormData();

				// define metadata
				const metadata: DocumentMetadata = {
					dateStart: new Date(entries['metadata-dateStart'] as string),
					dateEnd: new Date(entries['metadata-dateEnd'] as string),
				};

				// append form data
				formData.append('category', 'HISTORICAL_TRANSACTIONS');
				formData.append('file', files[i]);
				formData.append('metadata', JSON.stringify(metadata));
				formData.append('path', `bldt/projects/${project?.id}/historical/${year}/${month}/`);

				if (project) {
					formData.append('project', String(project.id));
				}

				// POST document
				const fetchPostDoc = await fetch(`${process.env.REACT_APP_API_URL}/documents`, {
					method: 'post',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				const resPostDoc = await fetchPostDoc.json();

				if (resPostDoc.error) {
					throw new Error(resPostDoc.error.message, {
						cause: {
							id: resPostDoc.error.id,
						},
					});
				}
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.historicalTransactionUploaded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'DOCUMENT_FILE_NON_EXISTENT') {
					message = t('error.documentFileNonExistent');
				}

				if (cause.id === 'DOCUMENT_INVALID_MIME_TYPE') {
					message = t('error.documentInvalidMimeType');
				}

				if (cause.id === 'TRANSACTION_DOCUMENTS_NON_EXISTENT') {
					message = t('error.documentsNonExistent');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} name="formUploadHistoricalTransactions" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
					<Input
						id={`${formId}-date-start`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('startDate')}
						name="metadata-dateStart"
						required={true}
						type="date"
					/>
				</Grid>

				<Grid item xs={12} lg={6}>
					<Input
						id={`${formId}-date-end`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('endDate')}
						name="metadata-dateEnd"
						required={true}
						type="date"
					/>
				</Grid>

				<Grid item xs={12}>
					<FilePicker onChange={(e, filesSelected) => setFiles(filesSelected)} required={true} />
				</Grid>

				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
