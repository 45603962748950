// react
import { ReactNode } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Typography, TypographyProps } from '@mui/material';

// utils
import { formatDate, toUTC } from '../../../utils/dates';

// props
export interface TimeProps {
	date: Date | null | string | undefined;
	TypographyProps?: TypographyProps;
}

export const Time = ({ date, TypographyProps }: TimeProps) => {
	// hooks
	const { t } = useTranslation();

	let time: ReactNode = t('na');

	if (date) {
		const dateUtc = toUTC(date);
		time = <time dateTime={dateUtc.toISOString()}>{formatDate({ date: dateUtc })}</time>;
	}

	return (
		<Typography color="brand.grey.500" component="span" {...TypographyProps}>
			{time}
		</Typography>
	);
};
