// react
import { ReactNode, useContext, useMemo } from 'react';

// types
import { ActionButton } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import {
	ContextHistoricalTransactions,
	ContextProject,
} from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// icons
import { InsertDriveFileOutlined as IconInsertDriveFile } from '@mui/icons-material';

// ui
import { TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardHistoricalTransaction } from '../../../cards/CardHistoricalTransaction/CardHistoricalTransaction';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

export const TableHistoricalTransactions = () => {
	// context
	const {
		historicalTransactions,
		isLoadingHistoricalTransactions,
		paginationHistoricalTransactions,
	} = useContext(ContextHistoricalTransactions);
	const { project } = useContext(ContextProject);

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramQ = searchParams.get('q');
	const paramExpenseType = searchParams.get('expenseType');

	// vars
	const headers = [
		{
			isSortable: true,
			label: t('name'),
			lg: 3,
			value: 'name',
		},
		{
			label: t('created'),
			lg: 2,
			value: 'created',
		},
		{
			label: t('startDate'),
			lg: 2,
			value: 'dateStart',
		},
		{
			label: t('endDate'),
			lg: 2,
			value: 'dateEnd',
		},
		{
			label: t('uploaded'),
			lg: 3,
			value: 'user',
		},
	];

	// memos
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('uploadHistoricalTransactions'),
					onClick: () => {
						searchParams.set('dialog', 'uploadHistoricalTransactions');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.historicalTransactions.content') },
			IconCircleProps: { icon: (<IconInsertDriveFile />) as ReactNode },
			title: { children: t('empty.historicalTransactions.title') },
		};

		if (paramQ || paramExpenseType) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, paramExpenseType, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoadingHistoricalTransactions || !project || !historicalTransactions.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingHistoricalTransactions} />;
		}

		return (
			<Grid container spacing={2}>
				{historicalTransactions.map((document, i) => {
					return (
						<Grid item key={`document-${i}`} xs={12}>
							<CardHistoricalTransaction document={document} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, historicalTransactions, isLoadingHistoricalTransactions, project]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter
					numShowing={historicalTransactions.length}
					pagination={paginationHistoricalTransactions}
				/>
			</Grid>
		</Grid>
	);
};
