// react
import { useContext } from 'react';

// mui
import { Container, Grid } from '@mui/material';

// context
import { ContextBeneficiaries } from '../../Project.context';

// tables
import { TableBeneficiaries } from '../../../../tables/cards/TableBeneficiaries/TableBeneficiaries';

export const Details = () => {
	// context
	const { isLoadingBeneficiaries, paginationBeneficiaries, beneficiaries, refetchBeneficiaries } =
		useContext(ContextBeneficiaries);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableBeneficiaries
						isLoading={isLoadingBeneficiaries}
						pagination={paginationBeneficiaries}
						beneficiaries={beneficiaries}
						refetchBeneficiaries={refetchBeneficiaries}
						refetchEntities={refetchBeneficiaries}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
