// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useEffect, useState } from 'react';

// types
import {
	Account,
	AccountBalancePayload,
	AccountEntityPayload,
	AccountPayload,
	AccountType,
	Entity,
	ErrorCause,
	Loan,
	Option,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// icons
import { FlagOutlined as IconFlag } from '@mui/icons-material';

// img
import { LogoWorldBankMark } from '../../img/LogoWorldBankMark';

// fields
import { Input } from '../../fields/Input/Input';
import { RadioButtons } from '../../fields/RadioButtons/RadioButtons';
import { Select } from '../../fields/Select/Select';

// utils
import { translateType } from '../../../utils/translations';
import { Amounts } from '../../fields/Amounts/Amounts';

// props
export interface FormAddAccountProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddAccount = ({ onClose, onSuccess, setIsLoading }: FormAddAccountProps) => {
	// hooks
	const { t } = useTranslation();

	// search params
	const [searchParams] = useSearchParams();

	// defaults
	const defaultType = searchParams.get('type') || 'DESIGNATED';

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [accountType, setAccountType] = useState(defaultType);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [owner, setOwner] = useState('');

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// vars
	const formId = 'form-add-account';
	const isDesignated = accountType === 'DESIGNATED';

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');
	const sessionUser = sessionStorage.getItem('user');
	const user = sessionUser ? JSON.parse(sessionUser) : null;

	// fetch accounts
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&populate=loan&type=DESIGNATED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type,wallet&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU&populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setEntities(res.data);
		},
	});

	// options
	const optionsAccounts = accounts.map((account) => {
		return {
			label: account.name,
			secondary: account.institution,
			value: account.id,
		};
	});

	const optionsEntities = entities
		.filter((entity) => entity.id !== user.entity?.id)
		.map((entity) => {
			return {
				disabled: entity.wallet?.status === 'INACTIVE',
				label: entity.name,
				secondary: translateType(entity.type, t),
				value: String(entity.id),
			};
		})
		.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

	let optionsOwner: Option[] = [];

	if (user.entity) {
		optionsOwner = [
			{
				label: user.entity.name,
				value: String(user.entity.id),
			},
		];
	} else {
		optionsOwner = optionsEntities;
	}

	// filter out owner for additional entities
	const optionsEntitiesAdditional = optionsEntities.filter((entity) => entity.value !== owner);

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// define balances using loans
		const balances: AccountBalancePayload[] = [];

		for (let s = 0; s < loans.length; s++) {
			if (entries[`source-${s}`]) {
				balances.push({
					currency: entries.currency,
					balance: Number(entries[`source-${s}`]),
					loan: loans[s].id,
				});
			}
		}

		// define entities
		let entities: AccountEntityPayload[] = [];

		if (entries.entities) {
			const entitiesStr = entries.entities as string;
			entities = entitiesStr.split(',').map((id) => {
				return {
					id: Number(id),
					relationship: 'ASSOCIATE',
				};
			});
		}

		// check if OWNER entity is associated entity
		const ownerFound = entities.find((entity) => entity.id === Number(owner));

		if (ownerFound) {
			throw new Error('The account owner cannot be an associated entity.', {
				cause: {
					id: 'ACCOUNT_OWNER_ASSOCIATE',
				},
			});
		}

		// add owner entity
		entities.push({
			id: Number(owner),
			relationship: 'OWNER',
		});

		// define accounts
		let accounts: number[] = [];
		if (entries.accounts) {
			const accountsStr = entries.accounts as string;
			accounts = accountsStr.split(',').map((id) => Number(id));
		}

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			const body: AccountPayload = {
				accounts,
				balances,
				currency: String(entries.currency),
				entities,
				institution: String(entries.institution),
				name: String(entries.name),
				num: String(entries.num),
				project: project?.id,
				type: entries.type as AccountType,
			};

			if (accounts.length) {
				body.accounts = accounts;
			}

			const fetchAddAccount = await fetch(`${process.env.REACT_APP_API_URL}/accounts`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resAddAccount = await fetchAddAccount.json();

			if (resAddAccount.error) {
				throw new Error(resAddAccount.error.message, {
					cause: {
						id: resAddAccount.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountCreated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let msg = t('error.default');

			if (cause) {
				if (cause.id === 'ACCOUNT_OWNER_ASSOCIATE') {
					msg = t('error.accountOwnerAssociate');
				}

				if (cause.id === 'ACCOUNT_UNAUTHORIZED_POST') {
					msg = t('error.unauthorized');
				}
			}

			// set error
			setError(msg);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	// set user entity as owner
	useEffect(() => {
		if (user.entity) {
			setOwner(String(user.entity.id));
		}
	}, [user]);

	return (
		<form id={`${formId}`} name="formAddAccount" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RadioButtons
						defaultValue={defaultType}
						id={`${formId}-type`}
						inputs={[
							{
								icon: <LogoWorldBankMark width={20} />,
								label: t('designatedAccount'),
								value: 'DESIGNATED',
							},
							{
								icon: <IconFlag />,
								label: t('localAccount'),
								value: 'LOCAL',
							},
						]}
						label={t('type')}
						name="type"
						onChange={(e, value) => {
							setAccountType(value);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input id={`${formId}-name`} label={t('name')} name="name" required={true} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input id={`${formId}-number`} label={t('accountNumber')} name="num" required={true} />
				</Grid>
				<Grid item xs={12} md={isDesignated ? 12 : 6}>
					<Input
						id={`${formId}-financial-institution`}
						label={t('financialInstitution')}
						name="institution"
						required={true}
					/>
				</Grid>
				{!isDesignated && (
					<Grid item xs={12} md={6}>
						<Select
							id={`${formId}-designated-accounts`}
							label={t('designatedAccounts')}
							multiple={true}
							name="accounts"
							options={optionsAccounts}
						/>
					</Grid>
				)}

				<Grid item xs={12}>
					<Select
						disabled={Boolean(user?.entity)}
						hasNoneOption={false}
						id={`${formId}-owner`}
						label="Owner"
						name="owner"
						onChange={(e) => {
							const { value } = e.target as HTMLInputElement;
							setOwner(value);
						}}
						options={optionsOwner}
						required={true}
						value={owner}
					/>
				</Grid>

				{optionsEntitiesAdditional.length > 0 && (
					<Grid item xs={12}>
						<Select
							disabled={owner === ''}
							hasNoneOption={false}
							helperText={t('additionalEntities.helper')}
							id={`${formId}-entities`}
							label={t('additionalEntities')}
							multiple={true}
							name="entities"
							options={optionsEntitiesAdditional}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<Amounts formId={formId} isRequired={false} loans={loans} title={t('balances')} />
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
