import { ReactNode } from 'react';

// types
import { EntitySubtype, EntityType, Financier, TransactionType } from '../../../@types';

// mui
import { ClassNameMap } from '@mui/material';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	AddchartOutlined as IconAddchart,
	AssignmentOutlined as IconAssignment,
	CalculateOutlined as IconCalculate,
	CategoryOutlined as IconCategory,
	CheckOutlined as IconCheck,
	CloudUploadOutlined as IconCloudUpload,
	CommentOutlined as IconComment,
	CorporateFareOutlined as IconCorporateFare,
	CreateOutlined as IconCreate,
	DescriptionOutlined as IconDescription,
	FlagOutlined as IconFlag,
	FolderOutlined as IconFolder,
	InfoOutlined as IconInfo,
	InsertDriveFileOutlined as IconInsertDriveFile,
	LocalAtmOutlined as IconLocalAtm,
	PageviewOutlined as IconPageview,
	PaidOutlined as IconPaid,
	PaymentsOutlined as IconPayments,
	PeopleOutlineOutlined as IconPeople,
	PersonOutlined as IconPerson,
	PublicOutlined as IconPublic,
	ReplayOutlined as IconReplenishment,
	SearchOutlined as IconSearch,
	StoreOutlined as IconStore,
	SwapHorizOutlined as IconTransfer,
	ViewListOutlined as IconList,
	VolunteerActivismOutlined as IconVolunteerActivism,
} from '@mui/icons-material';

// img
import { LogoWorldBankMark } from '../../img/LogoWorldBankMark';

// styles
import useStyles from './IconCircle.styles';

// entity icon types
export const entityIconTypes: Partial<Record<EntityType, IconCircleProps['type']>> = {
	AUDITOR: 'auditor',
	BENEFICIARY: 'individual',
	BORROWER: 'borrower',
	DONOR: 'donor',
	INTERMEDIARY: 'entity',
	PIU: 'entity',
	SUB_PIU: 'subPiu',
	SUPPLIER: 'supplier',
	TAX_AUTHORITY: 'tax',
};

export const entityIconSubtypes: Record<EntitySubtype, IconCircleProps['type']> = {
	INDIVIDUAL: 'individual',
	ORGANIZATION: 'organization',
};

export const transactionIconTypes: Record<TransactionType, IconCircleProps['type']> = {
	CASH_BENEFIT: 'cashBenefit',
	INITIAL: 'payment',
	NON_CASH_BENEFIT: 'nonCashBenefit',
	OPEX: 'opex',
	PAYMENT: 'payment',
	REPLENISHMENT: 'replenishment',
	TRANSFER: 'transfer',
};

export const financierIconTypes: Record<Financier, IconCircleProps['type']> = {
	COFN: 'loan',
	IBRD: 'loan',
	IDA: 'loan',
	GOV: 'government',
	TF: 'loan',
	OTH: 'other',
};

// props
export const iconCircleColors = [
	'blue',
	'green',
	'grey',
	'magenta',
	'orange',
	'purple',
	'teal',
	'yellow',
] as const;
export const iconCircleSizes = ['xs', 'sm', 'md', 'lg'] as const;
export const iconCircleTypes = [
	'account',
	'auditor',
	'benefit',
	'beneficiary',
	'borrower',
	'cashBenefit',
	'check',
	'comment',
	'contract',
	'nonCashBenefit',
	'default',
	'description',
	'document',
	'documentType',
	'donor',
	'edit',
	'entity',
	'expenseType',
	'form',
	'government',
	'individual',
	'loan',
	'local',
	'other',
	'opex',
	'organization',
	'payment',
	'project',
	'replenishment',
	'search',
	'subPiu',
	'supplier',
	'tax',
	'transfer',
	'upload',
	'user',
] as const;

export type IconCircleColor = (typeof iconCircleColors)[number];
export type IconCircleSize = (typeof iconCircleSizes)[number];
export type IconCircleType = (typeof iconCircleTypes)[number];

export interface IconCircleVariant {
	color: IconCircleColor;
	icon: ReactNode;
}

export interface IconCircleProps {
	color?: IconCircleColor;
	icon?: ReactNode;
	size?: IconCircleSize;
	type?: IconCircleType;
}

export const IconCircle = ({ color, icon, size = 'md', type = 'default' }: IconCircleProps) => {
	// hooks
	const styles: ClassNameMap = useStyles();

	// vars
	const classes = [styles.circle, styles[size]];

	const customSvgSize: Record<string, number> = {
		xs: 16,
		sm: 20,
		md: 20,
		lg: 30,
	};

	const types: Record<string, IconCircleVariant> = {
		account: {
			color: 'blue',
			icon: <LogoWorldBankMark style={{ width: customSvgSize[size] }} />,
		},
		auditor: {
			color: 'grey',
			icon: <IconPageview />,
		},
		benefit: {
			color: 'blue',
			icon: <IconVolunteerActivism />,
		},
		beneficiary: {
			color: 'magenta',
			icon: <IconPerson />,
		},
		borrower: {
			color: 'grey',
			icon: <IconAccountBalance />,
		},
		cashBenefit: {
			color: 'blue',
			icon: <IconPaid />,
		},
		check: {
			color: 'green',
			icon: <IconCheck />,
		},
		comment: {
			color: 'blue',
			icon: <IconComment />,
		},
		contract: {
			color: 'yellow',
			icon: <IconAssignment />,
		},
		nonCashBenefit: {
			color: 'blue',
			icon: <IconVolunteerActivism />,
		},
		default: {
			color: 'grey',
			icon: <IconInfo />,
		},
		description: {
			color: 'blue',
			icon: <IconDescription />,
		},
		document: {
			color: 'green',
			icon: <IconInsertDriveFile />,
		},
		documentType: {
			color: 'green',
			icon: <IconInsertDriveFile />,
		},
		donor: {
			color: 'grey',
			icon: <IconAddchart />,
		},
		edit: {
			color: 'grey',
			icon: <IconCreate />,
		},
		entity: {
			color: 'magenta',
			icon: <IconAccountBalance />,
		},
		expenseType: {
			color: 'blue',
			icon: <IconCategory />,
		},
		form: {
			color: 'magenta',
			icon: <IconList />,
		},
		government: {
			color: 'grey',
			icon: <IconAccountBalance />,
		},
		individual: {
			color: 'magenta',
			icon: <IconPerson />,
		},
		loan: {
			color: 'teal',
			icon: <IconPayments />,
		},
		local: {
			color: 'blue',
			icon: <IconFlag />,
		},
		opex: {
			color: 'blue',
			icon: <IconLocalAtm />,
		},
		organization: {
			color: 'magenta',
			icon: <IconPeople />,
		},
		other: {
			color: 'grey',
			icon: <IconPublic />,
		},
		payment: {
			color: 'blue',
			icon: <IconPaid />,
		},
		project: {
			color: 'purple',
			icon: <IconFolder />,
		},
		replenishment: {
			color: 'blue',
			icon: <IconReplenishment />,
		},
		search: {
			color: 'grey',
			icon: <IconSearch />,
		},
		subPiu: {
			color: 'magenta',
			icon: <IconCorporateFare />,
		},
		supplier: {
			color: 'magenta',
			icon: <IconStore />,
		},
		tax: {
			color: 'grey',
			icon: <IconCalculate />,
		},
		transfer: {
			color: 'blue',
			icon: <IconTransfer />,
		},
		upload: {
			color: 'grey',
			icon: <IconCloudUpload />,
		},
		user: {
			color: 'orange',
			icon: <IconPerson />,
		},
	};

	if (color) {
		classes.push(styles[color]);
	} else {
		classes.push(types[type] ? styles[types[type].color] : styles.grey);
	}

	return <div className={classes.join(' ')}>{icon || types[type].icon}</div>;
};
