// i18n
import i18n from 'i18next';

interface TimeUnits {
	year: number;
	month: number;
	day: number;
	hour: number;
	minute: number;
	second: number;
}

type TimeUnit = keyof TimeUnits;

export const toRelativeTime = (d1: Date, lang = i18n.language, d2 = new Date()) => {
	const units: TimeUnits = {
		year: 24 * 60 * 60 * 1000 * 365,
		month: (24 * 60 * 60 * 1000 * 365) / 12,
		day: 24 * 60 * 60 * 1000,
		hour: 60 * 60 * 1000,
		minute: 60 * 1000,
		second: 1000,
	};

	const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });

	const elapsed = d1.getTime() - d2.getTime();

	for (const u in units) {
		const ms = units[u as TimeUnit];
		if (Math.abs(elapsed) > ms || u === 'second') {
			return rtf.format(Math.round(elapsed / ms), u as Intl.RelativeTimeFormatUnit);
		}
	}
};

export interface FormatDateParams {
	date: Date;
	format?: 'YYYY-MM-DD';
	lang?: string;
}

export const formatDate = ({ date, format, lang = i18n.language }: FormatDateParams) => {
	if (!date) {
		return '';
	}

	if (format === 'YYYY-MM-DD') {
		return date.toLocaleDateString('en-CA', {
			timeZone: 'UTC',
		});
	}

	return date.toLocaleDateString(lang, {
		day: 'numeric',
		month: 'short',
		timeZone: 'UTC',
		year: 'numeric',
	});
};

export const toUTC = (date: Date | string) => {
	const d = new Date(date);
	return new Date(
		Date.UTC(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds()
		)
	);
};
