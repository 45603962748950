// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Account, ActionButton } from '../../../@types';

// router
import { Outlet, Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject } from '../Project/Project.context';
import { ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Grid } from '@mui/material';

// pages
import { Details } from './Details/Details';

// ui
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Empty } from '../../ui/Empty/Empty';
import { Loading } from '../../ui/Loading/Loading';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Title } from '../../ui/Title/Title';

// dialogs
import { DialogAddAccountBalance } from '../../dialogs/DialogAddAccountBalance/DialogAddAccountBalance';
import { DialogEditAccount } from '../../dialogs/DialogEditAccount/DialogEditAccount';
import { DialogUpdateAccountStatus } from '../../dialogs/DialogUpdateAccountStatus/DialogUpdateAccountStatus';

export const PageAccount = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { id, idAccount } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogAccount = searchParams.get('dialogAccount');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [account, setAccount] = useState<Account | null>(null);
	const [tabValue, setTabValue] = useState('activity');

	// fetch account
	const { isLoading: isLoadingAccount, refetch: refetchAccounts } = useFetch({
		url: `${process.env.REACT_APP_API_URL}/accounts/${idAccount}?populate=entities,loans`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setAccount(res.data[0]);
			}
		},
	});

	// handlers
	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	const handleCloseDialog = () => {
		searchParams.delete('dialog');
		searchParams.delete('dialogAccount');
		setSearchParams(searchParams);
	};

	const tabs = [
		{
			label: t('activity'),
			value: 'activity',
		},
	];

	const titleActions: ActionButton[] = [];

	if (account && (user?.permissions.account.update || user?.permissions.transaction.create)) {
		const options: ActionButton[] = [];

		if (user?.permissions.transaction.create) {
			options.push({
				children: t('addToBalance'),
				onClick: () => {
					searchParams.set('dialog', 'addAccountBalance');
					searchParams.set('dialogAccount', String(account.id));
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		}

		if (user?.permissions.account.update) {
			options.push(
				{
					children: t('edit'),
					onClick: () => {
						searchParams.set('dialog', 'editAccount');
						searchParams.set('dialogAccount', String(account.id));
						setSearchParams(searchParams);
					},
					variant: 'contained',
				},
				{
					children: account.status === 'DEACTIVATED' ? t('activate') : t('deactivate'),
					onClick: () => {
						searchParams.set('dialog', 'updateAccountStatus');
						searchParams.set('dialogAccount', String(account.id));
						setSearchParams(searchParams);
					},
					variant: 'contained',
				}
			);
		}

		if (options.length) {
			titleActions.push({
				options,
			});
		}
	}

	const renderContent = () => {
		if (isLoadingAccount) {
			return <Loading variant="page" />;
		}

		if (account) {
			return (
				<>
					<Grid container spacing={2}>
						<Grid item xs={11} lg={12}>
							<Breadcrumbs
								crumbs={[
									{
										children: t('projects'),
										to: '/projects',
									},
									{
										children: project?.idWbg,
										to: `/project/${id}`,
									},
									{
										children: t('accounts'),
										to: `/project/${id}/accounts`,
									},
								]}
								page={account.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Title
								actions={titleActions}
								ChipStatusProps={{
									status: account.status,
								}}
								color="blue"
								overline={account.type === 'LOCAL' ? t('localAccount') : t('designatedAccount')}
								title={account.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Details account={account} />
						</Grid>
						<Grid item xs={12}>
							<Tabs onChange={handleChangeTab} tabs={tabs} value={tabValue} hasBorder={false} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
					<DialogAddAccountBalance
						account={account}
						isOpen={
							paramDialog === 'addAccountBalance' && paramDialogAccount === String(account.id)
						}
						onClose={handleCloseDialog}
						onSuccess={() => {
							refetchAccounts();
						}}
					/>
					<DialogEditAccount
						isOpen={paramDialog === 'editAccount' && paramDialogAccount === String(account.id)}
						onClose={handleCloseDialog}
						onSuccess={() => {
							refetchAccounts();
						}}
					/>
					<DialogUpdateAccountStatus
						account={account}
						isOpen={
							paramDialog === 'updateAccountStatus' && paramDialogAccount === String(account.id)
						}
						onClose={handleCloseDialog}
						onSuccess={() => {
							refetchAccounts();
						}}
					/>
				</>
			);
		}

		return (
			<Empty
				actions={[
					{
						children: t('accounts'),
						component: RouterLink,
						to: `/project/${project?.id}/accounts`,
						variant: 'contained',
					},
				]}
				content={{ children: t('empty.account.content') }}
				title={{ children: t('empty.account.title') }}
				IconCircleProps={{ type: 'account' }}
				variant="page"
			/>
		);
	};

	return <>{renderContent()}</>;
};
