// react
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

// types
import { Account, AccountEntityPayload, AccountPayload, Entity, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextBanner, ContextUser } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';

// ui
import { Loading } from '../../ui/Loading/Loading';

// utils
import { translateType } from '../../../utils/translations';

// props
export interface FormEditAccountProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormEditAccount = ({ onClose, onSuccess, setIsLoading }: FormEditAccountProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [account, setAccount] = useState<Account | null>(null);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [searchParams] = useSearchParams();
	const [values, setValues] = useState({
		entities: [] as string[],
		institution: '',
		name: '',
		num: '',
	});

	// params
	const id = searchParams.get('dialogAccount');

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// vars
	const idForm = 'form-edit-account';
	const owner = account?.entities.find((acc) => acc.relationship === 'OWNER');
	const userEntity = user?.entity as Entity;

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch account
	const { isLoading: isLoadingAccount } = useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/accounts/${id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data && res.data.length) {
				setAccount(res.data[0]);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type,wallet&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU&populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	const optionsEntities = entities
		.filter((entity) => entity.id !== userEntity?.id && entity.id !== Number(owner?.entity))
		.map((entity) => {
			return {
				disabled: entity.wallet?.status === 'INACTIVE',
				label: entity.name,
				secondary: translateType(entity.type, t),
				value: String(entity.id),
			};
		})
		.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (!project) {
				throw new Error('The account needs to have a project ID associated with it.', {
					cause: {
						id: 'ACCOUNT_PROJECT_NON_EXISTENT',
					},
				});
			}

			const body: AccountPayload = {
				entities: [],
				institution: String(entries.institution),
				name: String(entries.name),
				num: String(entries.num),
				project: project.id,
			};

			if (entries.entities) {
				const entitiesStr = entries.entities as string;
				const entities: AccountEntityPayload[] = entitiesStr.split(',').map((id) => {
					return {
						id: Number(id),
						relationship: 'ASSOCIATE',
					};
				});

				body.entities = entities;
			}

			const fetchEditAccount = await fetch(`${process.env.REACT_APP_API_URL}/accounts/${id}`, {
				method: 'PATCH',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resEditAccount = await fetchEditAccount.json();

			if (resEditAccount.error) {
				throw new Error(resEditAccount.error.message, {
					cause: {
						id: resEditAccount.error.id,
						details: resEditAccount.error?.details,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountUpdated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ACCOUNT_ID_NON_EXISTENT') {
					message = t('error.accountIdNonExistent');
				}
				if (cause.id === 'ACCOUNT_INVALID_ENTITY_TRANSACTIONS') {
					const entity = entities.find((entity) => entity.id === cause.details?.entity);
					message = t('error.accountEntityTransactions', { entity: entity?.name });
				}
				if (cause.id === 'ACCOUNT_NON_EXISTENT') {
					message = t('error.accountNonExistent', { id: account?.id });
				}
				if (cause.id === 'ACCOUNT_PROJECT_NON_EXISTENT') {
					message = t('error.accountProjectNonExistent', { id: account?.id });
				}
				if (cause.id === 'ACCOUNT_UNAUTHORIZED_PATCH') {
					message = t('error.unauthorized');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	const handleChangeValue = (e: ChangeEvent | SelectChangeEvent<unknown>) => {
		const { name, value } = e.target as HTMLInputElement;
		setValues({
			...values,
			[name]: value,
		});
	};

	// content
	const renderContent = () => {
		if (isLoadingAccount) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						onChange={handleChangeValue}
						required={true}
						value={values.name}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-number`}
						label={t('accountNumber')}
						name="num"
						onChange={handleChangeValue}
						value={values.num}
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-financial-institution`}
						label={t('financialInstitution')}
						name="institution"
						onChange={handleChangeValue}
						value={values.institution}
					/>
				</Grid>
				{optionsEntities.length > 0 && (
					<Grid item xs={12}>
						<Select
							hasAllOption={false}
							hasNoneOption={false}
							helperText={t('additionalEntities.helper')}
							id={`${idForm}-entities`}
							label={t('additionalEntities')}
							onChange={handleChangeValue}
							multiple={true}
							name="entities"
							options={optionsEntities}
							value={values.entities}
						/>
					</Grid>
				)}
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</>
		);
	};

	// set default values when account changes
	useEffect(() => {
		if (account) {
			const defaultEntities = account?.entities
				?.filter((item) => item.relationship === 'ASSOCIATE')
				.map((item) => String(item.entity));

			setValues({
				entities: defaultEntities,
				institution: account.institution,
				name: account.name,
				num: account.num,
			});
		}
	}, [account]);

	return (
		<form id={`${idForm}`} name="formEditAccount" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</form>
	);
};
