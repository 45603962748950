// react
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';

// types
import { AccountPayload, ErrorCause, DocumentType } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// ui
import { Loading } from '../../ui/Loading/Loading';

// props
export interface FormEditDocumentTypeProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormEditDocumentType = ({
	onClose,
	onSuccess,
	setIsLoading,
}: FormEditDocumentTypeProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);
	const [documentType, setDocumentType] = useState<DocumentType | null>(null);
	const [searchParams] = useSearchParams();
	const [values, setValues] = useState({
		name: '',
	});

	// params
	const paramDocumentType = searchParams.get('dialogDocumentType');

	// context
	const { setBanner } = useContext(ContextBanner);

	// vars
	const idForm = 'form-edit-document-type';

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch document type
	const { isLoading: isLoadingDocumentType } = useFetch({
		url: `${process.env.REACT_APP_API_URL}/document-types/${paramDocumentType}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data && res.data.length) {
				setDocumentType(res.data[0]);
			}
		},
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (!paramDocumentType) {
				throw new Error('An ID is required', {
					cause: {
						id: 'ID_NON_EXISTENT',
					},
				});
			}

			const body: AccountPayload = {
				name: String(entries.name),
			};

			const fetchEditDocumentType = await fetch(
				`${process.env.REACT_APP_API_URL}/document-types/${paramDocumentType}`,
				{
					method: 'PATCH',
					body: JSON.stringify(body),
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				}
			);
			const resEditDocumentType = await fetchEditDocumentType.json();

			if (resEditDocumentType.error) {
				throw new Error(resEditDocumentType.error.message, {
					cause: {
						id: resEditDocumentType.error.id,
						details: resEditDocumentType.error?.details,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.documentTypeUpdated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'DOCUMENT_TYPE_NON_EXISTENT') {
					message = t('error.documentTypeNonExistent', { id: documentType?.id });
				}

				if (cause.id === 'ID_NON_EXISTENT') {
					message = t('error.idNonExistent');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	const handleChangeValue = (e: ChangeEvent | SelectChangeEvent<unknown>) => {
		const { name, value } = e.target as HTMLInputElement;
		setValues({
			...values,
			[name]: value,
		});
	};

	// content
	const renderContent = () => {
		if (isLoadingDocumentType) {
			return (
				<Grid item xs={12}>
					<Grid alignItems="center" container justifyContent="center">
						<Grid item>
							<Loading />
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						onChange={handleChangeValue}
						required={true}
						value={values.name}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</>
		);
	};

	// set default values when document type changes
	useEffect(() => {
		if (documentType) {
			setValues({
				name: documentType.name,
			});
		}
	}, [documentType]);

	return (
		<form id={`${idForm}`} name="formEditDocumentType" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				{renderContent()}
			</Grid>
		</form>
	);
};
