// material
import { Button, Chip, ChipProps, Container, Grid, PaletteBrand, Typography } from '@mui/material';

// types
import { ActionButton } from '../../../@types';

// ui
import { BtnMenu } from '../BtnMenu/BtnMenu';
import { BtnSplit } from '../BtnSplit/BtnSplit';
import { ChipStatus, ChipStatusProps } from '../ChipStatus/ChipStatus';
import { MenuItemProps } from '../Menu/Menu';

// props
export interface TitleProps {
	actions?: ActionButton[];
	ChipProps?: ChipProps;
	ChipStatusProps?: ChipStatusProps;
	color?: keyof PaletteBrand;
	id?: string;
	overline?: string;
	title: string | undefined;
}

export const Title = ({
	actions = [],
	ChipProps,
	ChipStatusProps,
	color = 'grey',
	id,
	overline,
	title,
}: TitleProps) => {
	const renderChip = () => {
		if (ChipProps) {
			return <Chip {...ChipProps} />;
		} else if (ChipStatusProps) {
			return <ChipStatus {...ChipStatusProps} />;
		}
	};

	// define menu items
	const btnMenuItems: MenuItemProps[] = [];

	for (let i = 0; i < actions.length; i++) {
		const options = actions[i].options || [];

		if (options.length) {
			for (let o = 0; o < options.length; o++) {
				btnMenuItems.push({
					children: options[o].children,
					onClick: options[o].onClick,
				});
			}
		} else {
			btnMenuItems.push({
				children: actions[i].children,
				onClick: actions[i].onClick,
			});
		}
	}

	return (
		<Container maxWidth={false}>
			<Grid alignItems="center" container justifyContent={{ md: 'space-between' }} spacing={2}>
				<Grid item xs={12} md={true}>
					<Grid container spacing={1}>
						{overline && (
							<Grid item xs={12}>
								<Typography
									component="div"
									sx={(theme) => {
										return {
											color: theme.palette.brand[color as keyof PaletteBrand][500],
										};
									}}
									variant="overline">
									{overline}
								</Typography>
							</Grid>
						)}

						<Grid item xs={12}>
							<Grid
								alignItems="center"
								container
								justifyContent={{ lg: 'space-between' }}
								spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h1">{title}</Typography>
								</Grid>
								{(id || ChipProps || ChipStatusProps) && (
									<Grid item xs={12}>
										<Grid alignItems="center" container spacing={2}>
											{id && (
												<Grid item xs="auto">
													<Typography
														fontWeight={600}
														sx={(theme) => {
															return {
																color: theme.palette.brand[color as keyof PaletteBrand][500],
															};
														}}
														variant="subtitle1">
														{id}
													</Typography>
												</Grid>
											)}
											{(ChipProps || ChipStatusProps) && (
												<Grid item xs="auto">
													{renderChip()}
												</Grid>
											)}
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{actions.length > 0 && (
					<Grid
						item
						xs={12}
						md="auto"
						sx={(theme) => {
							return {
								display: {
									xs: 'none',
									lg: 'inline-flex',
								},
							};
						}}>
						<Grid container justifyContent="flex-end" spacing={2}>
							{actions.map((action, i) => {
								const { children, options, ...btnProps } = action;

								return (
									<Grid item key={`title-action-${i}`} xs={12} md="auto">
										{options ? (
											<BtnSplit ButtonGroupProps={{ fullWidth: true }} actions={options} />
										) : (
											<Button {...btnProps} fullWidth>
												{children}
											</Button>
										)}
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				)}
				{btnMenuItems.length > 0 && (
					<Grid
						item
						xs="auto"
						sx={(theme) => {
							return {
								display: {
									lg: 'none',
								},
								position: 'absolute',
								top: theme.spacing(2),
								right: theme.spacing(2),
							};
						}}>
						<BtnMenu items={btnMenuItems} />
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
