// react
import { MouseEvent, ReactNode, useEffect, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useParams, useSearchParams } from 'react-router-dom';

// types
import { ErrorCause, Form, FormField, FormFieldType, Option } from '../../../../../@types';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// mui
import {
	Alert,
	Box,
	BoxProps,
	Button,
	Chip,
	Container,
	Grid,
	Paper,
	SxProps,
	Typography,
} from '@mui/material';

// fields
import { Checkbox } from '../../../../fields/Checkbox/Checkbox';
import { Input, InputProps } from '../../../../fields/Input/Input';
import { Select } from '../../../../fields/Select/Select';
import { Switch } from '../../../../fields/Switch/Switch';

// ui
import { Empty } from '../../../../ui/Empty/Empty';

// icons
import { DeleteOutlined as IconDelete } from '@mui/icons-material';

// utils
import { toCamelCase } from '../../../../../utils/strings';
import { translateFormName } from '../../../../../utils/translations';
import { DialogUpdateForm } from '../../../../dialogs/DialogUpdateForm/DialogUpdateForm';

export const FormBuilder = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();
	const { idForm } = useParams();

	// defaults
	const fieldDefault: FormField = {
		helper: '',
		isEditable: true,
		isProtected: true,
		isRequired: true,
		label: '',
		name: '',
		type: 'TEXT',
	};

	// state
	const [errorAddField, setErrorAddField] = useState<string | null>(null);
	const [errorEditField, setErrorEditField] = useState<string | null>(null);
	const [fieldAdd, setFieldAdd] = useState<FormField>(fieldDefault);
	const [fieldEdit, setFieldEdit] = useState<FormField | null>(null);
	const [fields, setFields] = useState<FormField[]>([]);
	const [form, setForm] = useState<Form | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');

	// fetch form
	const { fetchRequest: fetchForm, isLoading: isLoadingForm } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/forms/${idForm}?populate=user`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				const fieldsWithIds = res.data[0].fields.map((field: FormField) => {
					return {
						...field,
						uuid: crypto.randomUUID(),
					};
				});

				setFields(fieldsWithIds);
				setForm(res.data[0]);
			}
		},
	});

	// vars
	const idFormAddField = `form-builder-add-field`;
	const idFormEditField = `form-builder-edit-field`;

	// options
	const optionsFieldType = [
		{
			label: t('checkbox'),
			value: 'CHECKBOX',
		},
		{
			label: t('date'),
			value: 'DATE',
		},
		{
			label: t('number'),
			value: 'NUMBER',
		},
		{
			label: t('select'),
			value: 'SELECT',
		},
		{
			label: t('text'),
			value: 'TEXT',
		},
		{
			label: t('time'),
			value: 'TIME',
		},
		{
			label: 'URL',
			value: 'URL',
		},
	];

	const optionEmpty = {
		label: '',
		value: '',
	};

	const handleSubmitAddField = (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		try {
			// clear error
			setErrorAddField(null);

			const form = document.querySelector(`#${idFormAddField}`) as HTMLFormElement;
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());

			// check field label already exists
			if (fields.map((field) => field.label).includes(entries['label'] as string)) {
				throw new Error('A field with label already exists', {
					cause: {
						id: 'FORM_FIELD_LABEL_DUPLICATE',
						details: {
							label: entries['label'],
						},
					},
				});
			}

			const fieldsNew = [...fields];

			const label = entries['label'] as string;
			const name = toCamelCase(label);

			const field: FormField = {
				helper: entries['helper'] as string,
				isEditable: true,
				isProtected: true,
				isRequired: Boolean(entries['isRequired']),
				label,
				name,
				type: entries['type'] as FormFieldType,
				uuid: crypto.randomUUID(),
			};

			// add field options
			if (entries['type'] === 'SELECT') {
				field.options = Object.keys(entries)
					.filter((key) => key.includes('option'))
					.map((key) => {
						return {
							label: entries[key] as string,
							value: entries[key] as string,
						};
					});
			}

			// add field
			fieldsNew.push(field);

			// set fields
			setFields(fieldsNew);

			// reset field add
			setFieldAdd(fieldDefault);
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'FORM_FIELD_LABEL_DUPLICATE') {
					message = t('error.formFieldLabelDuplicate', { label: cause.details.label });
				}
			}

			// set error
			setErrorAddField(message);
		}
	};

	const handleSubmitEditField = (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();
	};

	interface HandleChangeFieldParams {
		key: keyof FormField;
		value: boolean | FormFieldType | number | Option[] | string;
	}

	const handleChangeFieldAdd = ({ key, value }: HandleChangeFieldParams) => {
		const fieldAddNew = structuredClone(fieldAdd);

		if (fieldAddNew) {
			fieldAddNew[key] = value;

			if (key === 'type') {
				if (value === 'SELECT') {
					fieldAddNew.options = [optionEmpty];
				} else {
					delete fieldAddNew.options;
				}
			}

			setFieldAdd(fieldAddNew);
		}
	};

	const handleChangeFieldEdit = ({ key, value }: HandleChangeFieldParams) => {
		try {
			// clear error
			setErrorEditField(null);

			if (fieldEdit) {
				// clone field edit
				const fieldEditNew = structuredClone(fieldEdit);

				if (fieldEditNew) {
					// define field key and value
					fieldEditNew[key] = value;

					// update dependent properties of field
					if (key === 'label') {
						// check field label already exists
						if (
							fields
								.filter((field) => !(field.label === fieldEdit[key]))
								.map((field) => field.label)
								.includes(value as string)
						) {
							throw new Error('A field name already exists.', {
								cause: {
									id: 'FORM_FIELD_LABEL_DUPLICATE',
									details: {
										label: value,
									},
								},
							});
						}
						fieldEditNew.name = toCamelCase(value as string);
					} else if (key === 'type') {
						if (value === 'SELECT') {
							fieldEditNew.options = [optionEmpty];
						} else {
							delete fieldEditNew.options;
						}
					}
					setFieldEdit(fieldEditNew);
				}

				// clone fields
				const fieldsNew = structuredClone(fields);

				// find field to update
				const fieldToUpdate = fieldsNew.find((field) => field.uuid === fieldEdit.uuid);

				if (fieldToUpdate) {
					// define field key and value
					fieldToUpdate[key] = value;

					// update dependent properties of field
					if (key === 'label') {
						fieldToUpdate.name = toCamelCase(value as string);
					} else if (key === 'type') {
						if (value === 'SELECT') {
							fieldToUpdate.options = [optionEmpty];
						} else {
							delete fieldToUpdate.options;
						}
					}
					setFields(fieldsNew);
				}
			}
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'FORM_FIELD_LABEL_DUPLICATE') {
					message = t('error.formFieldLabelDuplicate', { label: cause.details.label });
				}
			}

			// set error
			setErrorEditField(message);
		}
	};

	const fieldsOptions = fieldAdd.options?.map((option, i) => {
		const inputProps: InputProps = {};

		const isFirst = i === 0;
		const isLast = fieldAdd.options ? i === fieldAdd.options.length - 1 : false;

		if (isFirst) {
			inputProps.label = t('options');
			inputProps.required = true;
		}

		return (
			<Grid item key={`${idFormAddField}-option-${i}`} xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<Input
							{...inputProps}
							id={`${idFormAddField}-option-${i}`}
							name={`option-${i}`}
							placeholder={t('formBuilder.option.placeholder')}
						/>
					</Grid>
					{!isFirst && (
						<Grid item xs="auto">
							<Button
								aria-label={t('delete')}
								className="icon"
								color="error"
								disabled={i === 0}
								fullWidth={true}
								onClick={(e) => {
									// clone options
									const optionsNew = structuredClone(fieldAdd.options);

									if (optionsNew) {
										// remove option by index
										optionsNew.splice(i, 1);

										// set field options
										handleChangeFieldAdd({
											key: 'options',
											value: optionsNew,
										});
									}
								}}
								sx={{
									minWidth: 0,
								}}
								variant="outlined">
								<IconDelete />
							</Button>
						</Grid>
					)}
				</Grid>

				{isLast && (
					<Button
						onClick={() => {
							// clone options
							const optionsNew = structuredClone(fieldAdd.options);

							if (optionsNew) {
								optionsNew.push(optionEmpty);

								// set field options
								handleChangeFieldAdd({
									key: 'options',
									value: optionsNew,
								});
							}
						}}
						sx={(theme) => {
							return {
								marginTop: theme.spacing(1),
							};
						}}>
						{t('addOption')} +
					</Button>
				)}
			</Grid>
		);
	});

	const renderField = (field: FormField) => {
		const boxProps: BoxProps = {};
		const isActive = field.uuid === fieldEdit?.uuid;

		let component: ReactNode;

		if (field.type === 'CHECKBOX') {
			component = (
				<Checkbox
					disabled={!field.isEditable}
					disableRipple={true}
					label={field.label}
					required={field.isRequired}
				/>
			);
		}

		if (['DATE', 'EMAIL', 'NUMBER', 'TEXT', 'TIME', 'URL'].includes(field.type)) {
			let type = 'text';

			if (field.type === 'DATE') {
				type = 'date';
			} else if (field.type === 'EMAIL') {
				type = 'email';
			} else if (field.type === 'NUMBER') {
				type = 'number';
			} else if (field.type === 'TIME') {
				type = 'time';
			} else if (field.type === 'URL') {
				type = 'url';
			}

			component = (
				<Input
					disabled={!field.isEditable}
					helperText={field.helper}
					label={field.label}
					required={field.isRequired}
					type={type}
				/>
			);
		}

		if (field.type === 'SELECT') {
			component = (
				<Select
					disabled={!field.isEditable}
					hasNoneOption={false}
					helperText={field.helper}
					label={field.label}
					options={field.options || []}
					required={field.isRequired}
				/>
			);
		}

		if (field.isEditable) {
			boxProps.onClick = () => {
				//console.log('EDIT FIELD', field);
				setFieldEdit(field);
			};
		}

		if (component) {
			return (
				<Box
					{...boxProps}
					sx={(theme) => {
						let sx: SxProps = {
							transition: `all 200ms`,
							borderColor: theme.palette.brand.grey[200],
							borderStyle: 'solid',
							borderWidth: 1,
							borderRadius: 2,
							padding: theme.spacing(1),
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						};

						if (field.type === 'CHECKBOX') {
							sx = {
								...sx,
								justifyContent: 'flex-end',
							};
						}

						if (field.isEditable) {
							sx = {
								...sx,
								cursor: 'pointer',
							};
						} else {
							sx = {
								...sx,
								backgroundColor: theme.palette.brand.grey[200],
							};
						}

						if (isActive) {
							sx = {
								...sx,
								backgroundColor: theme.palette.brand.grey[100],
								borderColor: theme.palette.brand.blue[500],
								padding: theme.spacing(1),
							};
						}

						return sx;
					}}>
					{component}
				</Box>
			);
		}

		return <></>;
	};

	// fetch form when component mounts
	useEffect(() => {
		fetchForm();
	}, [fetchForm]);

	return (
		<>
			<Container maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={3}>
						<Paper
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
									height: '100%',
								};
							}}>
							<form id={idFormAddField} onSubmit={handleSubmitAddField}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h3">{t('addField')}</Typography>
									</Grid>
									<Grid item xs={12}>
										<Input
											id={`${idFormAddField}-label`}
											label={t('label')}
											name="label"
											onChange={(e) => {
												handleChangeFieldAdd({
													key: 'label',
													value: e.target.value,
												});
											}}
											required={true}
											value={fieldAdd.label}
										/>
									</Grid>
									<Grid item xs={12}>
										<Select
											defaultValue="TEXT"
											hasNoneOption={false}
											id={`${idFormAddField}-type`}
											label={t('type')}
											name="type"
											onChange={(e) => {
												handleChangeFieldAdd({
													key: 'type',
													value: e.target.value as FormFieldType,
												});
											}}
											options={optionsFieldType}
											required={true}
											value={fieldAdd.type}
										/>
									</Grid>
									{fieldAdd.type === 'SELECT' && fieldsOptions}
									<Grid item xs={12}>
										<Input
											helperText={t('formBuilder.helper.helper')}
											id={`${idFormAddField}-helper`}
											label={t('helperText')}
											name="helper"
											onChange={(e) => {
												handleChangeFieldAdd({
													key: 'helper',
													value: e.target.value,
												});
											}}
											value={fieldAdd.helper}
										/>
									</Grid>
									<Grid item xs={12}>
										<Switch
											checked={fieldAdd.isRequired}
											id={`${idFormAddField}-required`}
											label={t('required')}
											name="isRequired"
											onChange={(e) => {
												handleChangeFieldAdd({
													key: 'isRequired',
													value: e.target.checked,
												});
											}}
											size="small"
										/>
									</Grid>
									{errorAddField && (
										<Grid item xs={12}>
											<Alert severity="error">{errorAddField}</Alert>
										</Grid>
									)}
									<Grid item xs={12}>
										<Button fullWidth={true} type="submit" variant="outlined">
											{t('addField')}
										</Button>
									</Grid>
								</Grid>
							</form>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Paper
							sx={(theme) => {
								let sx: SxProps = {
									position: 'relative',
									padding: theme.spacing(2),
									height: '100%',
								};

								if (!fields.length) {
									sx = {
										...sx,
										minHeight: 400,
									};
								}
								return sx;
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid container justifyContent="space-between" spacing={2}>
										<Grid item>
											<Typography variant="h3">
												{form ? translateFormName(form.name, t) : t('loading')}
											</Typography>
										</Grid>
										<Grid item>
											<Chip color="info" label={t('preview')} size="small" variant="twoTone" />
										</Grid>
									</Grid>
								</Grid>
								{fields.length ? (
									<>
										{fields.map((field: FormField, i: number) => {
											return (
												<Grid item key={`field-${i}`} xs={12} lg={6}>
													{renderField(field)}
												</Grid>
											);
										})}
									</>
								) : (
									<Empty
										content={{
											children: t('empty.formBuilder.add.content'),
										}}
										IconCircleProps={{ color: 'grey', type: 'form' }}
										isLoading={isLoadingForm}
										title={{ children: t('empty.formBuilder.add.title') }}
										position="absolute"
									/>
								)}
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12} lg={3}>
						<Paper
							sx={(theme) => {
								let sx: SxProps = {
									position: 'relative',
									padding: theme.spacing(2),
									height: '100%',
								};

								if (!fieldEdit) {
									sx = {
										...sx,
										minHeight: 400,
									};
								}
								return sx;
							}}>
							<form id={idFormEditField} onSubmit={handleSubmitEditField}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<Typography variant="h3">{t('editField')}</Typography>
									</Grid>
									{fieldEdit ? (
										<>
											<Grid item xs={12}>
												<Input
													id={`${idFormEditField}-label`}
													label={t('label')}
													name="label"
													onChange={(e) => {
														handleChangeFieldEdit({
															key: 'label',
															value: e.target.value,
														});
													}}
													required={true}
													value={fieldEdit.label}
												/>
											</Grid>
											<Grid item xs={12}>
												<Select
													defaultValue={fieldEdit.type}
													hasNoneOption={false}
													id={`${idFormEditField}-type`}
													label={t('type')}
													name="type"
													onChange={(e) => {
														handleChangeFieldEdit({
															key: 'type',
															value: e.target.value as FormFieldType,
														});
													}}
													options={optionsFieldType}
													required={true}
													value={fieldEdit.type}
												/>
											</Grid>
											{fieldEdit.type === 'SELECT' &&
												fieldEdit.options?.map((option, i) => {
													const inputProps: InputProps = {};

													const isFirst = i === 0;
													let isLast = false;

													if (fieldEdit.options) {
														isLast = i === fieldEdit.options.length - 1;
													}

													if (isFirst) {
														inputProps.label = t('options');
														inputProps.required = true;
													}

													return (
														<Grid item key={`${idFormEditField}-option-${i}`} xs={12}>
															<Grid container spacing={2}>
																<Grid item xs={true}>
																	<Input
																		id={`${idFormEditField}-option-${i}`}
																		name={`option-${i}`}
																		onChange={(e) => {
																			// clone options
																			const optionsNew = structuredClone(fieldEdit.options);

																			if (optionsNew) {
																				// redefine option
																				optionsNew[i] = {
																					label: e.target.value,
																					value: e.target.value,
																				};

																				// set field options
																				handleChangeFieldEdit({
																					key: 'options',
																					value: optionsNew,
																				});
																			}
																		}}
																		value={fieldEdit.options?.[i].value}
																	/>
																	{isLast && (
																		<Button
																			onClick={() => {
																				// clone options
																				const optionsNew = structuredClone(fieldEdit.options);

																				if (optionsNew) {
																					optionsNew.push(optionEmpty);

																					// set field options
																					handleChangeFieldEdit({
																						key: 'options',
																						value: optionsNew,
																					});
																				}
																			}}
																			sx={(theme) => {
																				return {
																					marginTop: theme.spacing(1),
																				};
																			}}>
																			{t('addOption')} +
																		</Button>
																	)}
																</Grid>
																{!isFirst && (
																	<Grid item xs="auto">
																		<Button
																			aria-label={t('delete')}
																			className="icon"
																			color="error"
																			disabled={i === 0}
																			fullWidth={true}
																			onClick={(e) => {
																				// clone options
																				const optionsNew = structuredClone(fieldEdit.options);

																				if (optionsNew) {
																					// remove option by index
																					optionsNew.splice(i, 1);

																					// set field options
																					handleChangeFieldEdit({
																						key: 'options',
																						value: optionsNew,
																					});
																				}
																			}}
																			sx={{
																				minWidth: 0,
																			}}
																			variant="outlined">
																			<IconDelete />
																		</Button>
																	</Grid>
																)}
															</Grid>
														</Grid>
													);
												})}
											<Grid item xs={12}>
												<Input
													helperText={t('formBuilder.helper.helper')}
													id={`${idFormEditField}-helper`}
													label={t('helperText')}
													name="helper"
													onChange={(e) => {
														handleChangeFieldEdit({
															key: 'helper',
															value: e.target.value,
														});
													}}
													value={fieldEdit.helper}
												/>
											</Grid>
											<Grid item xs={12}>
												<Switch
													checked={fieldEdit.isRequired}
													id={`${idFormEditField}-required`}
													label={t('required')}
													name="isRequired"
													onChange={(e) => {
														handleChangeFieldEdit({
															key: 'isRequired',
															value: e.target.checked,
														});
													}}
													size="small"
												/>
											</Grid>
											{errorEditField && (
												<Grid item xs={12}>
													<Alert severity="error">{errorEditField}</Alert>
												</Grid>
											)}
											<Grid item xs={12}>
												<Button
													color="error"
													fullWidth={true}
													onClick={() => {
														// filter out field
														let fieldsNew = structuredClone(fields);
														fieldsNew = fieldsNew.filter((f) => f.uuid !== fieldEdit.uuid);
														setFields(fieldsNew);

														// reset edit field
														setFieldEdit(null);
													}}
													variant="outlined">
													{t('removeField')}
												</Button>
											</Grid>
										</>
									) : (
										<Empty
											content={{ children: t('empty.formBuilder.edit.content') }}
											IconCircleProps={{ type: 'edit' }}
											title={{ children: t('empty.formBuilder.edit.title') }}
											position="absolute"
										/>
									)}
								</Grid>
							</form>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			{form && (
				<DialogUpdateForm
					isOpen={paramDialog === 'updateForm'}
					form={{
						...form,
						fields: structuredClone(fields).map((field) => {
							delete field.uuid;
							return field;
						}),
					}}
					onClose={() => {
						searchParams.delete('dialog');
						setSearchParams(searchParams);
					}}
				/>
			)}
		</>
	);
};
