// react
import { useEffect, useState } from 'react';

// types
import { Pagination, Transaction, TransactionMetadata, TransactionType } from '../../../../@types';

// router
import { useParams, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// mui
import {
	Container,
	Grid,
	Paper,
	TableCell,
	TableFooter as MuiTableFooter,
	TableRow,
	Typography,
} from '@mui/material';

// ui
import { IconCircle, transactionIconTypes } from '../../../ui/IconCircle/IconCircle';
import { Flag } from '../../../ui/Flag/Flag';
import { Table, TableHeader } from '../../../ui/Table/Table';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';
import { Time } from '../../../ui/Time/Time';

// utils
import { flagsByCurrency, formatCurrency } from '../../../../utils/currency';
import { defaultPagination } from '../../../../utils/defaults';
import { translateTransactionType } from '../../../../utils/translations';

export const Activity = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { idAccount } = useParams();
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();

	// params
	const paramOrder = searchParams.get('order') || 'DESC';
	const paramOrderBy = searchParams.get('orderBy') || 'date';
	const paramPage = Number(searchParams.get('page') || 1);

	// state
	const [pagination, setPagination] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [transactions, setTransactions] = useState<Transaction[]>([]);

	// fetch transactions
	const { fetchRequest: fetchTransactions, isLoading: isLoadingTransactions } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/transactions?account=${idAccount}&status=COMPLETED,IN_PROGRESS,WAITING&populate=fromEntity,toEntity&page=${paramPage}&limit=${pagination.perPage}&orderBy=${paramOrderBy}&order=${paramOrder}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTransactions(res.data);
			}

			if (res.pagination) {
				setPagination(res.pagination);
			}
		},
	});

	const currencies = [
		...new Set(
			transactions
				.filter((transaction) => transaction.currency)
				.map((transaction) => transaction.currency)
		),
	];

	const totalsbyCurrency = currencies.map((currency) => {
		const transactionsByCurrency = transactions.filter(
			(transaction) => transaction.currency === currency
		);

		const transactionsIn = transactionsByCurrency.filter(
			(transaction) => transaction.toAccount === Number(idAccount)
		);

		const transactionsOut = transactionsByCurrency.filter(
			(transaction) => transaction.fromAccount === Number(idAccount)
		);

		const totalIn = transactionsIn.reduce((total, transaction) => {
			return total + transaction.amount;
		}, 0);

		const totalOut = transactionsOut.reduce((total, transaction) => {
			return total + transaction.amount;
		}, 0);

		return {
			currency,
			inflow: {
				total: totalIn,
			},
			outflow: {
				total: totalOut,
			},
		};
	});

	const headers: TableHeader[] = [
		{
			label: t('type'),
			render: (type: TransactionType) => {
				return (
					<Grid alignItems="center" container spacing={1} wrap="nowrap">
						<Grid item>
							<IconCircle size="sm" type={transactionIconTypes[type]} />
						</Grid>
						<Grid flex={1} item>
							<Typography>{translateTransactionType(type, t)}</Typography>
						</Grid>
					</Grid>
				);
			},
			value: 'type',
		},
		{
			label: t('date'),
			isSortable: true,
			render: (date: string, header: TableHeader, transaction: Transaction) => {
				return <Time date={date} />;
			},
			sx: {
				minWidth: 160,
				width: 160,
			},
			value: 'date',
		},
		{
			label: t('inflow'),
			render: (amount: number, header: TableHeader, transaction: Transaction) => {
				return (
					<Typography>
						{transaction.toAccount === Number(idAccount)
							? formatCurrency({ currency: transaction.currency, value: amount })
							: '-'}
					</Typography>
				);
			},
			value: 'amount',
		},
		{
			label: t('outflow'),
			render: (amount: number, header: TableHeader, transaction: Transaction) => {
				return (
					<Typography>
						{transaction.fromAccount === Number(idAccount)
							? formatCurrency({ currency: transaction.currency, value: amount })
							: '-'}
					</Typography>
				);
			},
			value: 'amount',
		},
		{
			label: t('comments'),
			render: (metadata: TransactionMetadata) => {
				return metadata?.comments || '-';
			},
			sx: {
				minWidth: 200,
				width: 200,
			},
			value: 'metadata',
		},
	];

	// fetch transactions when params change
	useEffect(() => {
		fetchTransactions();
	}, [fetchTransactions, paramOrder, paramOrderBy, paramPage]);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Paper>
						<Table
							borderRadius={2}
							data={transactions}
							headers={headers}
							hasStripes={true}
							isLoading={isLoadingTransactions}
							sxTd={{
								textWrap: {
									xs: 'wrap',
									md: 'wrap',
								},
							}}>
							<MuiTableFooter>
								{totalsbyCurrency.map((item) => {
									return (
										<TableRow>
											<TableCell></TableCell>
											<TableCell component="th" sx={{ fontWeight: 600 }}>
												<Grid alignItems="center" container spacing={1}>
													{item.currency && (
														<Grid item>
															<Flag code={flagsByCurrency[item.currency]} />
														</Grid>
													)}
													<Grid item>{t('total')}</Grid>
												</Grid>
											</TableCell>
											<TableCell sx={{ fontWeight: 600 }}>
												{formatCurrency({ currency: item.currency, value: item.inflow.total })}
											</TableCell>
											<TableCell sx={{ fontWeight: 600 }}>
												{formatCurrency({ currency: item.currency, value: item.outflow.total })}
											</TableCell>
											<TableCell></TableCell>
										</TableRow>
									);
								})}
							</MuiTableFooter>
						</Table>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<TableFooter numShowing={transactions.length} pagination={pagination} />
				</Grid>
			</Grid>
		</Container>
	);
};
