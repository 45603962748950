// react
import { SyntheticEvent, useContext, useEffect, useState } from 'react';

// types
import {
	ActionButton,
	Contract,
	Document,
	Entity,
	Loan,
	Pagination,
	Transaction,
	User,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import {
	Link as RouterLink,
	Outlet,
	useLocation,
	useParams,
	useSearchParams,
} from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// context
import { ContextProject } from '../Project/Project.context';
import {
	ContextSupplierContracts,
	ContextSupplierDocuments,
	ContextSupplierPayments,
	ContextSupplierUsers,
} from './Supplier.context';
import { ContextUser } from '../../../App.context';

// mui
import { Grid } from '@mui/material';

// pages
import { Details } from './Details/Details';

// dialogs
import { DialogAddContract } from '../../dialogs/DialogAddContract/DialogAddContract';
import { DialogAddTransaction } from '../../dialogs/DialogAddTransaction/DialogAddTransaction';
import { DialogAddUser } from '../../dialogs/DialogAddUser/DialogAddUser';

// ui
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Empty } from '../../ui/Empty/Empty';
import { Loading } from '../../ui/Loading/Loading';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Title } from '../../ui/Title/Title';

// defaults
import { defaultPagination } from '../../../utils/defaults';

export const PageSupplier = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const location = useLocation();
	const { id, idSupplier } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramContract = searchParams.get('contract');
	const paramDialog = searchParams.get('dialog');
	const paramExpenseType = searchParams.get('expenseType');
	const paramLoan = searchParams.get('loan');
	const paramPage = Number(searchParams.get('page') || 1);
	const paramQ = searchParams.get('q');
	const paramStatus = searchParams.get('status');
	const paramSupplier = searchParams.get('supplier');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [paginationDocuments, setPaginationDocuments] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationContracts, setPaginationContracts] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationPayments, setPaginationPayments] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [paginationUsers, setPaginationUsers] = useState<Pagination>({
		...defaultPagination,
		page: paramPage,
	});
	const [payments, setPayments] = useState<Transaction[]>([]);
	const [supplier, setSupplier] = useState<Entity | null>(null);
	const [tabValue, setTabValue] = useState('payments');
	const [users, setUsers] = useState<User[]>([]);

	// fetch supplier
	const { isLoading: isLoadingSupplier } = useFetch({
		url: `${process.env.REACT_APP_API_URL}/entities/${idSupplier}?populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSupplier(res.data[0]);
			}
		},
	});

	// fetch supplier contracts
	const {
		fetchRequest: fetchContracts,
		isLoading: isLoadingContracts,
		refetch: refetchContracts,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/contracts?supplier=${idSupplier}&populate=supplier&page=${paramPage}&limit=${paginationContracts.perPage}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setContracts(res.data);
			}

			if (res.pagination) {
				setPaginationContracts(res.pagination);
			}
		},
	});

	// fetch supplier loans
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${idSupplier}&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res) {
				setLoans(res.data);
			}
		},
	});

	// fetch supplier payments
	let fetchPaymentsUrl = `${process.env.REACT_APP_API_URL}/transactions?toEntity=${idSupplier}&type=PAYMENT&page=${paramPage}&limit=${paginationUsers.perPage}&populate=contract,expenseType,fromAccount,fromEntity,payments.loan`;

	if (paramExpenseType) {
		fetchPaymentsUrl += `&expenseType=${paramExpenseType}`;
	}

	if (paramContract) {
		fetchPaymentsUrl += `&contract=${paramContract}`;
	}

	if (paramSupplier) {
		fetchPaymentsUrl += `&supplier=${paramSupplier}`;
	}

	if (paramStatus) {
		fetchPaymentsUrl += `&status=${paramStatus}`;
	}

	if (paramLoan) {
		fetchPaymentsUrl += `&loan=${paramLoan}`;
	}

	if (paramQ) {
		fetchPaymentsUrl += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchPayments,
		isLoading: isLoadingPayments,
		refetch: refetchPayments,
	} = useFetch({
		url: fetchPaymentsUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setPayments(res.data);
			}

			if (res.pagination) {
				setPaginationPayments(res.pagination);
			}
		},
	});

	// fetch supplier users
	let urlUsers = `${process.env.REACT_APP_API_URL}/users?entity=${idSupplier}&page=${paramPage}&limit=${paginationUsers.perPage}`;

	if (paramQ) {
		urlUsers += `&q=${paramQ}`;
	}

	const {
		fetchRequest: fetchUsers,
		isLoading: isLoadingUsers,
		refetch: refetchUsers,
	} = useFetch({
		isLazy: true,
		url: urlUsers,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}

			if (res.pagination) {
				setPaginationUsers(res.pagination);
			}
		},
	});

	// fetch supplier documents
	const {
		fetchRequest: fetchDocuments,
		isLoading: isLoadingDocuments,
		refetch: refetchDocuments,
	} = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/documents?project=${project?.id}&entity=${supplier?.id}&page=${paramPage}&limit=${paginationDocuments.perPage}&populate=type,user,user.entity`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocuments(res.data);
			}

			if (res.pagination) {
				setPaginationDocuments(res.pagination);
			}
		},
	});

	const tabs = [
		{
			component: RouterLink,
			label: t('payments'),
			to: `/project/${id}/supplier/${idSupplier}/payments`,
			value: 'payments',
		},
		{
			component: RouterLink,
			label: t('users'),
			to: `/project/${id}/supplier/${idSupplier}/users`,
			value: 'users',
		},
		{
			component: RouterLink,
			label: t('contracts'),
			to: `/project/${id}/supplier/${idSupplier}/contracts`,
			value: 'contracts',
		},
		{
			component: RouterLink,
			label: t('documents'),
			to: `/project/${id}/supplier/${idSupplier}/documents`,
			value: 'documents',
		},
	];

	// vars
	const titleActions: ActionButton[] = [];

	if (tabValue === 'users' && user?.permissions?.user?.create) {
		titleActions.push({
			children: t('addUser'),
			onClick: () => {
				searchParams.set('dialog', 'addUser');
				setSearchParams(searchParams);
			},
			variant: 'contained',
		});
	}
	if (tabValue === 'payments' && user?.permissions?.transaction?.create) {
		titleActions.push({
			children: t('recordPayment'),
			onClick: () => {
				searchParams.set('dialog', 'recordPayment');
				setSearchParams(searchParams);
			},
			variant: 'contained',
		});
	}
	if (tabValue === 'contracts' && user?.permissions?.contract?.create) {
		titleActions.push({
			children: t('addContract'),
			onClick: () => {
				searchParams.set('dialog', 'addContract');
				setSearchParams(searchParams);
			},
			variant: 'contained',
		});
	}

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	let template;

	if (isLoadingSupplier) {
		template = <Loading variant="page" />;
	} else {
		if (supplier) {
			template = (
				<>
					<Grid container spacing={2}>
						<Grid item xs={11} lg={12}>
							<Breadcrumbs
								crumbs={[
									{
										children: t('projects'),
										to: '/projects',
									},
									{
										children: project?.idWbg,
										to: `/project/${id}`,
									},
									{
										children: t('suppliers'),
										to: `/project/${id}/suppliers`,
									},
								]}
								page={supplier.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Title
								actions={titleActions}
								color="magenta"
								overline={t('supplier')}
								title={supplier.name}
							/>
						</Grid>
						<Grid item xs={12}>
							<Details loans={loans} supplier={supplier} />
						</Grid>
						<Grid item xs={12}>
							<Tabs onChange={handleChangeTab} tabs={tabs} value={tabValue} hasBorder={false} />
						</Grid>
						<Grid item xs={12}>
							<Outlet />
						</Grid>
					</Grid>
					<DialogAddUser
						entity={supplier}
						isOpen={paramDialog === 'addUser'}
						onClose={() => {
							searchParams.delete('dialog');
							setSearchParams(searchParams);
						}}
						onSuccess={() => {
							refetchUsers();
						}}
					/>
					<DialogAddTransaction
						isOpen={paramDialog === 'recordPayment'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
					/>
					<DialogAddContract
						isOpen={paramDialog === 'addContract'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
						onSuccess={() => {
							refetchContracts();
						}}
						supplier={supplier}
					/>
				</>
			);
		} else {
			template = (
				<Empty
					actions={[
						{
							children: t('suppliers'),
							component: RouterLink,
							to: `/project/${project?.id}/suppliers`,
							variant: 'contained',
						},
					]}
					content={{ children: t('empty.supplier.content') }}
					title={{ children: t('empty.supplier.title') }}
					IconCircleProps={{ type: 'supplier' }}
					variant="page"
				/>
			);
		}
	}

	// fetch data when tab changes
	useEffect(() => {
		if (tabValue === 'contracts') {
			fetchContracts();
		} else if (tabValue === 'payments') {
			fetchPayments();
		} else if (tabValue === 'users') {
			fetchUsers();
		} else if (tabValue === 'documents') {
			if (project?.id && supplier?.id) {
				fetchDocuments();
			}
		}
	}, [
		fetchContracts,
		fetchDocuments,
		fetchPayments,
		fetchUsers,
		paramContract,
		paramExpenseType,
		paramLoan,
		paramPage,
		paramQ,
		paramStatus,
		paramSupplier,
		project,
		supplier,
		tabValue,
	]);

	// set active tab
	useEffect(() => {
		let tabValueNew = 'payments';
		if (location.pathname.includes('contracts')) {
			tabValueNew = 'contracts';
		} else if (location.pathname.includes('users')) {
			tabValueNew = 'users';
		} else if (location.pathname.includes('documents')) {
			tabValueNew = 'documents';
		}
		setTabValue(tabValueNew);
	}, [location]);

	return (
		<ContextSupplierContracts.Provider
			value={{
				contracts,
				isLoadingContracts,
				paginationContracts,
				refetchContracts,
				setContracts,
				setPaginationContracts,
			}}>
			<ContextSupplierPayments.Provider
				value={{
					isLoadingPayments,
					paginationPayments,
					payments,
					refetchPayments,
					setPayments,
					setPaginationPayments,
				}}>
				<ContextSupplierUsers.Provider
					value={{
						isLoadingUsers,
						paginationUsers,
						refetchUsers,
						setUsers,
						setPaginationUsers,
						users,
					}}>
					<ContextSupplierDocuments.Provider
						value={{
							isLoadingDocuments,
							paginationDocuments,
							refetchDocuments,
							setDocuments,
							setPaginationDocuments,
							documents,
						}}>
						{template}
					</ContextSupplierDocuments.Provider>
				</ContextSupplierUsers.Provider>
			</ContextSupplierPayments.Provider>
		</ContextSupplierContracts.Provider>
	);
};
