// react
import { MouseEvent, useContext, useState } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// types
import { User } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextUsersPermissions } from '../../../pages/Project/Project.context';

// mui
import { Button, Chip, ChipProps } from '@mui/material';

// dialogs
import { DialogUpdateUserPermissions } from '../../../dialogs/DialogUpdateUserPermissions/DialogUpdateUserPermissions';

// ui
import { Table, TableHeader, TableProps } from '../../../ui/Table/Table';
import { UserAvatar } from '../../../ui/UserAvatar/UserAvatar';

// icons
import {
	CheckOutlined as IconCheck,
	ClearOutlined as IconClear,
	DeleteOutline as IconDelete,
} from '@mui/icons-material';

// props
export interface TableBasicUsersPermissionsProps extends Omit<TableProps, 'headers'> {
	project?: number;
	data: User[];
}

export const TableBasicUsersPermissions = ({
	data = [],
	project,
	...props
}: TableBasicUsersPermissionsProps) => {
	// hooks
	const { t } = useTranslation();

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramDialog = searchParams.get('dialog');

	// context
	const { refetchUsersPermissions } = useContext(ContextUsersPermissions);

	// state
	const [activeUser, setActiveUser] = useState<User | null>(null);

	const headers = [
		{
			label: t('user'),
			value: 'email',
			render: (email: string, header: TableHeader, user: User) => {
				return <UserAvatar user={user} />;
			},
		},
		{
			label: t('email'),
			value: 'email',
		},
		{
			label: t('beneficiaries'),
			value: 'permissionsByProject',
			render: (permissionsByProject: User['permissionsByProject']) => {
				const chipProps: ChipProps = {
					color: 'default',
					icon: <IconClear />,
				};

				if (project && permissionsByProject?.[String(project)]?.beneficiary?.read) {
					chipProps.color = 'success';
					chipProps.icon = <IconCheck />;
				}

				return <Chip className="circle" size="small" variant="twoTone" {...chipProps} />;
			},
		},
		{
			label: '',
			value: 'firstName',
			render: (firstName: string, header: TableHeader, user: User) => {
				return (
					<Button
						aria-label={t('removePermissions', { name: firstName })}
						className="icon"
						color="error"
						onClick={(e: MouseEvent<HTMLButtonElement>) => {
							searchParams.set('dialog', 'updateUserPermissions');
							setSearchParams(searchParams);
							setActiveUser(user);
						}}
						variant="outlined">
						<IconDelete />
					</Button>
				);
			},
		},
	];

	return (
		<>
			<Table {...props} data={data} headers={headers} />
			{activeUser && (
				<DialogUpdateUserPermissions
					isOpen={paramDialog === 'updateUserPermissions'}
					onClose={() => {
						searchParams.delete('dialog');
						setSearchParams(searchParams);
					}}
					onSuccess={() => {
						refetchUsersPermissions();
					}}
					user={activeUser as User}
				/>
			)}
		</>
	);
};
