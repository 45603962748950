// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { Entity, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';

// props
export interface FormAddUserProps {
	entity?: Entity;
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddUser = ({ entity, onClose, onSuccess, setIsLoading }: FormAddUserProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-add-user') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// set loader
			setIsLoading(true);

			const fetchAddUser = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify({
					...entries,
					entity: entity ? entity.id : null,
					project: project?.id,
					role: 'GENERAL',
					status: 'INVITED',
				}),
			});
			const resAddUser = await fetchAddUser.json();

			if (resAddUser.error) {
				throw new Error(resAddUser.error.message, {
					cause: {
						id: resAddUser.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.userCreated', { name: `${entries.firstName} ${entries.lastName}` }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'USER_DUPLICATE') {
				message = t('error.userDuplicate', { email: entries.email });
			}

			if (cause.id === 'USER_INVALID_ROLE') {
				message = t('error.userInvalidRole');
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id="form-add-user" name="formAddUser" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-user-first-name"
						label={t('firstName')}
						name="firstName"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-user-last-name"
						label={t('lastName')}
						name="lastName"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-user-email"
						label={t('email')}
						name="email"
						required={true}
						type="email"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-user-phone"
						label={t('phone')}
						name="phone"
						required={true}
						type="tel"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input id="form-add-user-title" label={t('jobTitle')} name="title" />
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
