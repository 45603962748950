export const formatBytes = (
	num: number,
	options: Intl.NumberFormatOptions = {
		notation: 'compact',
		style: 'unit',
		unit: 'byte',
		unitDisplay: 'narrow',
	}
) => {
	return new Intl.NumberFormat('en', options).format(num);
};

export const formatNumber = (num: number, options: Intl.NumberFormatOptions = {}) => {
	return new Intl.NumberFormat('en', options).format(num);
};
