// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import {
	Document,
	DocumentType,
	Entity,
	ErrorCause,
	ExpenseType,
	FilePayload,
	Transaction,
	TransactionType,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { FilePicker } from '../../fields/FilePicker/FilePicker';

// options
import { DocsTraceability } from '../../ui/DocsTraceability/DocsTraceability';

// utils
import { toKebabCase } from '../../../utils/strings';

// props
export interface FormUploadDocumentsProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	transaction: Transaction;
}

export const FormUploadDocuments = ({
	onClose,
	onSuccess,
	setIsLoading,
	transaction,
}: FormUploadDocumentsProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [documents, setDocuments] = useState<Document[]>([]);
	const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
	const [docTypesSelected, setDocTypesSelected] = useState<string[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [files, setFiles] = useState<File[]>([]);

	// fetch document types for expense type
	const expenseType = transaction?.expenseType as ExpenseType;

	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	useFetch({
		isEnabled: Boolean(transaction && expenseType),
		url: `${process.env.REACT_APP_API_URL}/expense-types/${expenseType?.id}?entity=${paramEntity}&populate=documentTypes`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				// set doc types
				setDocTypes(res.data[0].documentTypes);
			}
		},
	});

	// fetch documents
	useFetch({
		isEnabled: Boolean(transaction),
		url: `${process.env.REACT_APP_API_URL}/documents?transaction=${transaction?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				// set documents
				setDocuments(res.data);
			}
		},
	});

	// vars
	const docTypesExisting = documents.map((doc) => String(doc.type));
	const docTypesChecked = [...new Set(docTypesExisting.concat(docTypesSelected))];
	const hasTraceability = ['CASH_BENEFIT', 'PAYMENT'].includes(transaction.type);

	const typePaths: Record<TransactionType, string> = {
		CASH_BENEFIT: 'cash-benefits',
		INITIAL: 'initial-deposits',
		NON_CASH_BENEFIT: 'non-cash-benefits',
		OPEX: 'opex',
		PAYMENT: `payments/${toKebabCase(String(expenseType?.name))}`,
		REPLENISHMENT: 'replenishments',
		TRANSFER: 'transfers',
	};

	// options
	const optionsDocTypes = docTypes.map((docType) => {
		return {
			label: docType.name,
			value: String(docType.id),
		};
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-upload-documents') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loader
			setIsLoading(true);

			const documents: FilePayload[] = [];

			// define documents using files
			if (files.length) {
				for (let i = 0; i < files.length; i++) {
					// add document
					documents.push({
						file: files[i],
						type: entries[`file-${i}-doc-type`] ? String(entries[`file-${i}-doc-type`]) : null,
					});

					// delete entry
					delete entries[`file-${i}-doc-type`];
				}
			}

			// for each document
			for (let i = 0; i < documents.length; i++) {
				// define doc
				const doc = documents[i];

				// define form data
				const formData = new FormData();
				const dateToday = new Date();
				const year = dateToday.getFullYear();
				const month = new Intl.DateTimeFormat('en-US', {
					month: '2-digit',
				}).format(dateToday);

				// append form data
				formData.append(
					'path',
					`bldt/projects/${project?.id}/transactions/${typePaths[transaction.type]}/${
						transaction.id
					}/${year}/${month}/`
				);
				formData.append('file', doc.file);
				formData.append('transaction', String(transaction.id));

				if (project) {
					formData.append('project', String(project.id));
				}

				if (doc.type) {
					formData.append('type', doc.type);
				}

				// POST document
				const fetchPostDoc = await fetch(`${process.env.REACT_APP_API_URL}/documents`, {
					method: 'post',
					body: formData,
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
				});

				const resPostDoc = await fetchPostDoc.json();

				if (resPostDoc.error) {
					throw new Error(resPostDoc.error.message, {
						cause: {
							id: resPostDoc.error.id,
						},
					});
				}
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.documentsUploaded'),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'DOCUMENT_FILE_NON_EXISTENT') {
					message = t('error.documentFileNonExistent');
				}

				if (cause.id === 'DOCUMENT_INVALID_MIME_TYPE') {
					message = t('error.documentInvalidMimeType');
				}

				if (cause.id === 'DOCUMENT_TRANSACTION_NON_EXISTENT') {
					message = t('error.documentTransactionNonExistent');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id="form-upload-documents" name="formUploadDocuments" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FilePicker
						onChange={(e, filesSelected) => {
							if (hasTraceability) {
								if (optionsDocTypes) {
									const defaultDocType = optionsDocTypes[0].value as string;

									if (!docTypesSelected.includes(defaultDocType)) {
										setDocTypesSelected([...docTypesSelected, defaultDocType]);
									}
								}
							}

							setFiles(filesSelected);
						}}
						onChangeDocType={(e, i) => {
							if (hasTraceability) {
								// clone doc types selected
								const docTypesSelectedNew = [...docTypesSelected];

								// define new doc type
								const docTypeNew = String(e.target.value);

								// replace or add doc type
								if (docTypesSelectedNew[i]) {
									docTypesSelectedNew[i] = docTypeNew;
								} else {
									docTypesSelectedNew.push(docTypeNew);
								}

								setDocTypesSelected(docTypesSelectedNew);
							}
						}}
						onRemove={(e, i) => {
							// clone doc types selected
							const docTypesSelectedNew = [...docTypesSelected];

							// remove selected doc by index
							docTypesSelectedNew.splice(i, 1);

							setDocTypesSelected(docTypesSelectedNew);
						}}
						required={true}
						optionsDocTypes={optionsDocTypes}
					/>
				</Grid>
				{hasTraceability && (
					<Grid item xs={12}>
						<DocsTraceability docTypes={docTypes} docTypesChecked={docTypesChecked} />
					</Grid>
				)}
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
