// react
import { useContext } from 'react';

// context
import { ContextNonCashBenefits } from '../../Project.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableNonCashBenefits } from '../../../../tables/cards/TableNonCashBenefits/TableNonCashBenefits';

export const NonCashBenefits = () => {
	// context
	const {
		nonCashBenefits,
		isLoadingNonCashBenefits,
		paginationNonCashBenefits,
		refetchNonCashBenefits,
	} = useContext(ContextNonCashBenefits);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableNonCashBenefits
						isLoading={isLoadingNonCashBenefits}
						pagination={paginationNonCashBenefits}
						refetchTransactions={refetchNonCashBenefits}
						transactions={nonCashBenefits}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
