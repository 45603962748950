// types
import { FormField } from '../../../@types';

// fields
import { Checkbox } from '../Checkbox/Checkbox';
import { Input } from '../Input/Input';
import { Select } from '../Select/Select';
import { SelectCountry } from '../SelectCountry/SelectCountry';
import { SelectCurrency } from '../SelectCurrency/SelectCurrency';

// utils
import { toKebabCase } from '../../../utils/strings';

// props
export interface DynamicFieldProps {
	field: FormField;
}

export const DynamicField = ({ field }: DynamicFieldProps) => {
	// vars
	const { helper, isEditable, isProtected, isRequired, name, options, type, ...props } = field;
	const id = toKebabCase(field.label);
	const inputFieldTypeMap: Record<string, string> = {
		DATE: 'date',
		EMAIL: 'email',
		NUMBER: 'number',
		TEXT: 'text',
		TIME: 'time',
		URL: 'url',
	};

	if (name === 'metadata-country' || name === 'country') {
		return <SelectCountry id={id} name={name} required={isRequired} {...props} />;
	}

	if (name === 'metadata-currency' || name === 'currency') {
		return <SelectCurrency id={id} name={name} required={isRequired} {...props} />;
	}

	if (type === 'CHECKBOX') {
		return <Checkbox disableRipple={true} id={id} name={name} required={isRequired} {...props} />;
	} else if (['DATE', 'EMAIL', 'NUMBER', 'TEXT', 'TIME', 'URL'].includes(type)) {
		return (
			<Input
				helperText={helper}
				id={id}
				name={name}
				required={isRequired}
				type={inputFieldTypeMap[type]}
				{...props}
			/>
		);
	} else if (type === 'SELECT') {
		return (
			<Select
				hasNoneOption={false}
				helperText={helper}
				id={id}
				name={name}
				options={options || []}
				required={isRequired}
				{...props}
			/>
		);
	}

	return <></>;
};
