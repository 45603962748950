// mui
import { Grid, LinearProgress, Paper, Typography, TypographyProps } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';
import { Loading } from '../../ui/Loading/Loading';

// props
interface CardKeyValueProps {
	IconCircleProps?: IconCircleProps;
	isLoading?: boolean;
	label: TypographyProps;
	percent?: number;
	value: TypographyProps;
}

export const CardKeyValue = ({
	IconCircleProps,
	isLoading,
	label,
	percent,
	value,
}: CardKeyValueProps) => {
	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					height: '100%',
				};
			}}>
			<Grid
				alignItems="flex-end"
				container
				justifyContent="space-between"
				spacing={2}
				sx={{ height: 'calc(100% + 16px)' }}>
				<Grid item xs={typeof percent === 'number' ? 'auto' : true}>
					{isLoading ? (
						<Loading size={30} />
					) : (
						<Typography
							color="brand.blue.500"
							component="div"
							fontWeight={600}
							variant="h2"
							{...value}
						/>
					)}
					<Typography component="div" variant="body2" {...label} />
				</Grid>
				{IconCircleProps && (
					<Grid alignSelf="flex-start" item xs="auto">
						<IconCircle {...IconCircleProps} />
					</Grid>
				)}
				{typeof percent === 'number' && !Number.isNaN(percent) && (
					<Grid item xs={true}>
						<div>
							<Typography
								color="brand.blue.700"
								component="div"
								style={{ marginLeft: `${percent}%` }}
								variant="body2">
								{percent}%
							</Typography>
							<LinearProgress value={percent} variant="determinate" />
						</div>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};
