// react
import { useContext, useState, useRef } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Chip, Container, Grid, Paper, Typography } from '@mui/material';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// tables
import { ChartSankey, ChartSankeyProps } from '../../../../charts/ChartSankey/ChartSankey';

// context
import { ContextUser } from '../../../../../App.context';

// ui
import { BtnFilters } from '../../../../ui/BtnFilters/BtnFilters';
import { BtnFullscreen } from '../../../../ui/BtnFullscreen/BtnFullscreen';
import { Empty, EmptyProps } from '../../../../ui/Empty/Empty';
import { FiltersProps } from '../../../../ui/Filters/Filters';

// utils
import { truncate } from '../../../../../utils/strings';

// props
export interface SankeyProps {
	dataSankey: ChartSankeyProps | null;
	FiltersProps: FiltersProps;
	onResetFilters: () => void;
}

export const Sankey = ({ dataSankey, FiltersProps, onResetFilters }: SankeyProps) => {
	// context
	const { user } = useContext(ContextUser);

	// refs
	const refPaper = useRef<HTMLDivElement>(null);

	// hooks
	const { t } = useTranslation();

	// defaults
	const defaultHeight = 600;

	// state
	const [height, setHeight] = useState(defaultHeight);

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramQ = searchParams.get('q');

	// vars
	const { filters } = FiltersProps;
	const empty: EmptyProps = {
		actions: [],
		content: { children: t('empty.fundFlow.content') },
		IconCircleProps: { icon: <IconPaid /> },
		title: { children: t('empty.fundFlow.title') },
	};

	if (user?.permissions?.transactions?.create) {
		empty.actions?.push(
			{
				children: t('recordFundTransfer'),
				onClick: () => {
					searchParams.set('dialog', 'recordFundTransfer');
					setSearchParams(searchParams);
				},
				variant: 'outlined',
			},
			{
				children: t('recordPayment'),
				onClick: () => {
					searchParams.set('dialog', 'recordPayment');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			}
		);
	}

	if (paramQ) {
		empty.actions = [];
		empty.content = { children: t('empty.default.content') };
		empty.IconCircleProps = { icon: null };
	}

	// render chips according to the values selected in the filters
	const renderChips = () => {
		const chips = [];

		// for each filter
		for (const filter of filters) {
			// get search param
			const param = searchParams.get(filter.name as string);

			let count = null;
			let value: null | string = t('all');

			if (filter.type === 'DATE') {
				value = null;
			}

			if (param) {
				value = param;

				if (filter.options) {
					const paramValues = param.split(',');
					const valueArray = filter.options
						.filter((option) => paramValues.includes(option.value as string))
						.map((option) => option.label);

					if (valueArray.length > 1) {
						count = valueArray.length - 1;
						value = valueArray[0];
					} else {
						value = valueArray.join(', ');
					}
				}
			}

			if (value) {
				chips.push({
					count,
					label: filter.label as string,
					value: truncate({ end: 15, str: value, suffix: '...' }),
				});
			}
		}

		return chips.map((chip, i) => {
			const { count, label, value } = chip;
			return (
				<Grid item key={`filter-chip-${i}-${value}`}>
					<Chip
						label={
							<Grid container spacing={1}>
								<Grid item>
									<Typography color="brand.grey.500" component="span" fontWeight={600}>
										{label}
									</Typography>
								</Grid>
								<Grid item>
									<Typography component="span" fontWeight={600}>
										{value}
									</Typography>
									{count && (
										<Typography component="span" fontWeight={600}>
											{' '}
											+{count}
										</Typography>
									)}
								</Grid>
							</Grid>
						}
						variant="outlined"
					/>
				</Grid>
			);
		});
	};

	return (
		<Container maxWidth={false}>
			<Paper
				ref={refPaper}
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
						overflowY: 'auto',
					};
				}}>
				<Grid container spacing={2} sx={{ minHeight: height }}>
					<Grid item xs={12}>
						<Grid container justifyContent="space-between" spacing={2}>
							<Grid item xs={12} md={true}>
								<Grid container spacing={2}>
									{renderChips()}
								</Grid>
							</Grid>
							<Grid item xs={12} md="auto">
								<Grid container spacing={2}>
									<Grid item xs={6} md="auto">
										<BtnFilters filters={filters} onReset={onResetFilters} />
									</Grid>
									<Grid item xs={6} md="auto">
										<BtnFullscreen
											element={refPaper.current}
											onEnter={() => {
												setHeight(1200);
											}}
											onExit={() => {
												setHeight(defaultHeight);
											}}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!dataSankey ? (
						<Grid item xs={12}>
							<Empty {...empty} />
						</Grid>
					) : (
						<>
							<Grid item xs={12}>
								<ChartSankey data={dataSankey.data} height={height} />
							</Grid>
							<Grid alignSelf="flex-end" item xs={12}>
								<Alert severity="info">{t('alert.fundFlow')}</Alert>
							</Grid>
						</>
					)}
				</Grid>
			</Paper>
		</Container>
	);
};
