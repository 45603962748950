// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Transaction } from '../../../@types';

// mui
import { Grid } from '@mui/material';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';
import { TableBasicTransactions } from '../../tables/basic/TableBasicTransactions/TableBasicTransactions';

// ui
import { BoxKeyValue } from '../../ui/BoxKeyValue/BoxKeyValue';
import { entityIconTypes, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// utils
import { formatCurrency } from '../../../utils/currency';

// props
export interface DialogTransactionsProps extends DialogActionsProps {
	fromEntity: Entity;
	isOpen: boolean;
	onClose: () => void;
	toEntity: Entity;
	transactions: Transaction[];
}

export const DialogTransactions = ({
	fromEntity,
	isOpen,
	onClose,
	toEntity,
	transactions,
	...props
}: DialogTransactionsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const total = transactions.reduce((total, transaction) => {
		return (total += transaction.amount);
	}, 0);

	const getIconCircleProps = (entity: Entity) => {
		const iconCircleProps: IconCircleProps = {
			type: 'default',
		};

		if (entity) {
			if (entityIconTypes[entity.type]) {
				iconCircleProps.type = entityIconTypes[entity.type];
			} else {
				const entityName = entity.name.toLowerCase();

				iconCircleProps.color = 'grey';

				if (entityName.includes('other')) {
					iconCircleProps.type = 'other';
				} else if (entityName.includes('government')) {
					iconCircleProps.type = 'government';
				} else if (entityName.includes('world bank')) {
					iconCircleProps.type = 'account';
				}
			}
		}

		return iconCircleProps;
	};

	const fromEntityIconCircleProps = getIconCircleProps(fromEntity);
	const toEntityIconCircleProps = getIconCircleProps(toEntity);

	return (
		<DialogActions
			{...props}
			hasClose={true}
			isOpen={isOpen}
			maxWidth={800}
			onClose={onClose}
			subtitle={{
				children: `${formatCurrency({
					currency: transactions?.[0]?.currency,
					value: total,
				})} ${t('total')}`,
				variant: 'body2',
			}}
			title={{
				children: t('transactions'),
				variant: 'h3',
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<BoxKeyValue
						IconCircleProps={fromEntityIconCircleProps}
						label={{
							children: t('sent'),
						}}
						value={{
							children: fromEntity?.name,
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<BoxKeyValue
						IconCircleProps={toEntityIconCircleProps}
						label={{
							children: t('received'),
						}}
						value={{
							children: toEntity?.name,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TableBasicTransactions
						borderRadius={2}
						color="grey"
						hasBorder={true}
						transactions={transactions}
					/>
				</Grid>
			</Grid>
		</DialogActions>
	);
};
