// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormManageContract } from '../../forms/FormManageContract/FormManageContract';

// types
import { Contract } from '../../../@types';

// props
export interface DialogEditContractProps {
	contract: Contract;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const DialogEditContract = ({
	contract,
	isOpen,
	onClose,
	onSuccess,
}: DialogEditContractProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('editContract'),
					loading: isLoading,
					form: 'form-manage-contract',
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: t('editContract'),
				variant: 'h3',
			}}>
			<FormManageContract
				contract={contract}
				onClose={onClose}
				onSuccess={onSuccess}
				setIsLoading={setIsLoading}
			/>
		</DialogActions>
	);
};
