// react
import { Dispatch, MouseEvent, SetStateAction } from 'react';

// types
import { Order, Sort } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// ui
import { FnSort, TableHeadersProps } from '../TableHeaders';

// mui
import { Box, Grid, GridProps, Typography } from '@mui/material';

// icons
import {
	KeyboardArrowDownOutlined as IconKeyboardArrowDown,
	KeyboardArrowUpOutlined as IconKeyboardArrowUp,
} from '@mui/icons-material';

// props
export interface TableHeaderProps {
	activeSort?: Sort;
	color?: TableHeadersProps['color'];
	GridProps?: GridProps;
	isSortable?: boolean;
	label: string;
	onSort?: FnSort;
	setActiveSort?: Dispatch<SetStateAction<Sort | undefined>>;
	value: string;
	xs?: GridProps['xs'];
	sm?: GridProps['sm'];
	md?: GridProps['md'];
	lg?: GridProps['lg'];
	xl?: GridProps['xl'];
}

export const TableHeader = ({
	activeSort,
	color,
	GridProps,
	isSortable,
	label,
	onSort,
	setActiveSort,
	value,
	xs,
	sm,
	md,
	lg,
	xl,
}: TableHeaderProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	const isSortActive = activeSort?.value === value;

	const colorActive = 'brand.blue.400';
	let colorDefault = 'brand.blue.700';

	if (color === 'grey') {
		colorDefault = 'brand.grey.800';
	}

	const handleSort = (e: MouseEvent<HTMLButtonElement>, value: TableHeaderProps['value']) => {
		// define new order
		const orderNew: Order = activeSort?.order === 'ASC' ? 'DESC' : 'ASC';

		// run callback
		if (onSort) {
			onSort({ e, order: orderNew, value });
		}

		// set active sorted header
		if (setActiveSort) {
			setActiveSort({
				order: orderNew,
				value,
			});
		}

		// set search params
		searchParams.set('orderBy', value);
		searchParams.set('order', orderNew);
		setSearchParams(searchParams);
	};

	const header = (
		<Grid alignItems="center" container spacing={1}>
			<Grid item>
				<Typography color={colorDefault} fontWeight={600} variant="caption">
					{label}
				</Typography>
			</Grid>
			{isSortable && (
				<Grid item>
					<Grid
						container
						sx={(theme) => {
							return {
								'& svg': {
									transition: 'all 200ms',
									display: 'block !important',
									width: `${theme.spacing(2)} !important`,
									height: 'auto !important',
								},
							};
						}}>
						<Grid
							item
							xs={12}
							sx={(theme) => {
								return {
									'& svg': {
										color: isSortActive && activeSort.order === 'ASC' ? colorActive : colorDefault,
									},
								};
							}}>
							<IconKeyboardArrowUp />
						</Grid>
						<Grid
							item
							xs={12}
							sx={(theme) => {
								return {
									'& svg': {
										color: isSortActive && activeSort.order === 'DESC' ? colorActive : colorDefault,
									},
								};
							}}>
							<IconKeyboardArrowDown />
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);

	const headerButton = (
		<Box component="button" onClick={(e: MouseEvent<HTMLButtonElement>) => handleSort(e, value)}>
			{header}
		</Box>
	);

	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} {...GridProps}>
			{isSortable ? headerButton : header}
		</Grid>
	);
};
