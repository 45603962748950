// react
import { Fragment, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Loan, Metadata, MetadataField } from '../../../@types';

// mui
import { Grid, Typography, Paper } from '@mui/material';

// ui
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// utils
import { truncate } from '../../../utils/strings';

// theme
import { theme } from '../../../theme';

// props
export interface CardSupplierDetailsProps {
	loans: Loan[];
	supplier: Entity;
}

export const CardSupplierDetails = ({ loans, supplier }: CardSupplierDetailsProps) => {
	// state
	const [isExpanded, setIsExpanded] = useState(false);

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	// hooks
	const { t } = useTranslation();

	// vars
	const associatedEntities: Entity[] = [];
	let metadata = supplier.metadata;

	if (Array.isArray(metadata)) {
		metadata = metadata as MetadataField[];
	} else {
		// reformat existing metadata object into dynamic fields array
		const keys = Object.keys(metadata);

		const metadatanew: MetadataField[] = [];
		const metadataLegacyFields: Metadata = {
			address: 'Address',
			address1: 'Address 1',
			address2: 'Address 2',
			city: 'City',
			country: 'Country',
			postcode: 'Postcode',
			region: 'Region',
		};

		for (let i = 0; i < keys.length; i++) {
			metadatanew.push({
				name: keys[i],
				label: (metadataLegacyFields[keys[i]] as string) || keys[i],
				isProtected: true,
				value: metadata[keys[i]],
				type: 'TEXT',
			});
		}

		metadata = metadatanew;
	}

	// for each loan
	for (let i = 0; i < loans.length; i++) {
		const supplierLoan = loans[i].entities.find((e) => e.id === supplier.id);

		if (supplierLoan && supplierLoan.parent) {
			const supplierExists = associatedEntities.find((e) => e.id === supplierLoan.parent?.id);

			if (!supplierExists) {
				associatedEntities.push(supplierLoan.parent);
			}
		}
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					position: 'relative',
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<Grid container spacing={3}>
				<Grid item xs={6} md={6} lg={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('associatedEntities')}
					</Typography>
					<Grid alignItems="center" container spacing={1}>
						{associatedEntities.length ? (
							<Grid item flex={1}>
								{associatedEntities.map((supplierParent, i) => {
									const isLast = i === associatedEntities.length - 1;
									return (
										<Fragment key={`supplier-${i}`}>
											<LinkEntity
												entity={{
													...supplierParent,
													name: truncate({ end: 20, str: supplierParent.name, suffix: '...' }),
												}}
											/>
											{!isLast ? ', ' : ''}
										</Fragment>
									);
								})}
							</Grid>
						) : (
							<Grid item>
								<Typography>{t('na')}</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item xs={6} md={6} lg={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('uniqueIdentifier')}
					</Typography>
					<Typography>{supplier?.idExternal ? supplier?.idExternal : t('na')}</Typography>
				</Grid>
				<Grid item xs={6} md={6} lg={3}>
					<Typography variant="h3" color="brand.grey.500">
						{t('wallet')}
					</Typography>
					<BlockchainHash hash={supplier.wallet?.address} />
				</Grid>
				{isExpanded && (
					<>
						{metadata.map((field, i) => {
							return (
								<Grid item key={`field-${field.name}-${i}`} xs={6} md={6} lg={4}>
									<Typography variant="h3" color="brand.grey.500">
										{t(`${field.label}`)}
									</Typography>
									{field.name === 'country' ? (
										<Typography>
											{field?.value
												? new Intl.DisplayNames(['en'], { type: 'region' }).of(String(field.value))
												: t('na')}
										</Typography>
									) : (
										<Typography> {field?.value ? String(field.value) : t('na')}</Typography>
									)}
								</Grid>
							);
						})}
					</>
				)}
			</Grid>
			<BtnExpand
				hasDisplayToggled={false}
				isExpanded={isExpanded}
				label={isExpanded ? t('hideDetails') : t('seeDetails')}
				onClick={handleToggleCard}
				sxIconButton={{
					position: 'absolute',
					top: theme.spacing(1),
					right: theme.spacing(1),
				}}
			/>
		</Paper>
	);
};
