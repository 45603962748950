// react
import { useContext, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Benefit, Entity } from '../../../../../@types';

// context
import { ContextProject } from '../../Project.context';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// mui
import { Container, Grid, Paper, Typography } from '@mui/material';

// forms
import { FormAddBenefit } from '../../../../forms/FormAddBenefit/FormAddBenefit';

// tables
import { TableBasicBenefits } from '../../../../tables/basic/TableBasicBenefits/TableBasicBenefits';
import { ContextUser } from '../../../../../App.context';

export const Benefits = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [benefits, setBenefits] = useState<Benefit[]>([]);

	// vars
	const userEntity = user?.entity as Entity;

	// fetch benefits
	let urlBenefits = `${process.env.REACT_APP_API_URL}/benefits?project=${project?.id}`;

	if (user?.entity) {
		urlBenefits += `&entity=null,${userEntity.id}`;
	}

	const { refetch: refetchBenefits } = useFetch({
		isEnabled: Boolean(project),
		url: urlBenefits,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBenefits(res.data);
			}
		},
	});

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={4}>
					<Paper
						sx={(theme) => {
							return {
								padding: theme.spacing(2),
								height: '100%',
								minHeight: {
									lg: 400,
								},
							};
						}}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h3">{t('addBenefit')}</Typography>
							</Grid>
							<Grid item xs={12}>
								<FormAddBenefit
									onSuccess={() => {
										refetchBenefits();
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12} lg={8}>
					<Paper
						sx={(theme) => {
							return {
								padding: theme.spacing(2),
								height: '100%',
							};
						}}>
						<Grid
							alignItems="stretch"
							container
							direction="column"
							spacing={2}
							sx={{ height: 'calc(100% + 16px)' }}>
							<Grid item>
								<Typography variant="h3">{t('benefits')}</Typography>
							</Grid>
							<Grid item flex={1}>
								<TableBasicBenefits
									borderRadius={2}
									color="grey"
									data={benefits}
									hasBorder={true}
									hasStripes={true}
									refetchBenefits={refetchBenefits}
								/>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};
