// react
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { ErrorCause, Financier, LoanPayload } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Alert, Grid, InputAdornment, SelectChangeEvent } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { RadioButtons } from '../../fields/RadioButtons/RadioButtons';
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	PublicOutlined as IconPublic,
} from '@mui/icons-material';

// props
export interface FormAddFinancingSourceProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddFinancingSource = ({
	onClose,
	onSuccess,
	setIsLoading,
}: FormAddFinancingSourceProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// defaults
	const defaultValues = {
		amount: 0,
		currency: '',
		dateApproved: '',
		dateClosing: '',
		dateSigning: '',
		disbursed: 0,
		financier: 'GOV',
		name: '',
		num: '',
	};

	// state
	const [error, setError] = useState<string | null>(null);
	const [values, setValues] = useState(defaultValues);

	// vars
	const idForm = `form-add-loan`;

	const handleChangeValue = (e: ChangeEvent | SelectChangeEvent<unknown>) => {
		const { name, value } = e.target as HTMLInputElement;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());
		const id = `${entries.financier}${entries.num}`;

		try {
			// set loader
			setIsLoading(true);

			if (entries.amount && entries.disbursed) {
				if (Number(entries.amount) < Number(entries.disbursed)) {
					throw new Error('Disbursed must be less than amount.', {
						cause: {
							id: 'LOAN_DISBURSED_GREATER_THAN_AMOUNT',
						},
					});
				}
			}

			const body: LoanPayload = {
				accounts: [],
				amount: entries.amount ? Number(entries.amount) : null,
				currency: String(entries.currency),
				dateApproved: entries.dateApproved ? String(entries.dateApproved) : null,
				dateClosing: entries.dateClosing ? String(entries.dateClosing) : null,
				disbursed: entries.disbursed ? Number(entries.disbursed) : null,
				entities: [],
				financier: entries.financier as Financier,
				name: entries.name ? String(entries.name) : null,
				num: String(entries.num),
				project: project?.id,
				status: 'ENABLED',
			};

			const fetchAddFinancingSource = await fetch(`${process.env.REACT_APP_API_URL}/loans`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddFinancingSource = await fetchAddFinancingSource.json();

			if (resAddFinancingSource.error) {
				throw new Error(resAddFinancingSource.error.message, {
					cause: {
						id: resAddFinancingSource.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.financingSourceCreated', { id }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'LOAN_DISBURSED_GREATER_THAN_AMOUNT') {
					message = t('error.loanDisbursedAmount');
				} else if (cause.id === 'LOAN_DUPLICATE') {
					message = t('error.userDuplicate', { email: entries.email });
				}
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id={idForm} name="formAddFinancingSource" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RadioButtons
						inputs={[
							{
								icon: <IconAccountBalance />,
								label: t('government'),
								value: 'GOV',
							},
							{
								icon: <IconPublic />,
								label: t('other'),
								value: 'OTH',
							},
						]}
						id={`${idForm}-financier`}
						label={t('financier')}
						name="financier"
						onChange={(e, value) => {
							setValues({
								...values,
								financier: value,
							});
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12}>
					<Input
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						onChange={handleChangeValue}
						required={true}
						value={values.name}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-num`}
						inputProps={{
							maxLength: 5,
						}}
						InputProps={{
							startAdornment: <InputAdornment position="start">{values.financier}</InputAdornment>,
						}}
						label={t('number')}
						name="num"
						onChange={handleChangeValue}
						required={true}
						value={values.num}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectCurrency id={`${idForm}-currency`} name="currency" required={true} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-amount`}
						label={t('amount')}
						name="amount"
						onChange={handleChangeValue}
						required={true}
						type="number"
						value={values.amount}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-disbursed`}
						label={t('disbursed')}
						name="disbursed"
						onChange={handleChangeValue}
						type="number"
						value={values.disbursed}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-date-approved`}
						label={t('approvedDate')}
						name="dateApproved"
						onChange={handleChangeValue}
						type="date"
						value={values.dateApproved}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-date-closing`}
						label={t('closingDate')}
						name="dateClosing"
						onChange={handleChangeValue}
						type="date"
						value={values.dateClosing}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
