// types
import { Benefit } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Button, Grid, Tooltip, Typography } from '@mui/material';

// ui
import { Table, TableHeader, TableProps } from '../../../ui/Table/Table';
import { Time } from '../../../ui/Time/Time';

// dialogs
import { DialogDeleteBenefit } from '../../../dialogs/DialogDeleteBenefit/DialogDeleteBenefit';
import { DialogEditBenefit } from '../../../dialogs/DialogEditBenefit/DialogEditBenefit';

// icons
import {
	ModeEditOutlined as IconModeEdit,
	DeleteOutlined as IconDelete,
} from '@mui/icons-material';

// props
export interface TableBasicBenefitsProps extends Omit<TableProps, 'headers'> {
	data: Benefit[];
	refetchBenefits?: () => void;
}

export const TableBasicBenefits = ({
	data = [],
	refetchBenefits,
	...props
}: TableBasicBenefitsProps) => {
	// hooks
	const [searchParams, setSearchParams] = useSearchParams();
	const { t } = useTranslation();

	// params
	const paramDialog = searchParams.get('dialog');

	const headers: TableHeader[] = [
		{
			label: t('name'),
			render: (name: string) => {
				return <Typography fontWeight={600}>{name}</Typography>;
			},
			value: 'name',
		},
		{
			label: t('created'),
			render: (created: string) => {
				return <Time date={created} />;
			},
			value: 'created',
		},
		{
			label: '',
			render: (name: string, header: TableHeader, benefit: Benefit) => {
				return (
					<Grid container spacing={2}>
						<Grid item>
							<Tooltip placement="top" title={t('edit')}>
								<Button
									aria-label={t('edit')}
									className="icon"
									color="primary"
									onClick={() => {
										searchParams.set('dialog', 'editBenefit');
										searchParams.set('dialogBenefit', String(benefit.id));
										setSearchParams(searchParams);
									}}
									size="small"
									variant="outlined">
									<IconModeEdit />
								</Button>
							</Tooltip>
						</Grid>
						<Grid item>
							<Tooltip placement="top" title={t('delete')}>
								<Button
									aria-label={t('delete')}
									className="icon"
									color="error"
									onClick={() => {
										searchParams.set('dialog', 'deleteBenefit');
										searchParams.set('dialogBenefit', String(benefit.id));
										setSearchParams(searchParams);
									}}
									size="small"
									variant="outlined">
									<IconDelete />
								</Button>
							</Tooltip>
						</Grid>
					</Grid>
				);
			},
			value: 'created',
		},
	];

	const handleCloseDialog = () => {
		searchParams.delete('dialog');
		searchParams.delete('dialogBenefit');
		setSearchParams(searchParams);
	};

	return (
		<>
			<Table {...props} data={data} headers={headers} />
			<DialogDeleteBenefit
				isOpen={paramDialog === 'deleteBenefit'}
				onClose={handleCloseDialog}
				onSuccess={() => {
					if (refetchBenefits) {
						refetchBenefits();
					}
				}}
			/>
			<DialogEditBenefit
				isOpen={paramDialog === 'editBenefit'}
				onClose={handleCloseDialog}
				onSuccess={() => {
					if (refetchBenefits) {
						refetchBenefits();
					}
				}}
			/>
		</>
	);
};
