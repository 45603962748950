// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextBanner } from '../../../App.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// types
import { Account, ErrorCause, Loan, PaymentPayload, TransactionMetadata } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Amounts } from '../../fields/Amounts/Amounts';
import { Input } from '../../fields/Input/Input';

// utils
import { formatDate } from '../../../utils/dates';

// props
export interface FormAddAccountBalanceProps {
	account: Account;
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddAccountBalance = ({
	account,
	onClose,
	onSuccess,
	setIsLoading,
}: FormAddAccountBalanceProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);
	const [loans, setLoans] = useState<Loan[]>([]);

	// context
	const { project } = useContext(ContextProject);
	const { setBanner } = useContext(ContextBanner);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');
	const formId = 'form-add-account-balance';

	const balance = account.balances.reduce((total, account) => {
		return total + account.balance;
	}, 0);

	// check if from account has disabled loans
	let hasDisabledLoans = false;

	for (let i = 0; i < loans.length; i++) {
		if (loans[i]?.status === 'DISABLED') {
			hasDisabledLoans = true;
			break;
		}
	}

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code,status&populate=loans`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// handlers
	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// define balances using loans
		const payments: PaymentPayload[] = [];
		let total = 0;

		for (let s = 0; s < loans.length; s++) {
			const amount = Number(entries[`source-${s}`]);
			if (amount > 0) {
				total += amount;

				payments.push({
					amount,
					currency: account.currency,
					loan: loans[s].id,
				});
			}
		}

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (total === 0) {
				throw new Error('The amount needs to be greater than 0.', {
					cause: {
						id: 'TRANSACTION_AMOUNT_NON_EXISTENT',
					},
				});
			}

			// add metadata
			const metadata: TransactionMetadata = {
				uuid: crypto.randomUUID(),
			};

			if (entries.comments) {
				metadata.comments = String(entries.comments);
			}

			const body = {
				date: formatDate({
					date: new Date(entries.date as string),
					format: 'YYYY-MM-DD',
				}),
				metadata,
				payments,
				project: project?.id,
				status: 'COMPLETED',
				toAccount: account.id,
				type: 'REPLENISHMENT',
			};

			const fetchRecordTransaction = await fetch(`${process.env.REACT_APP_API_URL}/transactions`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resRecordTransaction = await fetchRecordTransaction.json();

			if (resRecordTransaction.error) {
				throw new Error(resRecordTransaction.error.message, {
					cause: {
						id: resRecordTransaction.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountBalanceUpdated', { name: account.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ACCOUNT_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}

				if (cause.id === 'TRANSACTION_AMOUNT_NON_EXISTENT') {
					message = t('error.transactionAmountNonExistent');
				}

				if (cause.id === 'WALLET_INACTIVE') {
					message = t('error.walletInactive');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={formId} name="formAddAccountBalance" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Input
						defaultValue={formatDate({ date: new Date(), format: 'YYYY-MM-DD' })}
						id={`${formId}-date`}
						inputProps={{
							max: new Date().toISOString().split('T')[0],
						}}
						label={t('date')}
						name="date"
						required={true}
						type="date"
					/>
				</Grid>
				<Grid item xs={12}>
					<Amounts
						currency={account.currency}
						formId={formId}
						hasCurrency={false}
						loans={loans}
						summaries={[
							{
								operator: '+',
								title: t('newBalance'),
								value: balance,
							},
						]}
						title={t('fundsToAdd')}
					/>
				</Grid>

				<Grid item xs={12}>
					<Input
						id={`${formId}-comments`}
						label={t('comments')}
						minRows={2}
						multiline={true}
						name="comments"
					/>
				</Grid>

				{hasDisabledLoans && (
					<Grid item xs={12}>
						<Alert severity="info">{t('alert.financingSourcesDisabled')}</Alert>
					</Grid>
				)}
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
