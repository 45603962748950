// react
import { MouseEvent, ReactNode, useState } from 'react';

// mui
import { Box, Button, Grid, InputLabel } from '@mui/material';

// props
export type RadioButtonsProps = {
	defaultValue?: string;
	id: string;
	inputs: {
		icon?: ReactNode;
		label: string;
		value: string;
	}[];
	label: string;
	name: string;
	onChange?: (e: MouseEvent<HTMLButtonElement>, value: string) => void;
	required?: boolean;
};

export const RadioButtons = ({
	defaultValue,
	id,
	inputs = [],
	label,
	name = 'type',
	onChange,
	required,
}: RadioButtonsProps) => {
	// state
	const [value, setValue] = useState(defaultValue || inputs[0].value);

	return (
		<>
			{label && (
				<InputLabel htmlFor={id} required={required}>
					{label}
				</InputLabel>
			)}
			<Grid container spacing={2}>
				{inputs.map((input, i) => {
					const isChecked = input.value === value;

					return (
						<Grid item key={`radio-box-${i}-${input.value}`} xs={6}>
							<Button
								color={isChecked ? 'primary' : 'neutral'}
								fullWidth={true}
								onClick={(e) => {
									setValue(input.value);
									if (onChange) {
										onChange(e, input.value);
									}
								}}
								sx={(theme) => {
									return {
										backgroundColor: isChecked ? theme.palette.brand.blue[100] : 'transparent',
										padding: `${theme.spacing(2)} 0`,
										gap: theme.spacing(1),
									};
								}}
								variant="outlined">
								{input.icon && <Box>{input.icon}</Box>}
								{input.label}
							</Button>
							<input
								checked={isChecked}
								hidden
								name={name}
								readOnly={true}
								type="radio"
								value={input.value}
							/>
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};
