// react
import { useCallback, useEffect, useRef, useState } from 'react';

// insights
import { appInsights, trackEvent } from '../insights';

// props
export interface UseFetchParams {
	isEnabled?: boolean;
	isLazy?: boolean;
	onComplete?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onError?: (error: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSuccess?: (data: any) => void;
	options?: object;
	url?: string;
}

export const useFetch = ({
	isEnabled = true,
	isLazy = false,
	onComplete,
	onError,
	onSuccess,
	options = {},
	url,
}: UseFetchParams) => {
	// state
	const [error, setError] = useState<Error | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// refs
	const refOnComplete = useRef(onComplete);
	const refOnError = useRef(onError);
	const refOnSuccess = useRef(onSuccess);
	const refOptions = useRef(options);
	const refUrl = useRef(url);

	const fetchRequest = useCallback(async (u = refUrl.current, o = refOptions.current) => {
		// set loading
		setIsLoading(true);

		// vars
		const { headers = {}, ...opts } = o;
		const idOperation = crypto.randomUUID();

		try {
			const req = await fetch(u, {
				...opts,
				headers: {
					...headers,
					'Request-Id': idOperation,
					'X-Operation-Id': idOperation,
				},
			});
			const res = await req.json();

			if (res.error) {
				throw res.error;
			}

			if (refOnSuccess.current) {
				refOnSuccess.current(res);
			}

			// track dependency
			appInsights.trackDependencyData({
				correlationContext: idOperation,
				id: idOperation,
				name: u,
				responseCode: res.status,
				success: req.ok,
			});
			trackEvent('DataFetchSuccess', { success: true });

			return res;
		} catch (error) {
			const err = error as Error;
			if (refOnError.current) {
				refOnError.current(err);
			}
			setError(err);
			trackEvent('DataFetchError', { message: error });
		} finally {
			if (refOnComplete.current) {
				refOnComplete.current();
			}
			setIsLoading(false);
		}
	}, []);

	const refetch = useCallback(() => {
		fetchRequest();
	}, [fetchRequest]);

	// update url on change
	useEffect(() => {
		refUrl.current = url;
	}, [url]);

	// fetch when isEnabled
	useEffect(() => {
		if (!isLazy && isEnabled) {
			fetchRequest();
		}
	}, [fetchRequest, isEnabled, isLazy]);

	// update options ref when options change
	useEffect(() => {
		refOptions.current = options;
	}, [options]);

	// update onSuccess ref when onSuccess change
	useEffect(() => {
		refOnSuccess.current = onSuccess;
	}, [onSuccess]);

	return {
		error,
		fetchRequest,
		isLoading,
		refetch,
	};
};
