// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useEffect, useState } from 'react';

// router
import { useParams } from 'react-router-dom';

// types
import {
	Entity,
	EntityLoanPayload,
	EntityPayload,
	ErrorCause,
	FormField,
	Loan,
	MetadataField,
	MetadataValue,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Checkbox, FormControlLabel, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { DynamicField } from '../../fields/DynamicField/DynamicField';

// props
export interface FormAddSupplierProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddSupplier = ({ onClose, onSuccess, setIsLoading }: FormAddSupplierProps) => {
	// hooks
	const { idEntity } = useParams();
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [fields, setFields] = useState<FormField[]>([]);
	const [entityParent, setEntityParent] = useState(idEntity);
	const [metadata] = useState<MetadataField[]>([]);

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// vars
	const idForm = 'form-add-supplier';

	// fetch loans
	const { fetchRequest: fetchLoans } = useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setEntities(res.data);
		},
	});

	// fetch form
	const { fetchRequest: fetchForm } = useFetch({
		isEnabled: Boolean(entityParent),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${entityParent}&name=SUPPLIER&limit=1&orderBy=modified&populate=user&status=PUBLISHED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data[0]) {
				const fields: FormField[] = res.data[0].fields.map((field: FormField) => {
					const fieldsDefault = ['idExternal', 'name'];
					const fieldsUser = ['email', 'firstName', 'lastName', 'phone'];

					let name = field.name;

					if (!fieldsDefault.includes(field.name)) {
						if (fieldsUser.includes(field.name)) {
							name = `user-${field.name}`;
						} else {
							name = `metadata-${field.name}`;
						}
					}

					return {
						...field,
						name,
					};
				});
				setFields(fields);
			}
		},
	});

	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.code,
			value: String(loan.id),
		};
	});

	// options
	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			value: String(entity.id),
		};
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// define form data
		const form = document.querySelector(`#${idForm}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			// set associated entity
			const idParent = idEntity ? Number(idEntity) : Number(entries.parent);

			if (!project) {
				throw new Error('The project does not exist.', {
					cause: {
						id: 'PROJECT_NON_EXISTENT',
					},
				});
			}

			if (!idParent) {
				throw new Error('Associated parent entity must be selected.', {
					cause: {
						id: 'ENTITY_PARENT_NON_EXISTENT',
					},
				});
			}

			// construct loans array
			const loans: EntityLoanPayload[] = [];

			const loanEntry = entries.loan as string;
			const ids = loanEntry.split(',');

			// create relationship with same parent for each loan selected
			for (let i = 0; i < ids.length; i++) {
				loans.push({
					id: Number(ids[i]),
					parent: idParent,
				});
			}

			const url = `${process.env.REACT_APP_API_URL}/entities`;

			// check permissions
			const hasPermissionCreateEntity = Boolean(entries.permissionCreateEntity);
			const hasPermissionCreateTransaction = Boolean(entries.permissionCreateTransaction);
			const body: Partial<EntityPayload> = {
				name: String(entries.name),
				metadata,
				loans,
				permissions: {
					entity: {
						create: hasPermissionCreateEntity,
						delete: hasPermissionCreateEntity,
						update: hasPermissionCreateEntity,
					},
					transaction: {
						create: hasPermissionCreateTransaction,
						delete: hasPermissionCreateTransaction,
						update: hasPermissionCreateTransaction,
					},
				},
				type: 'SUPPLIER',
			};

			const bodyMetadata = [] as MetadataField[];
			// construct body using fields and entries
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];

				let value: MetadataValue = String(entries[field.name]);

				if (field.type === 'CHECKBOX') {
					value = Boolean(value);
				}

				if (field.type === 'NUMBER') {
					value = Number(value);
				}

				if (field.name.includes('metadata-')) {
					bodyMetadata.push({
						isProtected: Boolean(field.isProtected),
						label: field.label,
						name: field.name.replace('metadata-', ''),
						type: field.type,
						value,
					});
				} else {
					body[field.name] = value;
				}
			}
			body.metadata = bodyMetadata;

			if (entries.idExternal) {
				body.idExternal = String(entries.idExternal);
			}

			body.isKycVerified = entries.isKycVerified === 'on';
			body.project = project.id;

			const fetchAddSupplier = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddSupplier = await fetchAddSupplier.json();

			if (resAddSupplier.error) {
				throw new Error(resAddSupplier.error.message, {
					cause: {
						id: resAddSupplier.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			const bannerMessage = t('alert.supplierCreated', { name: entries.name });

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: bannerMessage,
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ENTITY_DUPLICATE') {
					message = t('error.entityDuplicate', { id: entries.idExternal });
				}

				if (cause.id === 'ENTITY_LOANS_IDENTICAL') {
					message = t('error.entityLoansIdentical');
				}

				if (cause.id === 'ENTITY_PARENT_NON_EXISTENT') {
					message = t('error.associatedEntityNotSelected');
				}

				if (cause.id === 'FINANCING_SOURCE_NOT_SELECTED') {
					message = t('error.financingSourceNotSelected');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	// fetch form and loans when beneficiary type changes
	useEffect(() => {
		if (entityParent) {
			fetchForm();
			fetchLoans();
		}
	}, [entityParent, fetchForm, fetchLoans]);

	return (
		<form id={idForm} name="formAddSupplier" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input id={`${idForm}-name`} label={t('name')} name="name" required={true} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						helperText={t('idExternal.helper')}
						id={`${idForm}-id-external`}
						label={t('uniqueIdentifier')}
						name="idExternal"
					/>
				</Grid>

				{!idEntity && (
					<Grid item xs={6}>
						<Select
							id={`${idForm}-parent`}
							label={t('entity')}
							name="parent"
							onChange={(e) => {
								const { value } = e.target as HTMLInputElement;
								setEntityParent(value);
							}}
							options={optionsEntities}
							required={true}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={idEntity ? 12 : 6}>
					<Select
						id={`${idForm}-loan`}
						hasAllOption={true}
						label={t('financingSource')}
						name="loan"
						multiple={true}
						options={optionsLoans}
						required={true}
					/>
				</Grid>
				{fields.map((field, i) => {
					return (
						<Grid
							alignSelf={field.type === 'CHECKBOX' ? 'flex-end' : 'flex-start'}
							item
							key={`field-${field.name}-${i}`}
							xs={12}
							lg={6}>
							<DynamicField field={field} />
						</Grid>
					);
				})}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={true}
								defaultValue={'on'}
								id={`${idForm}-is-kyc-verified`}
								name="isKycVerified"
								required={true}
							/>
						}
						label={t('formAddEntity.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
