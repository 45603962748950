// react
import { SyntheticEvent } from 'react';

// types
import { FilterType } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid, GridProps, SelectChangeEvent } from '@mui/material';

// ui
import { Autocomplete, AutocompleteProps } from '../../fields/Autocomplete/Autocomplete';

// fields
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';
import { Select, SelectProps } from '../../fields/Select/Select';
import { Input, InputProps } from '../../fields/Input/Input';

// props
export type FilterChangeEvent = SelectChangeEvent<unknown> | SyntheticEvent<Element, Event>;

export type FilterProps = {
	GridProps?: GridProps;
	onChange?: (e: FilterChangeEvent, value: unknown, filter?: FilterProps) => void;
	type: FilterType;
} & Omit<AutocompleteProps, 'onChange'> &
	Omit<SelectProps, 'onChange'> &
	Omit<InputProps, 'onChange'>;

export interface FiltersProps {
	filters: FilterProps[];
}

export const Filters = ({ filters }: FiltersProps) => {
	// state
	const [searchParams] = useSearchParams();

	// handlers
	const handleChangeFilter = (e: FilterChangeEvent, value: unknown, filter: FilterProps) => {
		// remove search param
		searchParams.delete('q');

		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (filter.onChange) {
			filter.onChange(e, value, filter);
		}
	};

	return (
		<Grid container spacing={2}>
			{filters.map((filter, i) => {
				const { GridProps, type, ...propsFilter } = filter;
				const name = String(filter.name);
				const key = `filter-${i}-${name}`;

				if (['DATE', 'INPUT'].includes(type)) {
					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Input
								type={type === 'DATE' ? 'date' : 'text'}
								{...propsFilter}
								onChange={(e) => handleChangeFilter(e, e.target.value, filter)}
							/>
						</Grid>
					);
				} else if (type === 'AUTOCOMPLETE') {
					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Autocomplete
								{...propsFilter}
								disablePortal={true}
								onChange={(e, value) => handleChangeFilter(e, value, filter)}
							/>
						</Grid>
					);
				} else {
					if (type === 'CURRENCY') {
						return (
							<Grid item key={key} xs={12} {...GridProps}>
								<SelectCurrency
									{...propsFilter}
									MenuProps={{
										disablePortal: true,
									}}
									onChange={(e) => handleChangeFilter(e, e.target.value, filter)}
								/>
							</Grid>
						);
					}

					return (
						<Grid item key={key} xs={12} {...GridProps}>
							<Select
								{...propsFilter}
								MenuProps={{
									disablePortal: true,
								}}
								onChange={(e) => handleChangeFilter(e, e.target.value, filter)}
							/>
						</Grid>
					);
				}
			})}
		</Grid>
	);
};
