// react
import { ReactNode, SyntheticEvent, useContext, useState } from 'react';

// types
import { Contract, Entity, Frame } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// material
import { Alert, Box, Grid, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// theme
import { theme } from '../../../theme';

// icons
import { ModeEditOutlined as IconModeEdit } from '@mui/icons-material';

// context
import { ContextUser } from '../../../App.context';

// dialogs
import { DialogEditContract } from '../../dialogs/DialogEditContract/DialogEditContract';

// ui
import { Amount } from '../../ui/Amount/Amount';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { LineItem } from '../../ui/LineItem/LineItem';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// tables
import { TableBasicTransactions } from '../../tables/basic/TableBasicTransactions/TableBasicTransactions';

export interface RenderKeyValueParams {
	label: string;
	value: ReactNode;
}

export interface CardContractProps {
	contract: Contract;
	frame?: Frame;
	refetchContracts?: () => void;
}

export const CardContract = ({
	contract,
	frame = 'PROJECT',
	refetchContracts,
}: CardContractProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const [searchParams, setSearchParams] = useSearchParams();

	const { user } = useContext(ContextUser);

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [tabValue, setTabValue] = useState('transactions');

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogContract = searchParams.get('dialogContract');

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};
	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const btnMenuItems: MenuItemProps[] = [];
	if (user?.permissions?.contract?.update) {
		btnMenuItems.push({
			children: t('edit'),
			icon: <IconModeEdit />,
			onClick: () => {
				searchParams.set('dialog', 'editContract');
				searchParams.set('dialogContract', String(contract.id));
				setSearchParams(searchParams);
			},
		});
	}
	const supplier = contract.supplier as Entity;
	const tabs = [
		{
			label: t('transactions'),
			value: 'transactions',
		},
		{
			label: t('description'),
			value: 'description',
		},
	];

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box
					sx={(theme) => {
						return {
							padding: theme.spacing(2),
						};
					}}>
					<Grid alignItems="center" container spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={(theme) => {
									return {
										margin: theme.spacing(0, 0, 2, 0),
										[theme.breakpoints.up('lg')]: {
											margin: 0,
										},
									};
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type="contract" />
									</Grid>
									<Grid item>
										<Typography fontWeight={600}>{contract.num}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						{frame === 'PROJECT' && (
							<Grid item xs={12} lg={3}>
								<RowKeyValue label={t('supplier')} value={<LinkEntity entity={supplier} />} />
							</Grid>
						)}

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('amount')}
								value={<Amount currency={contract.currency} value={contract.amount} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('stepId')}
								value={<Typography>{contract.idStep || t('na')}</Typography>}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('signingDate')}
								value={
									contract?.dateSigning ? (
										<Time date={new Date(contract?.dateSigning)} />
									) : (
										<Typography>{t('na')}</Typography>
									)
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('created')}
								value={<Time date={new Date(contract.created)} />}
							/>
						</Grid>

						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideTransactions') : t('seeTransactions')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="transactions">
							<TableBasicTransactions contract={contract.id} />
						</TabPanel>
						<TabPanel value="description">
							{contract?.description ? (
								<LineItem
									content={contract?.description}
									IconCircleProps={{ size: 'sm', type: 'description' }}
								/>
							) : (
								<Alert severity="info">{t('alert.empty')}</Alert>
							)}
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogEditContract
				contract={contract}
				isOpen={paramDialog === 'editContract' && paramDialogContract === String(contract.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogContract');
					setSearchParams(searchParams);
				}}
				onSuccess={() => {
					if (refetchContracts) {
						refetchContracts();
					}
				}}
			/>
		</>
	);
};
