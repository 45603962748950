// react
import {
	ChangeEvent,
	Dispatch,
	MouseEvent,
	SetStateAction,
	useContext,
	useMemo,
	useState,
} from 'react';

// router
import { useParams } from 'react-router-dom';

// types
import {
	Entity,
	EntityLoanPayload,
	EntityPayload,
	ErrorCause,
	Form,
	FormField,
	Loan,
	MetadataField,
	MetadataValue,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Checkbox, FormControlLabel, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { DynamicField } from '../../fields/DynamicField/DynamicField';

// props
export interface FormEditSupplierProps {
	onClose?: () => void;
	onSuccess?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	supplier: Entity;
}

export const FormEditSupplier = ({
	onClose,
	onSuccess,
	setIsLoading,
	supplier,
}: FormEditSupplierProps) => {
	// hooks
	const { idEntity } = useParams();
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [entities, setEntities] = useState<Entity[]>([]);
	const [entityParent, setEntityParent] = useState('');
	const [error, setError] = useState<string | null>(null);
	const [form, setForm] = useState<Form | null>(null);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [metadata] = useState<MetadataField[]>(supplier?.metadata as MetadataField[]);
	const [values, setValues] = useState({
		name: supplier.name,
		idExternal: supplier.idExternal,
		loan: [''],
		parent: '',
		fields: form?.fields,
		isKycVerified: true,
	});

	// context
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// vars
	const idForm = 'form-edit-supplier';
	const isParentRequired = true;

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,code,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setEntities(res.data);
		},
	});

	// fetch entity loans
	useFetch({
		isEnabled: Boolean(supplier),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${supplier?.id}&fields=id,code,entities,status&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				const entitiesloans: Loan[] = res.data;
				const entityParent = String(entitiesloans?.[0]?.entities?.[0]?.parent?.id);
				setEntityParent(entityParent);
				setValues((currrentValues) => {
					return {
						...currrentValues,
						loan: entitiesloans.map((loan) => String(loan.id)),
						parent: entityParent,
					};
				});
			}
		},
	});

	// fetch form
	const { fetchRequest: fetchForm } = useFetch({
		isEnabled: Boolean(entityParent),
		url: `${process.env.REACT_APP_API_URL}/forms?entity=${entityParent}&name=SUPPLIER&limit=1&orderBy=modified&populate=user&status=PUBLISHED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setForm(res.data[0]);
			}
		},
	});

	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.code,
			value: String(loan.id),
		};
	});

	const optionsEntities = entities
		.filter((e) => e.id !== supplier?.id)
		.map((entity) => {
			return {
				label: entity.name,
				value: String(entity.id),
			};
		});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// define form data
		const form = document.querySelector('#form-edit-supplier') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			// set associated entity
			const idParent = idEntity ? Number(idEntity) : Number(entries.parent);

			if (!project) {
				throw new Error('The project does not exist.', {
					cause: {
						id: 'PROJECT_NON_EXISTENT',
					},
				});
			}

			if (!idParent && isParentRequired) {
				throw new Error('Associated parent entity must be selected.', {
					cause: {
						id: 'ENTITY_PARENT_NON_EXISTENT',
					},
				});
			}

			// construct loans array
			const loans: EntityLoanPayload[] = [];

			const loanEntry = entries.loan as string;
			const ids = loanEntry.split(',');

			// create relationship with same parent for each loan selected
			for (let i = 0; i < ids.length; i++) {
				loans.push({
					id: Number(ids[i]),
					parent: idParent,
				});
			}

			let url = `${process.env.REACT_APP_API_URL}/entities`;

			if (supplier) {
				url += `/${supplier.id}`;
			}

			// check permissions
			const hasPermissionCreateEntity = Boolean(entries.permissionCreateEntity);
			const hasPermissionCreateTransaction = Boolean(entries.permissionCreateTransaction);
			const body: Partial<EntityPayload> = {
				name: String(entries.name),
				metadata,
				loans,
				permissions: {
					entity: {
						create: hasPermissionCreateEntity,
						delete: hasPermissionCreateEntity,
						update: hasPermissionCreateEntity,
					},
					transaction: {
						create: hasPermissionCreateTransaction,
						delete: hasPermissionCreateTransaction,
						update: hasPermissionCreateTransaction,
					},
				},
				type: 'SUPPLIER',
			};

			const bodyMetadata = [] as MetadataField[];
			// construct body using fields and entries
			for (let i = 0; i < fields.length; i++) {
				const field = fields[i];

				let value: MetadataValue = String(entries[field.name]);

				if (field.type === 'CHECKBOX') {
					value = Boolean(value);
				}

				if (field.type === 'NUMBER') {
					value = Number(value);
				}

				if (field.name.includes('metadata-')) {
					bodyMetadata.push({
						isProtected: Boolean(field.isProtected),
						label: field.label,
						name: field.name.replace('metadata-', ''),
						type: field.type,
						value,
					});
				} else {
					body[field.name] = value;
				}
			}
			body.metadata = bodyMetadata;

			if (entries.idExternal) {
				body.idExternal = String(entries.idExternal);
			}
			const fetchAddSupplier = await fetch(url, {
				method: 'PATCH',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddSupplier = await fetchAddSupplier.json();

			if (resAddSupplier.error) {
				throw new Error(resAddSupplier.error.message, {
					cause: {
						id: resAddSupplier.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			const bannerMessage = t('alert.supplierUpdated', { name: entries.name });

			if (onSuccess) {
				onSuccess();
			}

			// set success banner
			setBanner({
				hasClose: true,
				message: bannerMessage,
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause) {
				if (cause.id === 'ENTITY_DUPLICATE') {
					message = t('error.entityDuplicate', { id: entries.idExternal });
				}

				if (cause.id === 'ENTITY_LOANS_IDENTICAL') {
					message = t('error.entityLoansIdentical');
				}

				if (cause.id === 'ENTITY_UNAUTHORIZED_PATCH' || cause.id === 'ENTITY_UNAUTHORIZED_POST') {
					message = t('error.unauthorized');
				}

				if (cause.id === 'ENTITY_PARENT_NON_EXISTENT') {
					message = t('error.associatedEntityNotSelected');
				}

				if (cause.id === 'FINANCING_SOURCE_NOT_SELECTED') {
					message = t('error.financingSourceNotSelected');
				}
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	// handlers
	const handleChangeValue = (e: ChangeEvent | SelectChangeEvent<unknown>) => {
		const { name, value } = e.target as HTMLInputElement;
		setValues((currrentValues) => {
			return {
				...currrentValues,
				[name]: value,
			};
		});
	};

	// define default values for each field
	const fields: FormField[] = useMemo(() => {
		if (form) {
			const defaultFields = ['idExternal', 'name'];
			let metadata = supplier.metadata;

			return form.fields.map((field) => {
				if (!Array.isArray(metadata)) {
					const keys = Object.keys(metadata);
					if (keys.includes(field.name)) {
						field.defaultValue = String(metadata[field.name as keyof Entity]);
					}
				} else {
					// find metadata by field
					metadata = supplier.metadata as MetadataField[];
					const metadataItem = metadata?.find((m) => m.name === field.name);

					// redefine default value if metadata exists
					if (metadataItem) {
						if (field.type === 'CHECKBOX') {
							field.defaultChecked =
								metadataItem.value === undefined ? false : Boolean(metadataItem.value);
						} else {
							field.defaultValue = String(metadataItem.value);
						}
					}
				}

				// check if default field
				if (supplier && defaultFields.includes(field.name)) {
					field.defaultValue = String(supplier[field.name as keyof Entity]);
				} // check if default field
				else {
					if (field.name.includes('metadata-')) {
						field.name = field.name.replace('metadata-', '');
					}
					// redefine field name with metadata prefix
					field.name = `metadata-${field.name}`;
				}

				return {
					...field,
				};
			});
		}

		return [];
	}, [supplier, form]);

	return (
		<form id={idForm} name="formEditSupplier" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						id={`${idForm}-name`}
						label={t('name')}
						name="name"
						onChange={handleChangeValue}
						required={true}
						value={values.name}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						helperText={t('idExternal.helper')}
						id={`${idForm}-id-external`}
						label={t('uniqueIdentifier')}
						name="idExternal"
						onChange={handleChangeValue}
						value={values.idExternal}
					/>
				</Grid>

				{!idEntity && (
					<Grid item xs={6}>
						<Select
							id={`${idForm}-parent`}
							label={t('entity')}
							name="parent"
							onChange={(e) => {
								const { value } = e.target as HTMLInputElement;
								setEntityParent(value);
								fetchForm();
								handleChangeValue(e);
							}}
							options={optionsEntities}
							required={isParentRequired}
							value={values.parent}
						/>
					</Grid>
				)}
				<Grid item xs={12} md={idEntity ? 12 : 6}>
					<Select
						hasAllOption={true}
						id={`${idForm}-loan`}
						label={t('financingSource')}
						multiple={true}
						name="loan"
						onChange={handleChangeValue}
						options={optionsLoans}
						required={true}
						value={values.loan}
					/>
				</Grid>
				{fields.map((field, i) => {
					return (
						<Grid
							alignSelf={field.type === 'CHECKBOX' ? 'flex-end' : 'flex-start'}
							item
							key={`field-${field.name}-${i}`}
							lg={6}
							xs={12}>
							<DynamicField field={field} />
						</Grid>
					);
				})}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								checked={values.isKycVerified}
								disabled={Boolean(supplier)}
								id={`${idForm}-is-kyc-verified`}
								name="isKycVerified"
								onChange={handleChangeValue}
								required={true}
							/>
						}
						label={t('formAddEntity.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
