// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormEditSupplier } from '../../forms/FormEditSupplier/FormEditSupplier';

// types
import { Entity } from '../../../@types';

// props
export interface DialogEditSupplierProps {
	entity: Entity;
	isOpen: boolean;
	onClose: () => void;
	onSuccess?: () => void;
}

export const DialogEditSupplier = ({
	entity,
	isOpen,
	onClose,
	onSuccess,
}: DialogEditSupplierProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('editSupplier'),
					loading: isLoading,
					form: 'form-edit-supplier',
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: t('editSupplier'),
				variant: 'h3',
			}}>
			<FormEditSupplier
				onClose={onClose}
				onSuccess={onSuccess}
				setIsLoading={setIsLoading}
				supplier={entity}
			/>
		</DialogActions>
	);
};
