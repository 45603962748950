// router
import { useSearchParams } from 'react-router-dom';

// react
import { useContext } from 'react';

// mui
import { Grid } from '@mui/material';

// context
import { ContextContracts } from '../Project.context';

// pages
import { Details } from './Details/Details';

// dialogs
import { DialogAddContract } from '../../../dialogs/DialogAddContract/DialogAddContract';

export const Contracts = () => {
	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// context
	const { refetchContracts } = useContext(ContextContracts);

	// vars
	const paramDialog = searchParams.get('dialog');

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Details />
				</Grid>
			</Grid>
			<DialogAddContract
				isOpen={paramDialog === 'addContract'}
				onClose={() => {
					setSearchParams((params) => {
						params.delete('dialog');
						return params;
					});
				}}
				onSuccess={() => {
					refetchContracts();
				}}
			/>
		</>
	);
};
