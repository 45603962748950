// react
import { ReactNode } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Project } from '../../../@types';

// mui
import { Link, LinkProps } from '@mui/material';

// props
export interface LinkProjectProps extends LinkProps {
	children?: ReactNode;
	project: Project;
	[x: string]: unknown;
}

export const LinkProject = ({ account, children, project, ...props }: LinkProjectProps) => {
	// vars
	const content = children || project.name;

	return (
		<Link
			component={RouterLink}
			fontWeight={600}
			sx={{ wordBreak: 'break-word' }}
			to={`/project/${project.idWbg}`}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
