// react
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';

// context
import { ContextProject } from '../Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// ui
import { Tabs } from '../../../ui/Tabs/Tabs';

// types
import { Entity } from '../../../../@types';
import { entityTypesDefault } from '../../../../@types/unions';

export const Settings = () => {
	// hooks
	const { t } = useTranslation();
	const location = useLocation();

	// defaults
	const getTabByLocation = useCallback((pathname) => {
		if (pathname.includes('benefits')) {
			return 'benefits';
		} else if (pathname.includes('form')) {
			return 'forms';
		} else if (pathname.includes('permissions')) {
			return 'permissions';
		} else {
			return 'expenseTypes';
		}
	}, []);

	const defaultTabValue = useMemo(() => {
		return getTabByLocation(location.pathname);
	}, [getTabByLocation, location]);

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);
	const userEntity = user?.entity as Entity;

	// tabs
	const tabs = [
		{
			component: RouterLink,
			label: t('expenseTypes'),
			to: `/project/${project?.idWbg}/settings/expense-types`,
			value: 'expenseTypes',
		},
		{
			component: RouterLink,
			label: t('nonCashBenefits'),
			to: `/project/${project?.idWbg}/settings/benefits`,
			value: 'benefits',
		},
	];

	if (userEntity && entityTypesDefault.some((type) => type === userEntity?.type)) {
		tabs.push({
			component: RouterLink,
			label: t('forms'),
			to: `/project/${project?.idWbg}/settings/forms`,
			value: 'forms',
		});
	}

	if (user?.role === 'WFA') {
		tabs.push({
			component: RouterLink,
			label: t('permissions'),
			to: `/project/${project?.idWbg}/settings/permissions`,
			value: 'permissions',
		});
	}

	// state
	const [tabValue, setTabValue] = useState(defaultTabValue);

	// handlers
	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// set active tab
	useEffect(() => {
		const tabValueByLocation = getTabByLocation(location.pathname);
		setTabValue(tabValueByLocation);
	}, [getTabByLocation, location]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Typography variant="h2">{t('settings')}</Typography>
				</Container>
			</Grid>
			<Grid item xs={12}>
				<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
			</Grid>
			<Grid item xs={12}>
				<Outlet />
			</Grid>
		</Grid>
	);
};
