// react
import { MouseEvent, MouseEventHandler, useState } from 'react';

// mui
import { IconButton, IconButtonProps, Popover } from '@mui/material';

// ui
import { Menu, MenuItemProps } from '../Menu/Menu';

// icons
import { MoreVertOutlined as IconMoreVert } from '@mui/icons-material';

// props

export interface BtnMenuProps extends IconButtonProps {
	items: MenuItemProps[];
}

export type MenuItemOnClick = MouseEventHandler<HTMLLIElement>;

export const BtnMenu = ({ items, ...props }: BtnMenuProps) => {
	// state
	const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);

	const handleClickMenuItem = (e: MouseEvent, onClick: MouseEventHandler<Element> | undefined) => {
		// close menu
		setAnchorMenu(null);

		if (onClick) {
			onClick(e);
		}
	};

	const itemsSanitized = items.map((item) => {
		return {
			...item,
			onClick: (e: MouseEvent<HTMLLIElement>) => handleClickMenuItem(e, item.onClick),
		};
	});

	return (
		<>
			<IconButton
				onClick={(e: MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					setAnchorMenu(e.currentTarget);
				}}
				size="small"
				{...props}>
				<IconMoreVert />
			</IconButton>
			<Popover
				anchorEl={anchorMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				onClose={() => setAnchorMenu(null)}
				open={Boolean(anchorMenu)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Menu items={itemsSanitized} />
			</Popover>
		</>
	);
};
